import "./IndividualProductOrdered.css";
import React from "react";
const IndividualProductOrdered = (props) => {
  return (
    <div className="cartItem">
      <div className="container-fluid">
        <div className="cartResultProduct d-flex justify-content-start row">
          <div className="col-4 d-flex justify-content-center align-items-center">
            <img
              src={
                process.env.REACT_APP_USER_BACKEND_PRODUCT_IMAGE_LOCATION +
                "/" +
                props.image
              }
              alt="product"
              style={{ height: "95px", width: "95px" }}
              className="productImage"
            />
          </div>
          <div className="col-8 productDetails">
            <span className="productTitle fs-6">
              <strong>{props.productName}</strong>
            </span>
            <span className="productPrice fw-bold fs-6">₹{props.price}</span>
            {!(props.discount === 0 || props.discount < 0) && (
              <div className="fs-6 searchResultProduct_discount">
                <strike>₹{props.mrp}</strike>
                <span>
                  {"  "}({props.discount}% off)
                </span>
              </div>
            )}
            <span className="productBrand">by {props.brandName}</span>
            <span className="productBrand fw-semibold">
              Quantity {props.quantity}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(IndividualProductOrdered);
