import "./OrderHistory.css";
import React, { useState, useEffect } from "react";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import OrderHistoryItem from "./OrderHistoryItem/OrderHistoryItem";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import emptyIcon from "../../icons/empty.png";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const OrderHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "USER_LOAD" });
    document.title = "Order History • Swoopkart";
  }, []);
  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const userDPFromStore = useSelector((state) => state.userData.userDP);
  const userNameFromStore = useSelector((state) => state.userData.userName);
  const userIdFromStore = useSelector((state) => state.userData.userId);

  const [isLoading, setIsLoading] = useState(false);
  const [whatToShow, setWhatToShow] = useState("one");
  const [orders, setOrders] = useState([]);

  const fetchOnLoadHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL + "/order-history/",
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "ORDERS_FETCHED") {
        setWhatToShow("one");
        setOrders(response.orders);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const loaction = useLocation();

  useEffect(() => {
    fetchOnLoadHandler();
  }, []);

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <SmallNavBar navBarText="Order History" />
      {whatToShow === "one" && (
        <div>
          <div className="orderHistoryItemsContainer">
            <React.Fragment>
              {orders.map((order) => {
                return (
                  <OrderHistoryItem
                    key={order._id}
                    _id={order._id}
                    grandTotal={formatNumber(order.grandTotal)}
                    orderedOn={order.orderedOn}
                    soldBy={order.soldBy}
                    remarks={order.remarks}
                    totalItems={order.totalItems}
                    cartProducts={order.cartProducts}
                  />
                );
              })}
              {orders.length <= 0 && (
                <div className="container-fluid mt-3 mb-3">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "75vh" }}
                  >
                    <div
                      style={{
                        color: "#494949",
                        fontSize: "20px",
                      }}
                      className="fw-semibold d-flex justify-content-center align-items-center flex-column"
                    >
                      <img src={emptyIcon} alt="" style={{ height: "160px" }} />{" "}
                      <div>You have not placed any orders.</div>
                      <div
                        className="fw-semibold"
                        style={{ fontSize: "15px", color: "rgb(250,103,103)" }}
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        Start Shopping.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          </div>
          <div className="p-3"></div>
        </div>
      )}
      {whatToShow === "unableToReachServer" && (
        <div>
          <CoudntFetch />
        </div>
      )}
    </div>
  );
};
export default React.memo(OrderHistory);
