import swoopkartUserColor from "./../icons/logocolourful.png";
import "./CSSDump.css";
import { useNavigate } from "react-router-dom";
const LogoNavBar = () => {
  const navigate = useNavigate();
  return (
    <div
      className="sellerLogoNavBar_container mb-3 d-flex justify-content-center align-items-center"
      style={{}}
    >
      <img
        src={swoopkartUserColor}
        alt=""
        style={{ height: "30px" }}
        onClick={() => navigate("/")}
      />
    </div>
  );
};
export default LogoNavBar;
