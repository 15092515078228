import swoopkartIcon from "./icons/light.png";
import swoopkartLogo from "./icons/dark.png";
import Banner_Moblie from "./icons/Banner_Moblie.png";
import ContactSupportIcon from "./icons/ContactSupportIcon.png";
import { Link, useNavigate } from "react-router-dom";

import twitter from "./icons/twitter.png";
import facebook from "./icons/facebook.png";
import instagram from "./icons/instagram.png";
import youtube from "./icons/youtube.png";

const NonMobileScreen = () => {
  const navigate = useNavigate();
  const goToRootHandler = () => {
    navigate("/");
  };
  function openTwitterInNewTab() {
    const win = window.open("https://www.twitter.com/swoopkart", "_blank");
    if (win != null) {
      win.focus();
    }
  }
  function openFacebookInNewTab() {
    const win = window.open("https://www.facebook.com/swoopkart", "_blank");
    if (win != null) {
      win.focus();
    }
  }
  function openInstagramInNewTab() {
    const win = window.open("https://www.instagram.com/swoopkart", "_blank");
    if (win != null) {
      win.focus();
    }
  }
  function openYoutubeInNewTab() {
    const win = window.open("https://www.youtube.com/", "_blank");
    if (win != null) {
      win.focus();
    }
  }
  function openSellAtSwoopkartInNewTab() {
    const win = window.open("https://sell.swoopkart.varunbukka.in/", "_blank");
    if (win != null) {
      win.focus();
    }
  }

  return (
    <div>
      <nav className="d-flex sticky-top justify-content-center align-items-center container-fluid bg-white navBar shadow">
        <div className="col-4"></div>
        <div className="col-4 d-flex justify-content-center">
          <img
            src={swoopkartLogo}
            alt=""
            style={{ height: "35px" }}
            onClick={goToRootHandler}
          />
        </div>
        <div className="col-4 d-flex justify-content-end align-items-center container-fluid">
          <img
            src={ContactSupportIcon}
            alt=""
            style={{ height: "18px", paddingRight: "6px" }}
          />
          support@swoopkart.com
        </div>
      </nav>
      <div className="useSmallScreen_BeautifulContainer">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-6 d-flex justify-content-center">
            <img src={Banner_Moblie} alt="" style={{ height: "550px" }} />
          </div>
          {/* <div className="col-1"></div> */}
          <div className="col-6 d-flex justify-content-start flex-column align-items-center">
            <h1 className="text-start text-white" style={{ fontSize: "45px" }}>
              <div
                style={{
                  color: "rgb(29,29,29)",
                }}
              >
                For the best experience we suggest you,
              </div>
              access our website using a smartphone.
            </h1>
          </div>
        </div>
      </div>
      <div className="banner_two_Swoopkart">
        <div className="d-flex justify-content-center">
          <h3 style={{ color: "rgb(250,103,103)" }}>
            Shop from your favorite stores on your fingertips{"  : )"}
          </h3>
        </div>
      </div>
      <footer className="container-fluid outerFooter">
        <div className="row innerFooter">
          <div className="logosFooter_one d-flex justify-content-center">
            <img src={swoopkartIcon} alt="logo" id="logo" />
          </div>
          <div className="d-flex justify-content-center container-fluid">
            <div className="container-fluid col-sm-3 col-md-2">
              <div className="mb-1">
                <h5>
                  <strong className="color_footer_category">general</strong>
                </h5>
              </div>
              <div style={{ height: "2.5px" }}></div>
              <div className="linksFooter">
                <Link to="/about" className="linksClass">
                  about Us
                </Link>
                <Link to="/help" className="linksClass">
                  support
                </Link>
                <Link
                  onClick={openSellAtSwoopkartInNewTab}
                  className="linksClass"
                >
                  sell at swoopkart
                </Link>
              </div>
            </div>
            <div className="col-sm-6 col-md-8">
              <div className="footer_terms_conditions container-fluid">
                {/* d-flex flex-column align-items-center justify-content-center */}
                <div className="mb-1">
                  <h5>
                    <strong className="color_footer_category">
                      terms and conditions
                    </strong>
                  </h5>
                </div>
                <div className="footer_terms_conditions_text">
                  To protect the integrity of our sellers, all orders will be
                  taken only after the customer agrees for no return and no
                  refund policy. That means the customer will have a chance to
                  cancel an order only before its processing starts.
                </div>
              </div>
            </div>
            <div className="container-fluid col-sm-3 col-md-2 d-flex flex-column align-items-end">
              <div className="mb-1">
                <h5>
                  <strong className="color_footer_category">follow us</strong>
                </h5>
              </div>
              <div style={{ height: "2.5px" }}></div>
              <div className="linksFooter">
                <div className="footer_SocialHandle_one">
                  <img src={instagram} alt="" onClick={openInstagramInNewTab} />
                </div>
                <div className="footer_SocialHandle_one">
                  <img src={twitter} alt="" onClick={openTwitterInNewTab} />
                </div>
                <div className="footer_SocialHandle_one">
                  <img src={facebook} alt="" onClick={openFacebookInNewTab} />
                </div>
                <div className="footer_SocialHandle_one">
                  <img src={youtube} alt="" onClick={openYoutubeInNewTab} />
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "40px" }}
          >
            <p style={{ color: "rgb(255,255,255)" }}>
              {" "}
              © All rights reserved by <strong>swoopkart</strong>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default NonMobileScreen;
