import "./ExistingAddress.css";
import React from "react";
const ExistingAddress = (props) => {
  return (
    <div className="ExistingAddressItem">
      <div className="ExistingAddress_FullName" style={{ fontSize: "17px" }}>
        <strong>{props.fullName}</strong>
      </div>
      <div className="ExistingAddress_MobileNumber fs-6">
        {props.phoneNumber}
      </div>
      <div className="ExistingAddress_FlatorHouse fs-6">
        {props.flatHouseBuildingNo}
      </div>
      <div className="ExistingAddress_AreaorStreet fs-6">
        {props.areaStreet}
      </div>
      <div className="ExistingAddress_Landmark fs-6">{props.landmark}</div>
      <div className="ExistingAddress_TownCity_State_Pincode fs-6">
        {props.townCity} {props.pincode}, {props.state}
      </div>
      <div className="ExistingAddress_India fs-6">India</div>
      <div className="fs-6 d-flex add_address_seller justify-content-end">
        <span
          onClick={() => {
            props.DeleteHandler(props._id);
          }}
        >
          <strong>Delete</strong>
        </span>
      </div>
    </div>
  );
};
export default React.memo(ExistingAddress);
