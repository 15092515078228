import "./User.css";
import React, { useEffect } from "react";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import { useNavigate } from "react-router-dom";
import ordersIcon from "../../icons/user/orders.png";
import sflIcon from "../../icons/user/sfl.png";
import cartIcon from "../../icons/user/cart.png";
import helpcenterIcon from "../../icons/user/help-center.png";
import changepassIcon from "../../icons/user/change-password.png";
import addressesIcon from "../../icons/user/addresses.png";
import edituserIcon from "../../icons/user/edit-user.png";
import merchantIcon from "../../icons/user/merchant.png";
import signoutIcon from "../../icons/user/signout.png";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import { useDispatch, useSelector } from "react-redux";
import greaterThanIcon from "../../icons/greater-than.png";

const User = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = "My Account • Swoopkart";
    dispatch({ type: "USER_LOAD" });
  }, []);

  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const userDPFromStore = useSelector((state) => state.userData.userDP);
  const userNameFromStore = useSelector((state) => state.userData.userName);
  const userIdFromStore = useSelector((state) => state.userData.userId);

  const ordersNavigationHandler = () => {
    navigate("/user/orderhistory");
  };
  const wishlistNavigationHandler = () => {
    navigate("/user/wishlist");
  };
  const cartNavigationHandler = () => {
    navigate("/user/cart");
  };
  const helpNavigationHandler = () => {
    navigate("/support");
  };
  const editProfileNavigationHandler = () => {
    navigate("/user/edit-profile");
  };
  const addressesNavigationHandler = () => {
    navigate("/user/addresses");
  };
  const changePasswordNavigationHandler = () => {
    navigate("/user/change-password");
  };
  const merchantNavigationHandler = () => {
    const win = window.open("https://sell.swoopkart.varunbukka.in/", "_blank");
    if (win != null) {
      win.focus();
    }
  };
  const signoutHandler = () => {
    dispatch({ type: "USER_LOGGING_OUT" });
    navigate("/");
  };
  return (
    <div className="user_container" style={{ height: "100vh" }}>
      <SmallNavBar navBarText="My Account" />
      <div className="container-fluid mt-4 user_dp_and_name">
        <div className="d-flex justify-content-center">
          <img
            src={
              process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
              userDPFromStore
            }
            alt="dp"
            className="user_dp"
          />
        </div>
        <div className="d-flex justify-content-center mt-2">
          <span
            className="fw-semibold"
            style={{ fontSize: "23.5px", color: "rgb(250,103,103)" }}
          >
            {userNameFromStore}
          </span>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container-fluid">
          <div className="container-fluid">
            <div className="user_ControlsAndNavigation_box row">
              <div
                className="user_control_container_box col-6"
                onClick={ordersNavigationHandler}
                style={{
                  borderRight: "1px solid rgb(255, 237, 237)",
                  borderBottom: "1px solid rgb(255, 237, 237)",
                }}
              >
                <div className="">
                  <img
                    src={ordersIcon}
                    alt=""
                    className="user_control_container_box_img"
                  />
                  <span className="user_control_container_box_text">
                    Orders
                  </span>
                </div>
              </div>
              <div
                className="user_control_container_box col-6"
                onClick={wishlistNavigationHandler}
                style={{
                  borderLeft: "1px solid rgb(255, 237, 237)",
                  borderBottom: "1px solid rgb(255, 237, 237)",
                }}
              >
                <div className="">
                  <img
                    src={sflIcon}
                    alt=""
                    className="user_control_container_box_img"
                  />
                  <span className="user_control_container_box_text">
                    Wish List
                  </span>
                </div>
              </div>
              <div
                className="user_control_container_box col-6"
                onClick={cartNavigationHandler}
                style={{
                  borderRight: "1px solid rgb(255, 237, 237)",
                  borderTop: "1px solid rgb(255, 237, 237)",
                }}
              >
                <div className="">
                  <img
                    src={cartIcon}
                    alt=""
                    className="user_control_container_box_img"
                  />
                  <span className="user_control_container_box_text">Cart</span>
                </div>
              </div>
              <div
                className="user_control_container_box col-6"
                onClick={signoutHandler}
                style={{
                  borderLeft: "1px solid rgb(255, 237, 237)",
                  borderTop: "1px solid rgb(255, 237, 237)",
                }}
              >
                <div className="">
                  <img
                    src={signoutIcon}
                    alt=""
                    className="user_control_container_box_img"
                  />
                  <span className="user_control_container_box_text">
                    Sign out
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="user_MoreControls">
        <div className="mt-0">
          <div className="user_MoreControls_box_category">
            <span>
              <strong>My Account</strong>
            </span>
          </div>
          <div className="container-fluid">
            <div className="container-fluid">
              <div
                className="user_MoreControls_box  d-flex justify-content-between  align-items-center"
                onClick={editProfileNavigationHandler}
              >
                <div>
                  <img
                    src={edituserIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">
                    Edit Profile
                  </span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
              <div
                className="user_MoreControls_box  d-flex justify-content-between  align-items-center"
                onClick={addressesNavigationHandler}
              >
                <div>
                  <img
                    src={addressesIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">
                    My Addresses
                  </span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
              <div
                className="user_MoreControls_box  d-flex justify-content-between  align-items-center"
                onClick={changePasswordNavigationHandler}
              >
                <div>
                  <img
                    src={changepassIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">
                    Change Password
                  </span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="user_MoreControls_box_category">
            <span>
              <strong>More</strong>
            </span>
          </div>
          <div className="container-fluid">
            <div className="container-fluid">
              <div
                className="user_MoreControls_box  d-flex justify-content-between  align-items-center"
                onClick={merchantNavigationHandler}
              >
                <div>
                  <img
                    src={merchantIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">
                    Sell with Swoopkart
                  </span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
              <div
                className="user_MoreControls_box  d-flex justify-content-between align-items-center"
                onClick={helpNavigationHandler}
              >
                <div>
                  <img
                    src={helpcenterIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">Support</span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3"></div>
    </div>
  );
};
export default React.memo(User);
