import "./AddressSelector.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingAddressItem from "./LoadingAddressItem";
import { useEffect } from "react";
import AddressItem from "./AddressItem";
import emptyIcon from "../../icons/empty.png";

const AddressSelector = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [selectedAddress,setSelectedAddress] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "USER_LOAD" });
  }, []);
  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const userDPFromStore = useSelector((state) => state.userData.userDP);
  const userNameFromStore = useSelector((state) => state.userData.userName);
  const userIdFromStore = useSelector((state) => state.userData.userId);

  const [whatToShow, setWhatToShow] = useState("one");
  const [recievedAddresses, setRecievedAddresses] = useState([]);

  let drawerClasses = "addressSelectorDrawer shadow";
  if (props.show === true) {
    drawerClasses = "addressSelectorDrawer shadow open";
  } else {
    drawerClasses = "addressSelectorDrawer shadow";
  }

  const navigate = useNavigate();
  const selectAddressHandler = async (AddressId) => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL +
          "/choose-your-location/choose/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedAddressId: AddressId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "ADDRESS_SELECTED") {
        setWhatToShow("one");
        props.refreshSelector();
        setRecievedAddresses(response.addresses);
        setSelectedAddress(response.selectedAddress._id);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const fetchOnLoadHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL + "/choose-your-location/",
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "ADDRESS_FETCHED") {
        setWhatToShow("one");
        setRecievedAddresses(response.addresses);
        setSelectedAddress(response.selectedAddress._id);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const { show } = props;

  useEffect(() => {
    fetchOnLoadHandler();
  }, [show]);

  return (
    <nav className={drawerClasses}>
      {whatToShow === "one" && (
        <div className="container-fluid">
          <div className="container-fluid">
            <div>
              <div className="d-flex justify-content-start flex-column headingSignin pt-1 pb-1 mb-2">
                <div
                  className="fw-semibold"
                  style={{ fontSize: "21px", color: "rgb(250,103,103)" }}
                >
                  Choose your location
                </div>
                <div
                  className="d-flex justify-content-start mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Select a delivery location to see product availability and
                  delivery options.
                </div>
              </div>
              {error !== "" && (
                <div className="mb-3 mt-3 formErrorSeller">
                  {error !== "" && <span>{error}</span>}
                </div>
              )}
              <div className="selectAddress_allAddressItemContainer">
                <React.Fragment>
                  {!isLoading && recievedAddresses.map((address) => {
                    return (
                      <AddressItem
                        key={address._id}
                        _id={address._id}
                        fullName={address.fullName}
                        phoneNumber={address.phoneNumber}
                        pincode={address.pincode}
                        flatHouseBuildingNo={address.flatHouseBuildingNo}
                        areaStreet={address.areaStreet}
                        landmark={address.landmark}
                        townCity={address.townCity}
                        state={address.state}
                        selectAddress={selectAddressHandler}
                        isSelected={selectedAddress===address._id}
                      />
                    );
                  })}
                  {recievedAddresses.length <= 0 && (
                    <div className="container-fluid mt-3 mb-3">
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "50vh" }}
                      >
                        <div
                          style={{
                            color: "#494949",
                            fontSize: "20px",
                          }}
                          className="fw-semibold d-flex justify-content-center align-items-center flex-column"
                        >
                          <img
                            src={emptyIcon}
                            alt=""
                            style={{ height: "160px" }}
                          />{" "}
                          <div>No address found.</div>
                          <div
                            className="fw-semibold"
                            style={{
                              fontSize: "15px",
                              color: "rgb(250,103,103)",
                            }}
                            onClick={() => {
                              props.close();
                              navigate("/user/addresses");
                            }}
                          >
                            Add an Address.
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  
                  {(isLoading) &&(
              <div>
                <LoadingAddressItem />
                <LoadingAddressItem />
                <LoadingAddressItem />
              </div>
            )}
                </React.Fragment>
                <br/>
              </div>
            </div>
          </div>
        </div>
      )}
      {whatToShow === "unableToReachServer" && (
        <div
          className="fw-semibold"
          style={{
            fontSize: "18px",
            height: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>Couldn't reach server.</div>
        </div>
      )}
    </nav>
  );
};
export default AddressSelector;
