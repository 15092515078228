import React from "react";

const AddressItem = (props) => {
  return (
    <div
      className="AddressForOrder_AddressItem"
      style={{ border: `2px solid ${props.isSelected ? "rgb(255, 144, 144)" : "rgb(224, 224, 224)"}` }}
    >
      <div
        className="rbBlock"
        style={{ margin: "2.5% 3% 2.5% 3%" }}
        id={props._id}
            onClick={() => {
              props.selectAddress(props._id);
            }}
      >
        <label
          className="form-check-label address_checkout_radio_container"
          htmlFor={props._id}
          style={{ fontSize: "15.5px" }}
          onClick={() => {
            props.selectAddress(props._id);
          }}
        >
          <div className="ExistingAddress_FullName">
            <strong>{props.fullName}</strong>
          </div>
          <div className="ExistingAddress_MobileNumber">
            {props.phoneNumber}
          </div>
          <div className="ExistingAddress_FlatorHouse">
            {props.flatHouseBuildingNo}
          </div>
          <div className="ExistingAddress_AreaorStreet">{props.areaStreet}</div>
          <div className="ExistingAddress_Landmark">{props.landmark}</div>
          <div className="ExistingAddress_TownCity_State_Pincode">
            {props.townCity}, {props.state} {props.pincode}
          </div>
          <div className="ExistingAddress_India fs-6">India</div>
        </label>
      </div>
    </div>
  );
};
export default React.memo(AddressItem);
