import "./SavedForLater.css";
import React from "react";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import SavedForLaterItem from "./SavedForLaterItem";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import emptyIcon from "../../icons/empty.png";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";

const SavedForLater = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "USER_LOAD" });
  }, []);

  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const userDPFromStore = useSelector((state) => state.userData.userDP);
  const userNameFromStore = useSelector((state) => state.userData.userName);
  const userIdFromStore = useSelector((state) => state.userData.userId);

  useEffect(() => {
    dispatch({ type: "USER_LOAD" });
    document.title = userNameFromStore + "'s Wish List • Swoopkart";
  }, []);

  const [wishlistProducts, setWishlistProducts] = useState([]);
  const [whatToShow, setWhatToShow] = useState("one");
  const [error, setError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const fetchOnLoadHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL + "/wishlist/",
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "WISHLIST_FETCHED") {
        setWhatToShow("one");
        setWishlistProducts(response.products);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const extractedFirstName = userNameFromStore.split(" ")[0];

  const deleteFromWishlistHandler = async (ProductId) => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL +
          "/wishlist/delete-from-wishlist/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedProductId: ProductId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "WISHLIST_UPDATED") {
        setWhatToShow("one");
        setWishlistProducts(response.products);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const moveToCartHandler = async (ProductId, Quantity, SellerId) => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL + "/wishlist/move-to-cart/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedProductId: ProductId,
            recievedQuanitity: Quantity,
            recievedSellerId: SellerId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "MOVED_TO_CART") {
        setWhatToShow("one");
        setWishlistProducts(response.products);
      }
      if (response.status === "COUDNT_ADD_TO_CART") {
        setWhatToShow("one");
        setWishlistProducts(response.products);
        setCoudntAddToCart(true);
        setCoudntAddProductSellerId(response.coudntAddProductSellerId);
        setCoudntAddProductId(response.coudntAddProductId);
        setCoudntAddProductQuantity(response.coudntAddProductQuantity);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const loaction = useLocation();
  useEffect(() => {
    fetchOnLoadHandler();
  }, [loaction]);

  const [coudntAddToCart, setCoudntAddToCart] = useState(false);
  const [coudntAddProductSellerId, setCoudntAddProductSellerId] = useState("");
  const [coudntAddProductId, setCoudntAddProductId] = useState("");
  const [coudntAddProductQuantity, setCoudntAddProductQuantity] = useState(0);

  const removeAllAndAddToCartHandler = async () => {
    try {
      setIsLoading(true);
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL +
          "/wishlist/remove-all-and-add-to-cart/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedSellerId: coudntAddProductSellerId,
            recievedProductId: coudntAddProductId,
            recievedQuanitity: coudntAddProductQuantity,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "MOVED_TO_CART") {
        setWhatToShow("one");
        setWishlistProducts(response.products);
        setCoudntAddToCart(false);
        setCoudntAddProductSellerId("");
        setCoudntAddProductId("");
        setCoudntAddProductQuantity(0);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <SmallNavBar navBarText={extractedFirstName + "'s Wish List"} />
      {whatToShow === "one" && (
        <div>
          <div className="SavedForLaterItemsContainer">
            {coudntAddToCart && (
              <div
                className="mt-3"
                style={{ marginLeft: "3%", marginRight: "3%" }}
              >
                <div className="formErrorSeller">
                  <div>
                    Cannot add this product to cart. As you have products from
                    other store.
                  </div>
                  <div className="row d-flex justify-content-end mt-3">
                    <div className="col-4" style={{ paddingRight: "6px" }}>
                      <button
                        style={{
                          height: "33px",
                          width: "100%",
                          backgroundColor: "white",
                          border: "1px solid rgb(250,103,103)",
                          color: "rgb(250,103,103)",
                          borderRadius: "5px",
                          padding: "2.5%",
                        }}
                        onClick={() => {
                          setCoudntAddToCart(false);
                          setCoudntAddProductSellerId("");
                          setCoudntAddProductId("");
                          setCoudntAddProductQuantity(0);
                        }}
                      >
                        Okay
                      </button>
                    </div>
                    <div className="col-8" style={{ paddingLeft: "6px" }}>
                      <button
                        style={{
                          height: "33px",
                          width: "100%",
                          backgroundColor: "rgb(250,103,103)",
                          border: "none",
                          color: "white",
                          borderRadius: "5px",
                          padding: "2.5%",
                        }}
                        onClick={removeAllAndAddToCartHandler}
                      >
                        Remove and add this
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {error === "" && (
              <React.Fragment>
                {wishlistProducts.map((product) => {
                  return (
                    <SavedForLaterItem
                      key={product._id}
                      _id={product._id}
                      image={product.productImage}
                      productName={product.productName}
                      price={formatNumber(product.price)}
                      mrp={formatNumber(product.MRP)}
                      discount={product.discount}
                      sellerId={product.sellerId}
                      brandName={product.brandName}
                      moveToCart={moveToCartHandler}
                      deleteFromWishlist={deleteFromWishlistHandler}
                    />
                  );
                })}
                {wishlistProducts.length <= 0 && (
                  <div className="container-fluid mt-3 mb-3">
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "75vh" }}
                    >
                      <div
                        style={{
                          color: "#494949",
                          fontSize: "20px",
                        }}
                        className="fw-semibold d-flex justify-content-center align-items-center flex-column"
                      >
                        <img
                          src={emptyIcon}
                          alt=""
                          style={{ height: "160px" }}
                        />{" "}
                        <div>Your wish list is empty.</div>
                        <div
                          className="fw-semibold"
                          style={{
                            fontSize: "15px",
                            color: "rgb(250,103,103)",
                          }}
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          Start Shopping.
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          <div className="p-3"></div>
        </div>
      )}
      {whatToShow === "unableToReachServer" && (
        <div>
          <CoudntFetch />
        </div>
      )}
    </div>
  );
};

export default React.memo(SavedForLater);
