import "./EditProfile.css";
import React, { useState, useEffect } from "react";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import { useNavigate } from "react-router-dom";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";

import editIcon from "../../icons/edit.png";
import noEditIcon from "../../icons/no-edit.png";

import { useDispatch, useSelector } from "react-redux";

const EditProfile = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [isFormValid, setIsFormValid] = useState(true);

  const [enteredFirstName, setEnteredFirstName] = useState("");
  const [enteredLastName, setEnteredLastName] = useState("");
  const [enteredPhonenumber, setEnteredPhonenumber] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [selectedGender, setSelectedGender] = useState("default");

  const [enteredFirstNameValid, setEnteredFirstNameValid] = useState(true);
  const [enteredLastNameValid, setEnteredLastNameValid] = useState(true);
  const [enteredPhonenumberValid, setEnteredPhonenumberValid] = useState(true);
  const [selectedGenderValid, setSelectedGenderValid] = useState(true);

  const [areFieldsDisabled, setAreFieldsDisabled] = useState(true);
  const editFieldsClickHandler = () => {
    setAreFieldsDisabled((prevState) => !prevState);
  };

  const getProfileData = async () => {
    const requestSent = await fetch(
      process.env.REACT_APP_USER_BACKEND_URL + "/edit-profile/",
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + userTokenFromStore,
        },
      }
    );
    const response = await requestSent.json();
    if (response.status === "FAILED") {
      setError("There was a server error.");
    }
    if (!response.status) {
      setError("There was a server error.");
    }
    if (response.status === "USER_NOT_FOUND") {
      setError("Coudn't load your data. Try re-signing in.");
    }
    if (response.status === "NOT_AUTHENTICATED") {
      setError("You are not an authenticated user. Try re-signing in.");
    }
    if (response.status === "USER_LOADED") {
      setEnteredFirstName(response.userFirstName);
      setEnteredLastName(response.userLastName);
      setEnteredEmail(response.userEmail);
      setEnteredPhonenumber(response.userPhonenumber);
      setSelectedGender(response.userGender);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    document.title = "Edit Profile • Swoopkart";
    dispatch({ type: "USER_LOAD" });
    try {
      setIsLoading(true);
      getProfileData();
    } catch (err) {
      setError("Unable to reach the server. Try again later.");
      setIsLoading(false);
    }
  }, []);

  const formValidationHandler = () => {
    const validation =
      enteredFirstNameValid &&
      enteredLastNameValid &&
      enteredPhonenumberValid &&
      selectedGenderValid;
    setIsFormValid(validation);
  };

  const editProfileClickHandler = async () => {
    if (enteredFirstName.trim() === "") {
      setEnteredFirstNameValid(false);
      return;
    }
    setEnteredFirstNameValid(true);
    if (enteredLastName.trim() === "") {
      setEnteredLastNameValid(false);
      return;
    }
    setEnteredLastNameValid(true);
    if (!(enteredPhonenumber.length === 10)) {
      setEnteredPhonenumberValid(false);
      return;
    }
    setEnteredPhonenumberValid(true);
    if (selectedGender.trim() === "default") {
      setSelectedGenderValid(false);
      return;
    }
    setSelectedGenderValid(true);
    formValidationHandler();
    if (isFormValid) {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_USER_BACKEND_URL + "/edit-profile/edit",
          {
            method: "POST",
            body: JSON.stringify({
              enteredFirstName: enteredFirstName,
              enteredLastName: enteredLastName,
              enteredPhonenumber: enteredPhonenumber,
              selectedGender: selectedGender,
            }),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + userTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setError("There was a server error.");
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "USER_LOGGING_OUT" });
          navigate("/user-not-authenticated");
        }
        if (!response.status) {
          setError("There was a server error.");
        }
        if (response.status === "DETAILS_UPDATED_SUCESSFULLY") {
          localStorage.setItem("userName", response.newUserName);
          dispatch({ type: "USER_LOAD" });
          setError("");
          editFieldsClickHandler();
        }
        setIsLoading(false);
      } catch (error) {
        setError("Unable to reach the server. Try again later.");
        setIsLoading(false);
      }
    }
  };

  const navigate = useNavigate();

  const changeAvatarClickHandler = () => {
    navigate("/user/edit-profile/avatar");
  };

  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const userDPFromStore = useSelector((state) => state.userData.userDP);
  const userNameFromStore = useSelector((state) => state.userData.userName);
  const userIdFromStore = useSelector((state) => state.userData.userId);

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <SmallNavBar navBarText="Edit Profile" />
      <div className="">
        <div className="container-fluid mt-4 user_dp_and_name_editprofile mb-4">
          <div className="d-flex justify-content-center">
            <img
              src={
                process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                userDPFromStore
              }
              alt="dp"
              className="user_dp"
            />
          </div>
          <div className=" edit-profile_edit_dp d-flex justify-content-center">
            <strong onClick={changeAvatarClickHandler}>Change Avatar</strong>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container-fluid">
            <div
              className="d-flex justify-content-end mb-3"
              style={{ marginRight: "6px" }}
            >
              {areFieldsDisabled && (
                <img
                  src={editIcon}
                  style={{ height: "24px" }}
                  alt=""
                  onClick={editFieldsClickHandler}
                />
              )}
              {!areFieldsDisabled && (
                <img
                  src={noEditIcon}
                  alt=""
                  style={{ height: "24px" }}
                  onClick={editFieldsClickHandler}
                />
              )}
            </div>
            {(!enteredFirstNameValid ||
              !enteredLastNameValid ||
              !enteredPhonenumberValid ||
              !selectedGenderValid ||
              error !== "") && (
              <div className="mb-3 formErrorSeller">
                {!enteredFirstNameValid && (
                  <span>Enter a valid first name.</span>
                )}
                {!enteredLastNameValid && <span>Enter a valid last name.</span>}
                {!enteredPhonenumberValid && (
                  <span>Enter a valid phone number.</span>
                )}
                {!selectedGenderValid && <span>Choose a valid gender.</span>}
                {error !== "" && <span>{error}</span>}
              </div>
            )}
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput_signup_Fname_edit"
                placeholder="John"
                disabled={areFieldsDisabled}
                value={enteredFirstName}
                onChange={(event) => {
                  setEnteredFirstName(event.target.value);
                }}
              />
              <label htmlFor="floatingInput_signup_Fname_edit">
                First Name
              </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput_signup_Lname_edit"
                placeholder="Marcus"
                disabled={areFieldsDisabled}
                value={enteredLastName}
                onChange={(event) => {
                  setEnteredLastName(event.target.value);
                }}
              />
              <label htmlFor="floatingInput_signup_Lname_edit">Last Name</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="number"
                className="form-control"
                id="floatingInput_signup_phone_edit"
                placeholder="7906834597"
                disabled={areFieldsDisabled}
                value={enteredPhonenumber}
                onChange={(event) => {
                  setEnteredPhonenumber(event.target.value);
                }}
              />
              <label htmlFor="floatingInput_signup_phone_edit">
                Phone Number
              </label>
            </div>

            <select
              id="floatingInput_signup_gender_edit"
              className="form-select mb-3"
              aria-label="Default select example"
              disabled={areFieldsDisabled}
              value={selectedGender}
              onChange={(event) => {
                setSelectedGender(event.target.value);
              }}
            >
              <option value="default">Choose your gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
            <div className="mb-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput_signup_email_edit"
                  placeholder="john@gmail.com"
                  value={enteredEmail}
                  disabled={true}
                />
                <label htmlFor="floatingInput_signup_email_edit">Email</label>
              </div>
              <div
                className="mt-1"
                style={{
                  fontSize: "14.5px",
                  paddingLeft: "2.5px",
                  paddingBottom: "3px",
                }}
              >
                Email once registered cannot be changed.
              </div>
            </div>
            <div className="nextButton d-flex justify-content-end">
              <button
                className="nextButtonCustom"
                disabled={areFieldsDisabled}
                onClick={editProfileClickHandler}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3"></div>
    </div>
  );
};
export default React.memo(EditProfile);
