import "./Search.css";
import SearchResultProduct from "../../components/searchresultproduct/SearchResultProduct";
import LoadingSearchResultProduct from "../../UI/LoadingProduct/LoadingSearchResultProduct";
import FilterBar from "../../components/filter/FilterBar";
import FilterDrawer from "../../components/filter/filterdrawer/FilterDrawer";
import SortDrawer from "../../components/filter/sortdrawer/SortDrawer";
import Backdrop from "../../UI/Backdrop/Backdrop";
import useBodyScrollLock from "../../hooks/body-scrool-lock";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";

const Search = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const { search } = location;
  const { sort, reviews, discount, price } = queryString.parse(search);

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [isSortDrawerOpen, setIsSortDrawerOpen] = useState(false);
  const [recievedProducts, setRecievedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [toggle] = useBodyScrollLock();
  const filterDrawerToggleClickHandler = () => {
    setIsFilterDrawerOpen((prevState) => !prevState);
    toggle();
  };
  const sortDrawerToggleClickHandler = () => {
    setIsSortDrawerOpen((prevState) => !prevState);
    toggle();
  };
  const filterBackdropClickHandler = () => {
    setIsFilterDrawerOpen(false);
    toggle();
  };
  const sortBackdropClickHandler = () => {
    setIsSortDrawerOpen(false);
    toggle();
  };
  const [whatToShow, setWhatToShow] = useState("one");

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  const fetchOnLoadHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL +
          "/search/" +
          params.keyWord +
          "?sort=" +
          sort +
          "&reviews=" +
          reviews +
          "&discount=" +
          discount +
          "&price=" +
          price,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "PRODUCTS_SEARCHED") {
        setWhatToShow("one");
        setRecievedProducts(response.products);
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOnLoadHandler();
  }, [location]);

  return (
    <div className="search">
      <FilterDrawer
        show={isFilterDrawerOpen}
        filterToggle={filterDrawerToggleClickHandler}
      />

      {isFilterDrawerOpen && <Backdrop click={filterBackdropClickHandler} />}

      <SortDrawer
        show={isSortDrawerOpen}
        sortToggle={sortDrawerToggleClickHandler}
      />

      {isSortDrawerOpen && <Backdrop click={sortBackdropClickHandler} />}
      <FilterBar
        sortToggle={sortDrawerToggleClickHandler}
        filterToggle={filterDrawerToggleClickHandler}
      />
      <div
        className="container-fluid mt-4 results_text_search"
        style={{
          borderBottom: `${
            recievedProducts.length <= 0 && !isLoading
              ? "2px solid rgb(255, 200, 200)"
              : ""
          }`,
          color: "rgb(250,103,103)",
          fontSize: "18px",
          fontWeight: "600",
        }}
      >
        Results for "{params.keyWord}"
      </div>
      {whatToShow === "one" && (
        <div>
          <div className="container-searchResultProducts">
            {isLoading && (
              <React.Fragment>
                <LoadingSearchResultProduct />
                <LoadingSearchResultProduct />
                <LoadingSearchResultProduct />
                <LoadingSearchResultProduct />
                <LoadingSearchResultProduct />
                <LoadingSearchResultProduct />
                <LoadingSearchResultProduct />
              </React.Fragment>
            )}
            {!isLoading && recievedProducts.length > 0 && (
              <div className="container-fluid">
                {recievedProducts.map((product) => {
                  return (
                    <SearchResultProduct
                      key={product._id}
                      _id={product._id}
                      image={product.productImage}
                      productName={product.productName}
                      price={formatNumber(product.price)}
                      discount={product.discount}
                      mrp={formatNumber(product.MRP)}
                      brand={product.brandName}
                      rating={product.rating}
                      sellerId={product.sellerId}
                      signInToggle={props.signInToggle}
                    />
                  );
                })}
              </div>
            )}
            {!isLoading && recievedProducts.length <= 0 && (
              <div className="container-fluid mt-3 mb-3">
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <div
                    style={{
                      color: "#494949",
                      fontSize: "18px",
                    }}
                    className="fw-semibold container-fluid"
                  >
                    <div>No results found for "{params.keyWord}".</div>
                    <div style={{ fontSize: "15.5px", fontWeight: "300" }}>
                      Try checking your spelling or use more general terms.
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {whatToShow === "unableToReachServer" && (
        <div>
          <div
            className="alignmentCoudntFetch fw-semibold"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "68vh",
              fontSize: "18px",
            }}
          >
            <div>Couldn't reach server.</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default React.memo(Search);
