import "./Footer.css";
import swoopkartLogo from "../../icons/light.png";
import { Link, useNavigate } from "react-router-dom";
import twitter from "../../icons/twitter.png";
import facebook from "../../icons/facebook.png";
import instagram from "../../icons/instagram.png";
import youtube from "../../icons/youtube.png";

import React from "react";
const Footer = () => {
  const navigate = useNavigate();
  const goToRootHandler = () => {
    navigate("/");
  };
  function openTwitterInNewTab() {
    const win = window.open("https://www.twitter.com/swoopkart", "_blank");
    if (win != null) {
      win.focus();
    }
  }
  function openFacebookInNewTab() {
    const win = window.open("https://www.facebook.com/swoopkart", "_blank");
    if (win != null) {
      win.focus();
    }
  }
  function openInstagramInNewTab() {
    const win = window.open("https://www.instagram.com/swoopkart", "_blank");
    if (win != null) {
      win.focus();
    }
  }
  function openYoutubeInNewTab() {
    const win = window.open("https://www.youtube.com/", "_blank");
    if (win != null) {
      win.focus();
    }
  }
  function openSellAtSwoopkartInNewTab() {
    const win = window.open("https://sell.swoopkart.varunbukka.in/", "_blank");
    if (win != null) {
      win.focus();
    }
  }

  return (
    <footer className="mt-5 outerFooter">
      <div className="row innerFooter">
        <div className="logosFooter d-flex justify-content-center">
          <img
            src={swoopkartLogo}
            alt="logo"
            id="logo"
            onClick={goToRootHandler}
          />
        </div>
        <div className="mt-4"></div>
        <div className="d-flex justify-content-center container-fluid">
          <div className="container-fluid col-6">
            <div className="mb-1">
              <h5>
                <strong className="color_footer_category">general</strong>
              </h5>
            </div>
            <div style={{ height: "2.5px" }}></div>
            <div className="linksFooter">
              <Link to="/about" className="linksClass">
                about us
              </Link>
              <Link to="/support" className="linksClass">
                support
              </Link>
              <Link
                onClick={openSellAtSwoopkartInNewTab}
                className="linksClass"
              >
                sell at swoopkart
              </Link>
            </div>
          </div>
          <div className="container-fluid col-6">
            <div className="mb-1">
              <h5>
                <strong className="color_footer_category">follow us</strong>
              </h5>
            </div>
            <div style={{ height: "2.5px" }}></div>
            <div className="linksFooter">
              <div className="footer_SocialHandle">
                <img src={instagram} alt="" onClick={openInstagramInNewTab} />
              </div>
              <div className="footer_SocialHandle">
                <img src={twitter} alt="" onClick={openTwitterInNewTab} />
              </div>
              <div className="footer_SocialHandle">
                <img src={facebook} alt="" onClick={openFacebookInNewTab} />
              </div>
              <div className="footer_SocialHandle">
                <img src={youtube} alt="" onClick={openYoutubeInNewTab} />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="footer_terms_conditions mt-5 container-fluid">
            <div className="mb-1">
              <h5>
                <strong className="color_footer_category">
                  terms and conditions
                </strong>
              </h5>
            </div>
            <div className="footer_terms_conditions_text">
              To protect the integrity of our sellers, all orders will be taken
              only after the customer agrees for no return and no refund policy.
              That means the customer will have a chance to cancel an order only
              before its processing starts.
            </div>
          </div>
        </div>
        <div
          className="col-sm-12 copyrightsFooter d-flex justify-content-center mt-4"
          style={{ color: "rgb(255,255,255)" }}
        >
          <span style={{ fontSize: "15.5px" }}>
            © All rights reserved by <strong>swoopkart</strong>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
