import "./ContactSupport.css";
import React, { useEffect } from "react";
const ContactSupport = (props) => {
  const { onLoad } = props;
  useEffect(() => {
    document.title = "Support • Swoopkart";
    onLoad();
  }, []);
  return (
    <div className="">
      <div
        style={{
          paddingTop: "3%",
          paddingBottom: "4.7%",
          backgroundColor: "rgb(255, 245, 173)",
        }}
      >
        <div className="d-flex justify-content-center">
          <div className="aboutUsContainer_aboutUs">
            <strong style={{ color: "rgb(250,103,103)" }}>support</strong>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="" style={{ fontSize: "14px" }}>
            <strong>We are here for you 24/7.</strong>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ marginTop: "25px" }}>
        <div className="mb-4">
          <div
            style={{
              color: "rgb(250,103,103)",
              fontSize: "19px",
              marginLeft: "3px",
              marginBottom: "1%",
            }}
          >
            <strong>Issues regarding Order Cancellation</strong>
          </div>

          <div style={{ marginLeft: "3px" }}>
            Once the seller starts processing from his end cancellation of
            orders is not possible. That means the customer will only have a
            chance to cancel an order only before its processing starts.
          </div>
        </div>
        <div className="mb-4">
          <div
            style={{
              color: "rgb(250,103,103)",
              fontSize: "19px",
              marginLeft: "3px",
              marginBottom: "1%",
            }}
          >
            <strong>Other issues regarding Orders</strong>
          </div>
          <div style={{ marginLeft: "3px" }}>
            Orders are directly accepted by the sellers and after the processing
            starts by the seller, cancellation is not possible.
          </div>
          <div style={{ marginLeft: "3px" }}>
            We have also provided seller's phone number so that you can contact
            the seller directly.
          </div>
          <div style={{ marginLeft: "3px" }}>
            If still you are facing any issues with your orders, describe your
            issue clearly and mention the Order Id in the mail. You can mail us
            at <strong>support@swoopkart.com</strong>
          </div>
        </div>
        <div className="mb-4">
          <div
            style={{
              color: "rgb(250,103,103)",
              fontSize: "19px",
              marginLeft: "3px",
              marginBottom: "1%",
            }}
          >
            <strong>Issues regarding Return & Refund</strong>
          </div>

          <div style={{ marginLeft: "3px" }}>
            To protect the integrity of our sellers, all orders will be taken
            only after the customer agrees for no return and no refund policy.
            That means orders cannot be returned or refunded.
          </div>
        </div>
        <div className="mb-4">
          <div
            style={{
              color: "rgb(250,103,103)",
              fontSize: "19px",
              marginLeft: "3px",
              marginBottom: "1%",
            }}
          >
            <strong>Other issues</strong>
          </div>
          <div style={{ marginLeft: "3px" }} className="mb-2">
            Still if you are facing any issue, write us at{" "}
            <strong>support@swoopkart.com</strong>
          </div>
        </div>
        <div
          style={{
            color: "rgb(250,103,103)",
            marginLeft: "3px",
            paddingTop: "3%",
            textAlign: "center",
          }}
        >
          <strong>With Love Team Swoopkart.</strong>
        </div>
      </div>
    </div>
  );
};
export default React.memo(ContactSupport);
