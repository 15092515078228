import "./FilterBar.css";
import SortIcon from "../../icons/sort.png";
import FilterIcon from "../../icons/filter.png";
import React from "react";
const FilterBar = (props) => {
  return (
    <div className="filter d-flex justify-content-center">
      <div
        onClick={props.sortToggle}
        className="sortButton fs-6 text-center col-6"
        style={{ color: "white" }}
      >
        <img src={SortIcon} alt="sort" style={{ height: "22px" }} /> Sort
      </div>
      <div
        onClick={props.filterToggle}
        className="filterButton fs-6 text-center col-6"
        style={{ color: "white" }}
      >
        <img src={FilterIcon} alt="filter" style={{ height: "22px" }} /> Filter
      </div>
    </div>
  );
};
export default React.memo(FilterBar);
