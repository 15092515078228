import "./ShopPage.css";
import React, { useState } from "react";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import allProducts from "../../icons/all-products.png";
import SearchResultProduct from "../../components/searchresultproduct/SearchResultProduct";
import ProductRoot from "../root/ProductRoot";
import swoopkartQR from "../../icons/swoopkartQR.png";
import SellerQRCodeDrawer from "../../components/SellerQRCodeDrawer/SellerQRCodeDrawer";
import Backdrop from "../../UI/Backdrop/Backdrop";
import useBodyScrollLock from "../../hooks/body-scrool-lock";
import { useEffect } from "react";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import { useParams, useNavigate } from "react-router-dom";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import LogoNavBar from "../../UI/LogoNavBar";
import Footer from "../../UI/Footer/Footer";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";
import NavBar from "../../UI/NavBar/NavBar";
import SearchIcon from "../../icons/search.png";
import upArrow from "../../icons/up.png";
import downArrow from "../../icons/down.png";
import { useSelector, useDispatch } from "react-redux";

const ShopPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "USER_LOAD" });
     }, []);
     const userTokenFromStore = useSelector((state) => state.userData.userToken);
     
     useEffect(() => {
      if (userTokenFromStore) {
        props.fetchCartItemsCount();
      }
    }, []);

  const params = useParams();
  const [toggle] = useBodyScrollLock();
  const [isSellerQRDrawerOpen, setIsSellerQRDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [shopToShow, setShopToShow] = useState(false);
  const [wasCouldntFetch, setWasCouldntFetch] = useState(false);
  const sellerQRDrawerToggleClickHandler = () => {
    setIsSellerQRDrawerOpen((prevState) => !prevState);
    toggle();
  };
  const backdropClickHandler = () => {
    setIsSellerQRDrawerOpen(false);
    toggle();
  };

  const [recievedShopName, setRecievedShopName] = useState("");
  const [recievedShopDescription, setRecievedShopDescription] = useState("");
  const [recievedShopDP, setRecievedShopDP] = useState("");
  const [recievedShopAddress, setRecievedShopAddress] = useState("");
  const [recievedBestSellings, setRecievedBestSellings] = useState([]);
  const [recievedAllProducts, setRecievedAllProducts] = useState([]);

  const [showSearchBar, setShowSearchBar] = useState(false);

  const fetchShopDetaild = async () => {
    try {
      setIsLoading(true);
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL +
          "/seller-page/" +
          params.shopQR +
          "",
        {
          method: "GET",
          headers: { "content-type": "application/json" },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setError("There was a server error.");
        setShopToShow(false);
        setWasCouldntFetch(true);
        setIsLoading(false);
        setCoudntSearch(false);
      }
      if (!response.status) {
        setError("There was a server error.");
        setShopToShow(false);
        setWasCouldntFetch(true);
        setCoudntSearch(false);
      }
      if (response.status === "SHOP_FOUND") {
        setShopToShow(true);
        setRecievedShopName(response.shopName);
        setRecievedShopDP(response.shopDisplayPicture);
        setRecievedShopDescription(response.shopDescription);
        setRecievedBestSellings(response.bestSellingProducts);
        setRecievedAllProducts(response.allProducts);
        setRecievedShopAddress(response.address);
        setWasCouldntFetch(false);
        setCoudntSearch(false);
      }
      if (response.status === "SHOP_NOT_FOUND") {
        setShopToShow(false);
        setError("The shop you were looking for dosen't exist.");
        setWasCouldntFetch(false);
        setCoudntSearch(false);
      }
      setIsLoading(false);
      if (response.status === "REDIRECT_TO_SIGNUP") {
        setIsLoading(true);
        window.location.replace(
          process.env.REACT_APP_SELLER_URL + "/sign-up/" + response.shopQR + "/"
        );
      }
    } catch (err) {
      setWasCouldntFetch(true);
      setIsLoading(false);
    }
  };

  const searchProductsHandler = async () => {
    if (!enteredText.trim() !== "") {
      try {
        setIsLoading(true);
        const requestSent = await fetch(
          process.env.REACT_APP_USER_BACKEND_URL + "/seller-page/search/",
          {
            method: "POST",
            body: JSON.stringify({
              shopQR: params.shopQR,
              keyWord: enteredText,
            }),
            headers: { "content-type": "application/json" },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setError("There was a server error.");
          setShopToShow(false);
          setWasCouldntFetch(true);
          setIsLoading(false);
          setCoudntSearch(false);
        }
        if (!response.status) {
          setError("There was a server error.");
          setShopToShow(false);
          setWasCouldntFetch(true);
          setCoudntSearch(false);
        }
        if (response.status === "SEARCH_PRODUCT_FOUND") {
          setShopToShow(true);
          if (response.allProducts.length > 0) {
            setRecievedAllProducts(response.allProducts);
            setCoudntSearch(false);
          }
          if (response.allProducts.length <= 0) {
            setCoudntSearch(true);
          }
          setWasCouldntFetch(false);
        }
        setIsLoading(false);
      } catch (err) {
        setWasCouldntFetch(true);
        setIsLoading(false);
      }
    }
  };

  const [coudntSearch, setCoudntSearch] = useState(false);

  useEffect(() => {
    fetchShopDetaild();
  }, []);

  useEffect(() => {
    document.title = recievedShopName + " • Seller at Swoopkart";
  }, [recievedShopName]);

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  const [enteredText, setEnteredText] = useState("");

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      {!wasCouldntFetch && (
        <div>
          {shopToShow && (
            <div>
              <NavBar
                drawerToggle={props.drawerToggle}
                signInToggle={props.signInToggle}
                myAccountToggle={props.myAccountToggle}
                shadowForNav={true}
                cartItemsCount={props.cartItemsCount}
              />
              <SellerQRCodeDrawer
                show={isSellerQRDrawerOpen}
                close={sellerQRDrawerToggleClickHandler}
                QRCode={params.shopQR}
                shopName={recievedShopName}
              />
              {isSellerQRDrawerOpen && (
                <Backdrop click={backdropClickHandler} />
              )}
              <div className="shop_page_container">
                <div className="container-fluid">
                  <div className="row mt-3 pt-2 mb-4">
                    <div className="shop_page_sellerbanner_logo col-3 d-flex align-items-start justify-content-center">
                      <div
                        className="position-relative"
                        onClick={sellerQRDrawerToggleClickHandler}
                      >
                        <img
                          src={
                            process.env
                              .REACT_APP_SELLER_BACKEND_IMAGE_DP_LOCATION +
                            "/" +
                            recievedShopDP
                          }
                          alt=""
                        />
                        <span
                          className="position-absolute top-100 start-100 translate-middle d-flex justify-content-center"
                          style={{ color: "white" }}
                        >
                          <img
                            src={swoopkartQR}
                            alt=""
                            style={{
                              borderRadius: "50%",
                              height: "33px",
                              border: "1.5px solid rgb(250, 103, 103)",
                              marginBottom: "17px",
                              marginRight: "14px",
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="col-9" style={{ padding: "0 7px 0 22px" }}>
                      <div className="d-flex justify-content-start">
                        <span className="fs-5 shop_page_name">
                          <strong>{recievedShopName}</strong>
                        </span>
                      </div>
                      <div className="" style={{ paddingRight: "3%" }}>
                        <span className="fs-6">
                          <strong>{recievedShopName}</strong> -{" "}
                          {recievedShopDescription}
                        </span>
                      </div>
                      <div className="" style={{ paddingRight: "3%" }}>
                        <span className="fs-6">
                          <strong>Address</strong> - {recievedShopAddress}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <div className="container-fluid shop_page_heading mb-2">
                    <span className="fw-semibold" style={{ fontSize: "17px" }}>
                      Our Best Selling Products
                    </span>
                  </div>
                  <div
                    className={
                      recievedBestSellings.length <= 0
                        ? ""
                        : "sellerBestSellingContainer"
                    }
                  >
                    {error === "" && (
                      <React.Fragment>
                        {recievedBestSellings.map((bestSelling) => {
                          return (
                            <ProductRoot
                              key={bestSelling._id}
                              _id={bestSelling._id}
                              image={bestSelling.productImage}
                              productName={bestSelling.productName}
                              price={formatNumber(bestSelling.price)}
                              discount={bestSelling.discount}
                              sellerId={bestSelling.sellerId}
                              signInToggle={props.signInToggle}
                            />
                          );
                        })}
                        {recievedBestSellings.length <= 0 && (
                          <div className="container-fluid mt-3">
                            <div className="formGeneralSeller p-5">
                              <span
                                style={{
                                  color: "#494949",
                                  fontSize: "16.5px",
                                }}
                              >
                                This shop currently dosen't have any best
                                selling products.
                              </span>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div className="mt-5">
                  <div
                    className="container-fluid shop_page_heading  d-flex justify-content-start align-items-center mb-2"
                    onClick={() => setShowSearchBar((prev) => !prev)}
                  >
                    <span className="fw-semibold" style={{ fontSize: "17px" }}>
                      All Products
                    </span>
                    {!showSearchBar && (
                      <img
                        src={downArrow}
                        alt=""
                        style={{ height: "16px", marginLeft: "4%" }}
                      />
                    )}
                    {showSearchBar && (
                      <img
                        src={upArrow}
                        alt=""
                        style={{ height: "16px", marginLeft: "4%" }}
                      />
                    )}
                  </div>
                  {showSearchBar && (
                    <div className="mt-2 mb-3 bg-white container-fluid">
                      <div
                        className="d-flex bg-white align-items-center"
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          className="col-11 d-flex align-items-center justify-content-start position-relative"
                          style={{ padding: "0px" }}
                        >
                          <input
                            type="text"
                            name=""
                            id="searchField"
                            className="bg-white"
                            style={{ width: "100%" }}
                            value={enteredText}
                            onChange={(event) =>
                              setEnteredText(event.target.value)
                            }
                          />
                          <span
                            onClick={searchProductsHandler}
                            className="position-absolute end-0"
                            style={{ margin: "0px 10px 1px 0px" }}
                          >
                            <img
                              src={SearchIcon}
                              alt=""
                              className="searchBox_img"
                            />
                          </span>
                        </div>
                        <div
                          className="col-1 d-flex justify-content-center"
                          style={{ marginLeft: "1%" }}
                        >
                          <img
                            src={allProducts}
                            alt=""
                            style={{
                              height: "25px",
                            }}
                            onClick={fetchShopDetaild}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className="container-fluid"
                    style={{ marginBottom: "15%" }}
                  >
                    {error === "" && !coudntSearch && (
                      <React.Fragment>
                        {recievedAllProducts.map((product) => {
                          return (
                            <SearchResultProduct
                              key={product._id}
                              _id={product._id}
                              image={product.productImage}
                              productName={product.productName}
                              price={formatNumber(product.price)}
                              discount={product.discount}
                              mrp={formatNumber(product.MRP)}
                              brand={product.brandName}
                              rating={product.rating}
                              sellerId={product.sellerId}
                              signInToggle={props.signInToggle}
                            />
                          );
                        })}
                        {recievedAllProducts.length <= 0 && !coudntSearch && (
                          <div className="formGeneralSeller p-5 mt-3">
                            <span
                              style={{
                                color: "#494949",
                                fontSize: "16.5px",
                              }}
                            >
                              This shop currently dosen't have any products.
                            </span>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                    {coudntSearch && (
                      <div className="d-flex justify-content-center align-items-center mt-4">
                        <div
                          style={{
                            color: "#494949",
                            fontSize: "18px",
                          }}
                          className="fw-semibold container-fluid"
                        >
                          <div>No results found for "{enteredText}".</div>
                          <div
                            style={{
                              fontSize: "15.5px",
                              fontWeight: "300",
                            }}
                          >
                            Try checking your spelling or use more general
                            terms.
                          </div>
                        </div>
                      </div>
                      // </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          )}
          {!shopToShow && (
            <div>
              <LogoNavBar />
              <div className="container-fluid">
                <div className="d-flex justify-content-center">
                  <h3>Sorry, this page isn't available.</h3>
                </div>
                <div className="container-fluid mt-3 text-center fs-6">
                  The link you followed may be broken, or the page may have been
                  removed.{" "}
                  <span
                    className="fw-semibold"
                    style={{ color: "rgb(250,103,103)" }}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Go back to Swoopkart.
                  </span>
                </div>
              </div>
              <div className="fixed-bottom" style={{ zIndex: "1" }}>
                <SmallFooter />
              </div>
            </div>
          )}
        </div>
      )}
      {wasCouldntFetch && (
        <div>
          <SmallNavBar navBarText="" />
          <CoudntFetch />
        </div>
      )}
    </div>
  );
};
export default React.memo(ShopPage);
