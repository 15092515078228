// #FA6767
import "./App.css";
import React from "react";
import { useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import locationIcon from "./icons/LocationIcon.png";
import { useSelector, useDispatch } from "react-redux";

// start of user imports
import NavBar from "./UI/NavBar/NavBar";
import Footer from "./UI/Footer/Footer";
import SideDrawer from "./UI/sidedrawer/SideDrawer";
import Backdrop from "./UI/Backdrop/Backdrop";
import ForgotPassword from "./routes/forgotpassword/ForgotPassword";
import SearchBox from "./components/searchbox/SearchBox";
import Search from "./routes/search/Search";
import SignIn from "./components/signin/SignIn";
import Cart from "./routes/cart/Cart";
import Root from "./routes/root/Root";
import useBodyScrollLock from "./hooks/body-scrool-lock";
import MyAccountDrawer from "./components/myaccountdrawer/MyAccountDrawer";
import IndividualProductPage from "./routes/individualproductpage/IndividualProductPage";
import SavedForLater from "./routes/savedforlater/SavedForLater";
import ContactSupport from "./routes/contactsupport/ContactSupport";
import OrderHistory from "./routes/orderhistory/Orderhistory";
import IndividualOrderPage from "./routes/individualorderpage/IndividualOrderPage";
import User from "./routes/user/User";
import ChangePassword from "./routes/changepassword/ChangePassword";
import MyAddresses from "./routes/myaddresses/MyAddresses";
import EditProfile from "./routes/editprofile/EditProfile";
import SignUp from "./routes/signup/SignUp";
import PageNotFound from "./routes/PageNotFound/PageNotFound";
import Avatar from "./routes/avatar/Avatar";
import About from "./routes/about/About";
import Categories from "./routes/categories/Categories";
import ResetUserPassword from "./routes/forgotpassword/ResetUserPassword";
import Checkout from "./routes/checkout/Checkout";
import AddressSelector from "./components/AddressSelector/AddressSelector";
import ShopPage from "./routes/shoppage/ShopPage";
import NotAuthenticated from "./routes/notauthenticated/NotAuthenticated";
import NonMobileScreen from "./NonMobileScreen";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "USER_LOAD" });
  }, []);

  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const userDPFromStore = useSelector((state) => state.userData.userDP);
  const userNameFromStore = useSelector((state) => state.userData.userName);
  const userIdFromStore = useSelector((state) => state.userData.userId);

  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
  const [isSignInDrawerOpen, setIsSignInDrawerOpen] = useState(false);
  const [isAddressSelectorDrawerOpen, setIsAddressSelectorDrawerOpen] =
    useState(false);
  const [isMyAccountDrawerOpen, setIsMyAccountDrawerOpen] = useState(false);
  const [toggle] = useBodyScrollLock();
  const drawerToggleClickHandler = () => {
    setIsSideDrawerOpen((prevState) => !prevState);
    toggle();
  };
  const signInToggleClickHandler = () => {
    setIsSignInDrawerOpen((prevState) => !prevState);
    toggle();
  };

  const addressSelectorToggleClickHandler = () => {
    setIsAddressSelectorDrawerOpen((prevState) => !prevState);
    toggle();
  };

  const myAccountToggleClickHandler = () => {
    setIsMyAccountDrawerOpen((prevState) => !prevState);
    toggle();
  };

  const [isPageNotFound, setIsPageNotFound] = useState(false);
  const location = useLocation();

  const [selectedAddress, setSelectedAddress] = useState({});

  const checkURL =
    location.pathname.includes("user") ||
    location.pathname.includes("product") ||
    location.pathname.includes("seller") ||
    location.pathname.includes("signup") ||
    location.pathname.includes("forgotpassword") ||
    location.pathname.includes("shop") ||
    isPageNotFound ||
    location.pathname.includes("checkout");

  const checkURLForSearchBox =
    location.pathname.includes("support") ||
    location.pathname.includes("about");

  const backdropClickHandler = () => {
    setIsSideDrawerOpen(false);
    toggle();
  };
  const signInBackdropClickHandler = () => {
    setIsSignInDrawerOpen(false);
    toggle();
  };

  const myAccountBackdropClickHandler = () => {
    setIsMyAccountDrawerOpen(false);
    toggle();
  };

  const pageNotFoundInvokeHandler = () => {
    setIsPageNotFound(true);
  };
  const otherPagesInvokeHandler = () => {
    setIsPageNotFound(false);
  };

  const navigate = useNavigate();
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const fetchCartItemsCount = async () => {
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL + "/navbar/cart-items-count/",
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setCartItemsCount(0);
      }
      if (response.status === "CART_ITEMS_COUNT_FETCHED") {
        setCartItemsCount(response.cartItemsCount);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setCartItemsCount(0);
      }
    } catch (error) {
      setCartItemsCount(0);
    }
  };

  const fetchSelectedLocation = async () => {
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL +
          "/choose-your-location/get-selected-address/",
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setCartItemsCount(0);
      }
      if (response.status === "SELECTED_ADDRESS_FETCHED") {
        setSelectedAddress(response.selectedAddress);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setCartItemsCount(0);
      }
    } catch (error) {
      setCartItemsCount(0);
    }
  };

  useEffect(() => {
    if (userTokenFromStore) {
      fetchCartItemsCount();
      fetchSelectedLocation();
    }
  }, [userTokenFromStore]);

  const refreshSelectorHandler = () => {
    fetchSelectedLocation();
  };

  return (
    <React.Fragment>
      <div
        style={{ height: "100%" }}
        className="d-sm-none d-md-none d-lg-none d-xl-none d-xxl-none webkitsb"
      >
        {!checkURL && (
          <NavBar
            drawerToggle={drawerToggleClickHandler}
            signInToggle={signInToggleClickHandler}
            myAccountToggle={myAccountToggleClickHandler}
            shadowForNav={checkURLForSearchBox}
            cartItemsCount={cartItemsCount}
          />
        )}
        {!checkURL && !checkURLForSearchBox && <SearchBox />}
        {!checkURL && !checkURLForSearchBox && (
          <div
            className="d-flex bgoflocationroot justify-content-start align-items-center container"
            style={{
              height: "40px",
              color: "black",
              fontSize: "16px",
            }}
            onClick={() => {
              if (userTokenFromStore) {
                addressSelectorToggleClickHandler();
              } else {
                signInToggleClickHandler();
              }
            }}
          >
            <img
              src={locationIcon}
              alt=""
              style={{ height: "18px", marginRight: "3px" }}
            />
            {!(selectedAddress.pincode === 0) && selectedAddress.fullName && (
              <span>
                Deliver to {selectedAddress.fullName} {selectedAddress.pincode}
              </span>
            )}

            {!(selectedAddress.pincode === 0) && !selectedAddress.fullName && (
              <span>Deliver to Telangana, India</span>
            )}

            {selectedAddress.pincode === 0 && (
              <span>Deliver to Telangana, India</span>
            )}
          </div>
        )}

        <SideDrawer
          show={isSideDrawerOpen}
          close={drawerToggleClickHandler}
          signInOpen={signInToggleClickHandler}
        />

        {isSideDrawerOpen && <Backdrop click={backdropClickHandler} />}

        <SignIn show={isSignInDrawerOpen} close={signInToggleClickHandler} />
        {isSignInDrawerOpen && <Backdrop click={signInBackdropClickHandler} />}

        {userTokenFromStore && (
          <React.Fragment>
            <AddressSelector
              show={isAddressSelectorDrawerOpen}
              refreshSelector={refreshSelectorHandler}
              close={addressSelectorToggleClickHandler}
            />
            {isAddressSelectorDrawerOpen && (
              <Backdrop click={addressSelectorToggleClickHandler} />
            )}
          </React.Fragment>
        )}

        <MyAccountDrawer
          show={isMyAccountDrawerOpen}
          close={myAccountToggleClickHandler}
        />
        {isMyAccountDrawerOpen && (
          <Backdrop click={myAccountBackdropClickHandler} />
        )}
        <Routes>
          <Route
            path="/"
            element={
              <Root
                onLoad={otherPagesInvokeHandler}
                signInToggle={signInToggleClickHandler}
                fetchCartItemsCount={fetchCartItemsCount}
              />
            }
          />
          <Route
            path="/signup"
            element={
              !userTokenFromStore ? (
                <SignUp goToSignIn={signInToggleClickHandler} />
              ) : (
                <PageNotFound onLoad={pageNotFoundInvokeHandler} />
              )
            }
          />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route
            path="/forgotpassword/:uniqueURL"
            element={
              <ResetUserPassword goToSignIn={signInToggleClickHandler} />
            }
          />
          <Route
            path="/search/:keyWord"
            element={<Search signInToggle={signInToggleClickHandler} />}
          />
          <Route
            path="/user"
            element={
              userTokenFromStore ? (
                <User />
              ) : (
                <PageNotFound onLoad={pageNotFoundInvokeHandler} />
              )
            }
          />
          <Route
            path="/about"
            element={<About onLoad={otherPagesInvokeHandler} />}
          />
          <Route
            path="/support"
            element={<ContactSupport onLoad={otherPagesInvokeHandler} />}
          />
          <Route
            path="/user/cart"
            element={
              userTokenFromStore ? (
                <Cart />
              ) : (
                <PageNotFound onLoad={pageNotFoundInvokeHandler} />
              )
            }
          />
          {/* productid */}
          <Route
            path="/shop/:sellerId/product/:productId"
            element={
              userTokenFromStore ? (
                <IndividualProductPage
                  signInToggle={signInToggleClickHandler}
                  openAddressSelector={addressSelectorToggleClickHandler}
                />
              ) : (
                <PageNotFound onLoad={pageNotFoundInvokeHandler} />
              )
            }
          />
          <Route
            path="/user/wishlist"
            element={
              userTokenFromStore ? (
                <SavedForLater />
              ) : (
                <PageNotFound onLoad={pageNotFoundInvokeHandler} />
              )
            }
          />
          <Route
            path="/user/orderhistory"
            element={
              userTokenFromStore ? (
                <OrderHistory />
              ) : (
                <PageNotFound onLoad={pageNotFoundInvokeHandler} />
              )
            }
          />
          <Route
            path="/user/edit-profile"
            element={
              userTokenFromStore ? (
                <EditProfile />
              ) : (
                <PageNotFound onLoad={pageNotFoundInvokeHandler} />
              )
            }
          />
          <Route
            path="/user/edit-profile/avatar"
            element={
              userTokenFromStore ? (
                <Avatar />
              ) : (
                <PageNotFound onLoad={pageNotFoundInvokeHandler} />
              )
            }
          />
          <Route
            path="/user/addresses"
            element={
              userTokenFromStore ? (
                <MyAddresses />
              ) : (
                <PageNotFound onLoad={pageNotFoundInvokeHandler} />
              )
            }
          />
          <Route
            path="/user/change-password"
            element={
              userTokenFromStore ? (
                <ChangePassword />
              ) : (
                <PageNotFound onLoad={pageNotFoundInvokeHandler} />
              )
            }
          />
          <Route
            path="/user/checkout"
            element={
              userTokenFromStore ? (
                <Checkout />
              ) : (
                <PageNotFound onLoad={pageNotFoundInvokeHandler} />
              )
            }
          />
          <Route
            path="/categories/:categoryName"
            element={
              <Categories
                signInToggle={signInToggleClickHandler}
                onLoad={otherPagesInvokeHandler}
              />
            }
          />
          <Route
            path="/user/orderhistory/:orderId"
            element={
              userTokenFromStore ? (
                <IndividualOrderPage />
              ) : (
                <PageNotFound onLoad={pageNotFoundInvokeHandler} />
              )
            }
          />
          <Route
            path="/user-not-authenticated"
            element={
              userTokenFromStore ? (
                <PageNotFound onLoad={pageNotFoundInvokeHandler} />
              ) : (
                <NotAuthenticated signInToggle={signInToggleClickHandler} />
              )
            }
          />
          <Route
            path="/shop/:shopQR"
            element={
              <ShopPage
                drawerToggle={drawerToggleClickHandler}
                signInToggle={signInToggleClickHandler}
                myAccountToggle={myAccountToggleClickHandler}
                shadowForNav={checkURLForSearchBox}
                cartItemsCount={cartItemsCount}
                fetchCartItemsCount={fetchCartItemsCount}
              />
            }
          />
          <Route
            path="*"
            element={<PageNotFound onLoad={pageNotFoundInvokeHandler} />}
          />
        </Routes>

        {!checkURL && <Footer />}
      </div>
      <div
        className="d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block"
        style={{ height: "100vh" }}
      >
        <NonMobileScreen />
      </div>
    </React.Fragment>
  );
}

export default App;
