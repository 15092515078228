const AddressOption = (props) => {
  return (
    <div className="AddressForOrder_AddressItem">
      <div className="rbBlock d-flex justify-content-start">
        <div className="address_checkout_radio">
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id={"radio" + props._id}
            onClick={() => props.onClick(props._id)}
          />
        </div>
        <label
          className="form-check-label address_checkout_radio_container"
          htmlFor={"radio" + props._id}
          onClick={() => props.onClick(props._id)}
        >
          <div className="ExistingAddress_FullName fs-6">
            <strong>{props.fullName}</strong>
          </div>
          <div className="ExistingAddress_MobileNumber fs-6">
            {props.phoneNumber}
          </div>
          <div className="ExistingAddress_FlatorHouse fs-6">
            {props.flatHouseBuildingNo}
          </div>
          <div className="ExistingAddress_AreaorStreet fs-6">
            {props.areaStreet}
          </div>
          <div className="ExistingAddress_Landmark fs-6">{props.landmark}</div>
          <div className="ExistingAddress_TownCity_State_Pincode fs-6">
            {props.townCity} {props.pincode}, {props.state}
          </div>
          <div className="ExistingAddress_India fs-6">India</div>
        </label>
      </div>
    </div>
  );
};

export default AddressOption;
