import "./SignIn.css";
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import hideIcon from "../../icons/hide.png";
import showIcon from "../../icons/view.png";
import { useDispatch } from "react-redux";
import signUpIcon from "../../icons/signUpIcon.png";
import forgotPasswordIcon from "../../icons/forgotPasswordIcon.png";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import { useEffect } from "react";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";

const SignIn = (props) => {
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [enteredEmailValid, setEnteredEmailValid] = useState(true);
  const [enteredPasswordValid, setEnteredPasswordValid] = useState(true);
  const [isPasswordBox, setIsPasswordBox] = useState(true);
  const [isFormValid, setIsFormValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  function isValidEmail(email) {
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }

  const formValidationHandler = () => {
    const validation = enteredEmailValid && enteredPasswordValid;
    setIsFormValid(validation);
  };

  let drawerClasses = "signInDrawer shadow";
  if (props.show === true) {
    drawerClasses = "signInDrawer shadow open";
  } else {
    drawerClasses = "signInDrawer shadow";
  }

  const hideUnhideToggle = () => {
    setIsPasswordBox((prevState) => !prevState);
  };
  const navigate = useNavigate();
  const signInHandler = async () => {
    if (enteredEmail.trim() === "") {
      setEnteredEmailValid(false);
      return;
    }
    if (isValidEmail(enteredEmail)) {
      setEnteredEmailValid(false);
      return;
    }
    setEnteredEmailValid(true);
    if (enteredPassword === "") {
      setEnteredPasswordValid(false);
      return;
    }
    setEnteredPasswordValid(true);
    formValidationHandler();
    if (isFormValid) {
      setIsLoading(true);
      try {
        const sentRequest = await fetch(
          process.env.REACT_APP_USER_BACKEND_URL + "/sign-in/",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              enteredEmail: enteredEmail,
              enteredPassword: enteredPassword,
            }),
          }
        );

        const response = await sentRequest.json();
        if (response.status === "NOT_FOUND") {
          setError("No user found with this email.");
        }
        if (!response.status) {
          setError("There was a server error.");
        }
        if (response.status === "PASSWORD_DOSENT_MATCH") {
          setError("You have entered an incorrect password. Please try again.");
        }
        if (response.status === "FAILED") {
          setError("There was a server error.");
        }
        if (response.status === "INVALID_EMAIL") {
          setError("Enter a valid email address.");
        }
        if (response.status === "TOKEN_GENERATED") {
          props.close();
          navigate("/");
          localStorage.setItem("user", response.token);
          localStorage.setItem("userId", response._id);
          localStorage.setItem("userName", response.fullName);
          localStorage.setItem("userDP", response.avatar);
          dispatch({ type: "USER_LOGGING_IN" });
          setError("");
          setEnteredEmail("");
          setEnteredPassword("");
        }
        setIsLoading(false);
      } catch (error) {
        setError("Unable to reach the server. Try again later.");
        setIsLoading(false);
      }
    }
  };

  return (
    <nav className={drawerClasses}>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <div className="container-fluid">
        <div className="container-fluid">
          <div>
            <div className="d-flex justify-content-center headingSignin p-1 mb-2">
              <h3>Sign in</h3>
            </div>
            {(!enteredEmailValid || !enteredPasswordValid || error !== "") && (
              <div className="mb-3 mt-3 formErrorSeller">
                {!enteredEmailValid && (
                  <span>Enter a valid email address.</span>
                )}
                {!enteredPasswordValid && <span>Enter a valid password.</span>}
                {error !== "" && <span>{error}</span>}
              </div>
            )}
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInputuserSignin_email"
                placeholder="name@example.com"
                value={enteredEmail}
                onChange={(event) => setEnteredEmail(event.target.value.trim())}
              />
              <label htmlFor="floatingInputuserSignin_email">
                Email address
              </label>
            </div>
            <div className="input-group">
              <div className="form-floating">
                <input
                  type={isPasswordBox ? "password" : "text"}
                  className="form-control"
                  id="floatingInputuserSignin_password"
                  placeholder="Password"
                  value={enteredPassword}
                  onChange={(event) => setEnteredPassword(event.target.value)}
                />
                <label htmlFor="floatingInputuserSignin_password">
                  Password
                </label>
              </div>
              <button
                className="passHideUnhideButton"
                type="button"
                id="button-addon2"
                onClick={hideUnhideToggle}
              >
                <img src={isPasswordBox ? showIcon : hideIcon} alt="" />
              </button>
            </div>
            <div className="nextButton" style={{ marginBottom: "7%" }}>
              <button className="nextButtonCustom" onClick={signInHandler}>
                Sign in
              </button>
            </div>
            <div className="container-fluid">
              <div
                style={{ borderBottom: "2px solid rgb(255, 216, 216)" }}
              ></div>
            </div>
            <div
              className="d-flex justify-content-center signInOtherControls_Container"
              onClick={props.close}
            >
              <Link
                to="/forgotpassword"
                className="link signInOtherControls_Control d-flex justify-content-center align-items-center"
              >
                <img
                  src={forgotPasswordIcon}
                  alt=""
                  style={{ height: "18px", marginRight: "5px" }}
                />
                Forgot Password
              </Link>
              <Link
                to="/signup"
                className="link signInOtherControls_Control d-flex justify-content-center align-items-center"
              >
                <img
                  src={signUpIcon}
                  alt=""
                  style={{ height: "18px", marginRight: "3px" }}
                />
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default SignIn;
