import "./MyAddresses.css";
import React, { useState, useEffect } from "react";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import plusIcon from "../../icons/plus.png";
import ExistingAddress from "./ExistingAddress/ExistingAddress";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";
import { useNavigate } from "react-router-dom";

const MyAddresses = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "USER_LOAD" });
    document.title = "My Addresses • Swoopkart";
  }, []);
  const [addressesRecieved, setAddressesRecieved] = useState([]);
  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const userDPFromStore = useSelector((state) => state.userData.userDP);
  const userNameFromStore = useSelector((state) => state.userData.userName);
  const userIdFromStore = useSelector((state) => state.userData.userId);

  const [formError, setFormError] = useState("");
  const [error, setError] = useState("");
  const [wasCouldntFetch, setWasCouldntFetch] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const formValidationHandler = () => {
    const validation =
      enteredFullNameValid &&
      enteredPhonenumberValid &&
      enteredPincodeValid &&
      enteredFlatOrHouseValid &&
      enteredAreaOrStreetValid &&
      enteredLandmarkValid;
    setIsFormValid(validation);
  };

  const [enteredFullName, setEnteredFullName] = useState("");
  const [enteredPhonenumber, setEnteredPhonenumber] = useState("");
  const [enteredPincode, setEnteredPincode] = useState("");
  const [enteredFlatOrHouse, setEnteredFlatOrHouse] = useState("");
  const [enteredAreaOrStreet, setEnteredAreaOrStreet] = useState("");
  const [enteredLandmark, setEnteredLandmark] = useState("");
  const [enteredTownOrCity, setEnteredTownOrCity] = useState("");
  const [enteredState, setEnteredState] = useState("");

  const [enteredFullNameValid, setEnteredFullNameValid] = useState(true);
  const [enteredPhonenumberValid, setEnteredPhonenumberValid] = useState(true);
  const [enteredPincodeValid, setEnteredPincodeValid] = useState(true);
  const [enteredFlatOrHouseValid, setEnteredFlatOrHouseValid] = useState(true);
  const [enteredAreaOrStreetValid, setEnteredAreaOrStreetValid] =
    useState(true);
  const [enteredLandmarkValid, setEnteredLandmarkValid] = useState(true);

  const [isAddAddressClicked, setIsAddAddressClicked] = useState(false);
  const addAddressToggleHandler = () => {
    setIsAddAddressClicked(true);
  };
  const cancelAddAddressToggleHandler = () => {
    setFormError("");
    setWasFetchFromAPISuccessful(false);
    setEnteredFullName("");
    setEnteredPhonenumber("");
    setEnteredPincode("");
    setEnteredFlatOrHouse("");
    setEnteredAreaOrStreet("");
    setEnteredLandmark("");
    setEnteredTownOrCity("");
    setEnteredState("");
    setIsAddAddressClicked(false);
  };

  const addAddressClickHandler = async () => {
    if (enteredFullName.trim() === "") {
      setFormError("Enter a valid full name.");
      setEnteredFullNameValid(false);
      return;
    }
    setEnteredFullNameValid(true);
    if (enteredPhonenumber.length !== 10) {
      setFormError("Enter a valid phone number.");
      setEnteredPhonenumberValid(false);
      return;
    }
    setEnteredPhonenumberValid(true);
    if (enteredPincode.trim() === "") {
      setFormError("Enter a valid pincode.");
      setEnteredPincodeValid(false);
      return;
    }
    setEnteredPincodeValid(true);
    if (enteredFlatOrHouse.trim() === "") {
      setFormError("Enter a valid flat or house number.");
      setEnteredFlatOrHouseValid(false);
      return;
    }
    setEnteredFlatOrHouseValid(true);
    if (enteredAreaOrStreet.trim() === "") {
      setFormError("Enter a valid area or street.");
      setEnteredAreaOrStreetValid(false);
      return;
    }
    setEnteredAreaOrStreetValid(true);
    if (enteredLandmark.trim() === "") {
      setFormError("Enter a valid landmark.");
      setEnteredLandmarkValid(false);
      return;
    }
    setEnteredAreaOrStreetValid(true);
    setEnteredLandmarkValid(true);
    formValidationHandler();
    formValidationHandler();
    if (isFormValid) {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_USER_BACKEND_URL +
            "/my-addresses/add-new-address",
          {
            method: "POST",
            body: JSON.stringify({
              enteredFullName: enteredFullName,
              enteredPhonenumber: enteredPhonenumber,
              enteredPincode: enteredPincode,
              enteredFlatOrHouse: enteredFlatOrHouse,
              enteredAreaOrStreet: enteredAreaOrStreet,
              enteredLandmark: enteredLandmark,
              enteredTownOrCity: enteredTownOrCity,
              enteredState: enteredState,
            }),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + userTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setFormError("There was a server error.");
          setWasCouldntFetch(false);
        }
        if (response.status === "ADDRESSES_ADDED") {
          setWasCouldntFetch(false);
          cancelAddAddressToggleHandler();
          setAddressesRecieved(response.addresses);
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "USER_LOGGING_OUT" });
          navigate("/user-not-authenticated");
        }
        if (!response.status) {
          setWasCouldntFetch(false);
          setFormError("There was a server error.");
        }
        setIsLoading(false);
      } catch (error) {
        setWasCouldntFetch(true);
        setIsLoading(false);
      }
    }
  };

  const deleteAddressHandler = async (AddressId) => {
    try {
      setIsLoading(true);
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL + "/my-addresses/delete-address",
        {
          method: "POST",
          body: JSON.stringify({
            recievedAddressId: AddressId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWasCouldntFetch(false);
        setError("There was a server error.");
      }
      if (response.status === "ADDRESS_DELETED") {
        setWasCouldntFetch(false);
        setAddressesRecieved(response.addresses);
        setError("");
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setWasCouldntFetch(false);
        setError("There was a server error.");
      }
      setIsLoading(false);
    } catch (error) {
      setWasCouldntFetch(true);
      setIsLoading(false);
    }
  };

  const [wasFetchFromAPISuccessful, setWasFetchFromAPISuccessful] =
    useState(false);

  const checkValidityClickHandler = async (pincode) => {
    if (enteredPincode !== "") {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          "https://api.postalpincode.in/pincode/" + pincode,
          {
            method: "GET",
          }
        );
        const response = await requestSent.json();
        if (response[0].Status === "Success") {
          setEnteredTownOrCity(response[0].PostOffice[0].Division + "");
          setEnteredState(response[0].PostOffice[0].State);
          setWasFetchFromAPISuccessful(true);
          setFormError("");
        }
        if (response[0].Status === "Error") {
          setFormError("Pincode not found. Enter a valid pincode.");
        }
        if (!response[0].Status) {
          setFormError("There was a server error.");
        }
        setIsLoading(false);
      } catch (error) {
        setFormError("Unable to reach the server. Try again later.");
        setIsLoading(false);
      }
    } else {
      setFormError("Enter a valid pincode.");
    }
  };

  useEffect(() => {
    const fetchLoadHandler = async () => {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_USER_BACKEND_URL + "/my-addresses/",
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + userTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setWasCouldntFetch(false);
          setError("There was a server error.");
        }
        if (response.status === "ADDRESSES_RETRIEVED") {
          setWasCouldntFetch(false);
          setAddressesRecieved(response.addresses);
          setError("");
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "USER_LOGGING_OUT" });
          navigate("/user-not-authenticated");
        }
        if (!response.status) {
          setWasCouldntFetch(false);
          setError("There was a server error.");
        }
        setIsLoading(false);
      } catch (error) {
        setWasCouldntFetch(true);
        setIsLoading(false);
      }
    };
    fetchLoadHandler();
  }, []);

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <SmallNavBar navBarText="My Addresses" />
      {!wasCouldntFetch && (
        <div>
          <div className="MyAddresses_container">
            {!isAddAddressClicked && (
              <div
                className="MyAccount_addNewAddresses d-flex flex-column align-items-center"
                onClick={addAddressToggleHandler}
              >
                <div className="row" style={{ margin: "0px" }}>
                  <div
                    className="col-2 d-flex justify-content-end"
                    style={{ paddingRight: "0px" }}
                  >
                    <div className="MyAccount_addNewAddresses_img">
                      <img src={plusIcon} alt="" />
                    </div>
                  </div>
                  <div
                    className="col-10"
                    style={{ paddingLeft: "6px", marginTop: "-1px" }}
                  >
                    <div
                      className="MyAccount_addNewAddresses_text"
                      style={{ color: "rgb(250,103,103)" }}
                    >
                      <b>Add New Address</b>
                    </div>
                    <div className="MyAccount_addNewAddresses_text_exp">
                      You will only be able to purchase the products from the
                      sellers available in your pincodes if the seller dosen't
                      sell countrywide.
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isAddAddressClicked && (
              <div className="addNewAddresses_form">
                <div>
                  <div className="addNewAddresses_form_heading">
                    <strong className="fs-5">Add a new address</strong>
                  </div>
                  {formError !== "" && (
                    <div className="mb-3 formErrorSeller">
                      {formError !== "" && <div>{formError}</div>}
                    </div>
                  )}
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="addNewAdd_fullname"
                      placeholder="Full Name"
                      value={enteredFullName}
                      onChange={(event) => {
                        setEnteredFullName(event.target.value);
                      }}
                    />
                    <label htmlFor="addNewAdd_fullname">Full Name</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="number"
                      className="form-control"
                      id="addNewAdd_phonenumber"
                      placeholder="Mobile Number"
                      value={enteredPhonenumber}
                      onChange={(event) => {
                        setEnteredPhonenumber(event.target.value);
                      }}
                    />
                    <label htmlFor="addNewAdd_phonenumber">Phone Number</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="number"
                      className="form-control"
                      id="addNewAdd_pincode"
                      placeholder="Pincode"
                      value={enteredPincode}
                      onChange={(event) => {
                        setEnteredPincode(event.target.value);
                      }}
                    />
                    <label htmlFor="addNewAdd_pincode">Pincode</label>
                  </div>
                  {!wasFetchFromAPISuccessful && (
                    <div className="mt-4 pt-2 d-flex justify-content-center">
                      <button
                        className="seller_updateProduct_cancelbutton"
                        onClick={cancelAddAddressToggleHandler}
                      >
                        Cancel
                      </button>
                      <button
                        className="seller_addProduct_button"
                        onClick={() =>
                          checkValidityClickHandler(enteredPincode)
                        }
                      >
                        Check Validity
                      </button>
                    </div>
                  )}
                  {wasFetchFromAPISuccessful && (
                    <div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="addNewAdd_flatorhouse"
                          placeholder="Flat, House no., Building, Company, Apartment"
                          value={enteredFlatOrHouse}
                          onChange={(event) => {
                            setEnteredFlatOrHouse(event.target.value);
                          }}
                        />
                        <label htmlFor="addNewAdd_flatorhouse">
                          Flat, House no., Building, Apartment
                        </label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="addNewAdd_areaorstreet"
                          placeholder="Area, Street, Sector, Village"
                          value={enteredAreaOrStreet}
                          onChange={(event) => {
                            setEnteredAreaOrStreet(event.target.value);
                          }}
                        />
                        <label htmlFor="addNewAdd_areaorstreet">
                          Area, Street, Sector, Village
                        </label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="addNewAdd_landmark"
                          placeholder="Landmark"
                          value={enteredLandmark}
                          onChange={(event) => {
                            setEnteredLandmark(event.target.value);
                          }}
                        />
                        <label htmlFor="addNewAdd_landmark">Landmark</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="addNewAdd_towncity"
                          placeholder="Town/City"
                          disabled={true}
                          value={enteredTownOrCity}
                        />
                        <label htmlFor="addNewAdd_towncity">Town/City</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="addNewAdd_StateAndCountry"
                          placeholder="State and Country"
                          disabled={true}
                          value={enteredState}
                        />
                        <label htmlFor="addNewAdd_StateAndCountry">
                          State and Country
                        </label>
                      </div>
                    </div>
                  )}
                </div>

                {wasFetchFromAPISuccessful && (
                  <div className="mt-4 pt-2 d-flex justify-content-center">
                    <button
                      className="seller_updateProduct_cancelbutton"
                      onClick={cancelAddAddressToggleHandler}
                    >
                      Cancel
                    </button>
                    <button
                      className="seller_addProduct_button"
                      onClick={addAddressClickHandler}
                    >
                      Add Address
                    </button>
                  </div>
                )}
              </div>
            )}
            <div
              className="d-flex justify-content-start mt-5"
              style={{ marginRight: "5%", marginLeft: "6%" }}
            >
              <span
                className="fw-semibold"
                style={{ fontSize: "19px", color: "rgb(250,103,103)" }}
              >
                My Addresses
              </span>
            </div>
            <div className="container-fluid">
              <div className="container-fluid">
                <div className="loadedDataSeparaterForItems container-fluid"></div>
              </div>
            </div>
            <div className="">
              {error !== "" && (
                <div
                  className="mb-3 formErrorSeller"
                  style={{ marginRight: "5%", marginLeft: "5%" }}
                >
                  {error !== "" && <div>{error}</div>}
                </div>
              )}
              {error === "" && (
                <React.Fragment>
                  {addressesRecieved.map((address) => {
                    return (
                      <ExistingAddress
                        key={address._id}
                        _id={address._id}
                        fullName={address.fullName}
                        phoneNumber={address.phoneNumber}
                        pincode={address.pincode}
                        flatHouseBuildingNo={address.flatHouseBuildingNo}
                        areaStreet={address.areaStreet}
                        landmark={address.landmark}
                        townCity={address.townCity}
                        state={address.state}
                        DeleteHandler={deleteAddressHandler}
                      />
                    );
                  })}
                  {addressesRecieved.length <= 0 && (
                    <div
                      className="formGeneralSeller"
                      style={{ marginRight: "5%", marginLeft: "5%" }}
                    >
                      You have not registered any address.
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>

          <div className="p-3"></div>
        </div>
      )}
      {wasCouldntFetch && <CoudntFetch />}
    </div>
  );
};
export default React.memo(MyAddresses);
