import "./OrderHistoryItem.css";
import React from "react";
import { useNavigate } from "react-router-dom";

const OrderhistoryItem = (props) => {
  const navigate = useNavigate();
  const viewMoreHandler = () => {
    navigate("/user/orderhistory/" + props._id);
  };
  return (
    <div className="orderHistoryItem" onClick={viewMoreHandler}>
      <div className="container-fluid">
        <div className="orderHistoryItemInfo_SoldBy">
          Sold by
          <span className="fw-bold">
            {"  "}
            {props.soldBy}
          </span>
        </div>
        <div className="orderHistoryItemInfo_OrderedOn">
          {props.orderedOn} <strong>•</strong>{" "}
          <span style={{ color: "rgb(250,103,103)" }}>{props.remarks}</span>
        </div>
        <div className="orderHistoryItemInfo_ImagesOfProducts d-flex justify-content-start align-items-center">
          {props.totalItems >= 1 && (
            <img
              src={
                process.env.REACT_APP_USER_BACKEND_PRODUCT_IMAGE_LOCATION +
                "/" +
                props.cartProducts[0].productImage
              }
              alt=""
            />
          )}
          {props.totalItems >= 2 && (
            <img
              src={
                process.env.REACT_APP_USER_BACKEND_PRODUCT_IMAGE_LOCATION +
                "/" +
                props.cartProducts[1].productImage
              }
              alt=""
            />
          )}
          {props.totalItems >= 3 && (
            <img
              src={
                process.env.REACT_APP_USER_BACKEND_PRODUCT_IMAGE_LOCATION +
                "/" +
                props.cartProducts[2].productImage
              }
              alt=""
            />
          )}
          {props.totalItems >= 4 && (
            <img
              src={
                process.env.REACT_APP_USER_BACKEND_PRODUCT_IMAGE_LOCATION +
                "/" +
                props.cartProducts[3].productImage
              }
              alt=""
            />
          )}
          {props.totalItems >= 5 && (
            <img
              src={
                process.env.REACT_APP_USER_BACKEND_PRODUCT_IMAGE_LOCATION +
                "/" +
                props.cartProducts[4].productImage
              }
              alt=""
            />
          )}

          {props.totalItems > 5 && (
            <div className="orderHistoryItemInfo_ImagesOfProductsEnd d-flex justify-content-center align-items-center">
              {props.totalItems - 5}
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-5">
            <div className="fw-semibold orderHistoryItemInfo_PandOpHeading">
              Products
            </div>
            <div className="orderHistoryItemInfo_PandOp">
              {props.totalItems} Products
            </div>
          </div>
          <div className="col-5">
            <div className="fw-semibold orderHistoryItemInfo_PandOpHeading">
              Order Value
            </div>
            <div className="orderHistoryItemInfo_PandOp">
              ₹ {props.grandTotal}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(OrderhistoryItem);
