import React from "react";
import "./LoadingProduct.css";

const LoadingRootProduct = () => {
  return (
    <div className="productRoot">
      <div className="productRootInner">
        <div className="rootProductImage_cont d-flex justify-content-center">
          <div className="loadingProductRootImage"></div>
        </div>
        <div
          style={{ paddingLeft: "3.5%", paddingRight: "3.5%" }}
          className="mt-2"
        >
          <div className="rootProductTitle rootProductPrice">
            <span className="loadingRootProductText"></span>
          </div>
          <div className="rootProductPrice mt-1">
            <span className="loadingRootProductText"></span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoadingRootProduct;
