import "./SortDrawer.css";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import { useEffect } from "react";

const SortDrawer = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { search } = location;
  const { sort, reviews, discount, price } = queryString.parse(search);

  let drawerClasses = "sortDrawer shadow";
  if (props.show === true) {
    drawerClasses = "sortDrawer shadow open";
  } else {
    drawerClasses = "sortDrawer shadow";
  }

  return (
    <nav className={drawerClasses}>
      <div className="sort d-flex justify-content-start">
        <span>
          <strong>Sort by</strong>
        </span>
      </div>
      <div className="container-fluid">
        <div className="rbBlock d-flex justify-content-between">
          <label className="form-check-label" htmlFor="Relavance">
            <b>Relavance</b>
          </label>
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="Relavance"
            value="relavance"
            onChange={() => {
              navigate(
                "/search/" +
                  params.keyWord +
                  "?sort=relavance" +
                  "&reviews=" +
                  reviews +
                  "&discount=" +
                  discount +
                  "&price=" +
                  price
              );
              props.sortToggle();
            }}
          />
        </div>
        <div className="rbBlock d-flex justify-content-between">
          <label className="form-check-label" htmlFor="Popularity">
            <b>Popularity</b>
          </label>
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="Popularity"
            value="popularity"
            onChange={() => {
              navigate(
                "/search/" +
                  params.keyWord +
                  "?sort=popularity" +
                  "&reviews=" +
                  reviews +
                  "&discount=" +
                  discount +
                  "&price=" +
                  price
              );
              props.sortToggle();
            }}
          />
        </div>
        <div className="rbBlock d-flex justify-content-between">
          <label className="form-check-label" htmlFor="PriceLowToHigh">
            <b>Price - Low to High</b>
          </label>
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="PriceLowToHigh"
            value="priceLowToHigh"
            onChange={() => {
              navigate(
                "/search/" +
                  params.keyWord +
                  "?sort=pricelth" +
                  "&reviews=" +
                  reviews +
                  "&discount=" +
                  discount +
                  "&price=" +
                  price
              );
              props.sortToggle();
            }}
          />
        </div>
        <div className="rbBlock d-flex justify-content-between">
          <label className="form-check-label" htmlFor="PriceHighToLow">
            <b>Price - High to Low</b>
          </label>
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="PriceHighToLow"
            value="priceHighToLow"
            onChange={() => {
              navigate(
                "/search/" +
                  params.keyWord +
                  "?sort=pricehtl" +
                  "&reviews=" +
                  reviews +
                  "&discount=" +
                  discount +
                  "&price=" +
                  price
              );
              props.sortToggle();
            }}
          />
        </div>
      </div>
    </nav>
  );
};
export default SortDrawer;
