import {
    createStore
} from "redux";


const reducerFunction = (state = {
    userData: {},
}, action) => {
    if (action.type === "USER_LOAD") {
        const userTokenData = localStorage.getItem('user');
        const userNameData = localStorage.getItem('userName');
        const userDPData = localStorage.getItem('userDP');
        const userIdData = localStorage.getItem('userId');
        if (!userTokenData || !userNameData || !userDPData || !userIdData) {
            return {
                userData: {}
            }
        }
        if (userTokenData || userNameData || userDPData || userIdData) {
            return {
                userData: {
                    userToken: userTokenData,
                    userDP: userDPData,
                    userName: userNameData,
                    userId: userIdData
                },
            }
        }
    }
    if (action.type === "USER_LOGGING_IN") {
        const userTokenData = localStorage.getItem('user');
        const userNameData = localStorage.getItem('userName');
        const userDPData = localStorage.getItem('userDP');
        const userIdData = localStorage.getItem('userId');
        return {
            userData: {
                userToken: userTokenData,
                userDP: userDPData,
                userName: userNameData,
                userId: userIdData
            },
        }
    }
    if (action.type === "USER_LOGGING_OUT") {
        localStorage.removeItem('user');
        localStorage.removeItem('userName');
        localStorage.removeItem('userDP');
        localStorage.removeItem('userId');
        return {
            userData: {},
        }
    }
    return state;
};

const store = createStore(reducerFunction);

export default store;