import { useState, useEffect } from "react";
const useBodyScrollLock = () => {
  const bodyStyle = document.body.style;
  const [isLocked, setIsLocked] = useState(bodyStyle.overflowy === "hidden");
  useEffect(() => {
    bodyStyle.overflowY = isLocked ? "hidden" : "auto";
  }, [isLocked, bodyStyle]);
  const toggle = () => setIsLocked((prevState) => !prevState);
  return [toggle];
};
export default useBodyScrollLock;
