import "./Root.css";
import React from "react";
import ProductRoot from "./ProductRoot";
import carousel1 from "../../icons/carousel1.png";
import carousel2 from "../../icons/carousel2.png";
import carousel3 from "../../icons/carousel3.png";
import merchantBanner from "../../icons/merchant-banner.jpeg";
import userBanner from "../../icons/user1.jpg";
import gadgetCategory from "../../icons/root/smartphone.png";
import menCategory from "../../icons/root/men-fashion.png";
import womenCategory from "../../icons/root/women-fashion.png";
import kidCategory from "../../icons/root/children.png";
import footwearCategory from "../../icons/root/footwear.png";
import homeCategory from "../../icons/root/home.png";
import perfumeCategory from "../../icons/root/perfume.png";
import makeupCategory from "../../icons/root/makeup.png";
import fitnessCategory from "../../icons/root/fitness.png";
import stationeryCategory from "../../icons/root/stationery.png";
import groceriesCategory from "../../icons/root/groceries.png";
import foodCategory from "../../icons/root/food.png";
import toysCategory from "../../icons/root/toys.png";
import petsCategory from "../../icons/root/pets.png";
import appliancesCategory from "../../icons/root/appliance.png";
import giftingCategory from "../../icons/root/gifting.png";
import officeCategory from "../../icons/root/office.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import LoadingRootProduct from "../../UI/LoadingProduct/LoadingRootProduct";
const Root = (props) => {
  const { onLoad } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "USER_LOAD" });
    onLoad();
    document.title =
      "Swoopkart • Shop Online from India's small stores - Buy mobiles, laptops, cameras, books, watches, apparel and shoes. Instant Shipping & Cash on Delivery Available.";
  }, []);
  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const userDPFromStore = useSelector((state) => state.userData.userDP);
  const userNameFromStore = useSelector((state) => state.userData.userName);
  const userIdFromStore = useSelector((state) => state.userData.userId);

  useEffect(() => {
    if (userTokenFromStore) {
      props.fetchCartItemsCount();
    }
  }, []);

  const merchantBannerHandler = () => {
    const win = window.open("https://sell.swoopkart.varunbukka.in/", "_blank");
    if (win != null) {
      win.focus();
    }
  };
  const redirectToSignUp = () => {
    navigate("/signup");
  };

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);
  const [trendingProducts, setTrendingProducts] = useState([]);
  const [recomendedProducts, setRecomendedProducts] = useState([]);

  const userLoadHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL + "/root/",
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setError("There was a server error.");
      }
      if (response.status === "ROOT_PRODUCTS_LOADED") {
        setError("");
        setBestSellingProducts(response.bestSellingProducts);
        setRecomendedProducts(response.recomendedProducts);
        setPopularProducts(response.popularProducts);
        setTrendingProducts(response.trendingProducts);
      }
      if (!response.status) {
        setError("There was a server error.");
      }
      setIsLoading(false);
    } catch (error) {
      setError("There was a server error.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    userLoadHandler();
  }, []);

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  return (
    <div className="root">
      <div>
        <div
          className="categoriesRoot_container pb-4 pt-4"
          style={{ backgroundColor: "#f0fff1", marginBottom: "0px" }}
        >
          <div
            className="categoriesRoot_category d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/Gadgets");
            }}
          >
            <img src={gadgetCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>Gadgets</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category  d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/Appliances");
            }}
          >
            <img src={appliancesCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>Appliances</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category  d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/Groceries");
            }}
          >
            <img src={groceriesCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>Groceries</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category  d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/Food");
            }}
          >
            <img src={foodCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>Food</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category  d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/Footwear");
            }}
          >
            <img src={footwearCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>Footwear</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/MensFashion");
            }}
          >
            <img src={menCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>M's Fashion</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category  d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/WomensFashion");
            }}
          >
            <img src={womenCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>W's Fashion</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category  d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/Kids");
            }}
          >
            <img src={kidCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>Kids</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category  d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/Stationery");
            }}
          >
            <img src={stationeryCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>Stationery</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category  d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/Home");
            }}
          >
            <img src={homeCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>Home</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category  d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/Perfumes");
            }}
          >
            <img src={perfumeCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>Perfumes</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category  d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/Beauty");
            }}
          >
            <img src={makeupCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>Beauty</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category  d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/Wellness");
            }}
          >
            <img src={fitnessCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>Wellness</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category  d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/Pets");
            }}
          >
            <img src={petsCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>Pets</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category  d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/Toys");
            }}
          >
            <img src={toysCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>Toys</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category  d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/Gifting");
            }}
          >
            <img src={giftingCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>Gifting</strong>
            </div>
          </div>
          <div
            className="categoriesRoot_category  d-flex align-items-center flex-column"
            style={{ backgroundColor: "#f0fff1" }}
            onClick={() => {
              navigate("/categories/Office");
            }}
          >
            <img src={officeCategory} alt="" />
            <div className="fs-6 text-center ___text">
              <strong>Office</strong>
            </div>
          </div>
        </div>
        <div className="adCarousel">
          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={carousel1} className="d-block w-100" alt="..." />
              </div>
              <div className="carousel-item">
                <img src={carousel2} className="d-block w-100" alt="..." />
              </div>
              <div className="carousel-item">
                <img src={carousel3} className="d-block w-100" alt="..." />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        {!userTokenFromStore && (
          <div className="rootWholeContainer d-flex justify-content-center">
            <div>
              <img
                src={userBanner}
                alt="merchant"
                className="rootUserBannerImg"
              />
            </div>
            <div
              className="rootUserBannerText d-flex align-items-center"
              onClick={redirectToSignUp}
            >
              <span className="container-fluid">
                Start shopping for your loved ones. <strong>Sign Up</strong>{" "}
                Now!
              </span>
            </div>
          </div>
        )}
        <div className="bg-swoopkart">
          <div className="rootWholeContainer ">
            <div className="container-fluid mb-2">
              <span
                className="fw-semibold"
                style={{ fontSize: "18px", color: "rgb(250,103,103)" }}
              >
                Today's Recomendations
              </span>
            </div>
            <div className="recomendationsRoot">
              {(error !== "" || isLoading) && (
                <React.Fragment>
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                </React.Fragment>
              )}
              {error === "" && (
                <React.Fragment>
                  {recomendedProducts.map((recomended) => {
                    return (
                      <ProductRoot
                        key={recomended._id}
                        _id={recomended._id}
                        image={recomended.productImage}
                        productName={recomended.productName}
                        price={formatNumber(recomended.price)}
                        discount={recomended.discount}
                        sellerId={recomended.sellerId}
                        signInToggle={props.signInToggle}
                      />
                    );
                  })}
                  {recomendedProducts.length <= 0 && (
                    <div className="container-fluid mt-3 mb-3">
                      <div className="formGeneralSeller p-5">
                        <span
                          style={{
                            color: "#494949",
                            fontSize: "16.5px",
                          }}
                        >
                          {" "}
                          No recomendations found for you.
                        </span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="rootWholeContainer">
            <div className="container-fluid mb-2">
              <span
                className="fw-semibold"
                style={{ fontSize: "18px", color: "rgb(250,103,103)" }}
              >
                Popular
              </span>
            </div>
            <div className="popularRoot">
              {(error !== "" || isLoading) && (
                <React.Fragment>
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                </React.Fragment>
              )}
              {error === "" && (
                <React.Fragment>
                  {popularProducts.map((popular) => {
                    return (
                      <ProductRoot
                        key={popular._id}
                        _id={popular._id}
                        image={popular.productImage}
                        productName={popular.productName}
                        price={formatNumber(popular.price)}
                        discount={popular.discount}
                        sellerId={popular.sellerId}
                        signInToggle={props.signInToggle}
                      />
                    );
                  })}
                  {popularProducts.length <= 0 && (
                    <div className="container-fluid mt-3 mb-3">
                      <div className="formGeneralSeller p-5">
                        <span
                          style={{
                            color: "#494949",
                            fontSize: "16.5px",
                          }}
                        >
                          {" "}
                          No best popular products found.
                        </span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="rootWholeContainer">
            <div className="container-fluid mb-2">
              <span
                className="fw-semibold"
                style={{ fontSize: "18px", color: "rgb(250,103,103)" }}
              >
                Best Sellings
              </span>
            </div>
            <div className="bestSellerRoot">
              {(error !== "" || isLoading) && (
                <React.Fragment>
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                </React.Fragment>
              )}
              {error === "" && (
                <React.Fragment>
                  {bestSellingProducts.map((bestSelling) => {
                    return (
                      <ProductRoot
                        key={bestSelling._id}
                        _id={bestSelling._id}
                        image={bestSelling.productImage}
                        productName={bestSelling.productName}
                        price={formatNumber(bestSelling.price)}
                        discount={bestSelling.discount}
                        sellerId={bestSelling.sellerId}
                        signInToggle={props.signInToggle}
                      />
                    );
                  })}
                  {bestSellingProducts.length <= 0 && (
                    <div className="container-fluid mt-3 mb-3">
                      <div className="formGeneralSeller p-5">
                        <span
                          style={{
                            color: "#494949",
                            fontSize: "16.5px",
                          }}
                        >
                          {" "}
                          No best selling products found.
                        </span>
                      </div>
                    </div>
                  )}{" "}
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="rootWholeContainer">
            <div className="container-fluid mb-2">
              <span
                className="fw-semibold"
                style={{ fontSize: "18px", color: "rgb(250,103,103)" }}
              >
                Trending
              </span>
            </div>
            <div className="trendingRoot">
              {(error !== "" || isLoading) && (
                <React.Fragment>
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                </React.Fragment>
              )}
              {error === "" && (
                <React.Fragment>
                  {trendingProducts.map((trending) => {
                    return (
                      <ProductRoot
                        key={trending._id}
                        _id={trending._id}
                        image={trending.productImage}
                        productName={trending.productName}
                        price={formatNumber(trending.price)}
                        discount={trending.discount}
                        sellerId={trending.sellerId}
                        signInToggle={props.signInToggle}
                      />
                    );
                  })}
                  {trendingProducts.length <= 0 && (
                    <div className="container-fluid mt-3 mb-3">
                      <div className="formGeneralSeller p-5">
                        <span
                          style={{
                            color: "#494949",
                            fontSize: "16.5px",
                          }}
                        >
                          {" "}
                          No trending products found.
                        </span>
                      </div>
                    </div>
                  )}{" "}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <div
          className="mt-5 d-flex justify-content-center margin-bottom-4p"
          onClick={merchantBannerHandler}
          style={{ marginBottom: "-48px" }}
        >
          <div>
            <img
              src={merchantBanner}
              alt="merchant"
              className="rootMerchantBannerImg"
            />
          </div>
          <div className="rootMerchantBannerText d-flex align-items-center">
            <span className="container-fluid">
              Digitize your business today by becoming a{" "}
              <strong>Swoopkart Seller</strong>.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(Root);
