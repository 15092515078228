import React from "react";
import "./Cart.css";
import CartItem from "./CartItem";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import { useState, useEffect } from "react";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import emptyIcon from "../../icons/empty.png";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";

const Cart = (props) => {
  const navigate = useNavigate();
  const checkoutHandler = () => {
    navigate("/user/checkout");
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "USER_LOAD" });
  }, []);

  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const userDPFromStore = useSelector((state) => state.userData.userDP);
  const userNameFromStore = useSelector((state) => state.userData.userName);
  const userIdFromStore = useSelector((state) => state.userData.userId);

  const [cartProducts, setCartProducts] = useState([]);
  const [cartArray, setCartArray] = useState([]);
  const [whatToShow, setWhatToShow] = useState("one");
  const [error, setError] = useState("");
  const [sellerName, setSellerName] = useState("");
  const [sellerDP, setSellerDP] = useState("");
  const [subtotal, setSubtotal] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const fetchOnLoadHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL + "/cart/",
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "CART_FETCHED") {
        setWhatToShow("one");
        setCartProducts(response.products);
        setCartArray(response.userCart);
        setSubtotal(response.subtotal);
        setSellerName(response.sellerName);
        setSellerDP(response.sellerDP);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const loaction = useLocation();
  useEffect(() => {
    fetchOnLoadHandler();
  }, [loaction]);

  useEffect(() => {
    document.title = userNameFromStore + "'s Cart • Swoopkart";
  }, []);

  const deleteFromCartHandler = async (ProductId) => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL + "/cart/delete-from-cart/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedProductId: ProductId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "DELETED_FROM_CART") {
        setWhatToShow("one");
        setCartProducts(response.products);
        setCartArray(response.userCart);
        setSubtotal(response.subtotal);
        setSellerName(response.sellerName);
        setSellerDP(response.sellerDP);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const moveToWishlistHandler = async (ProductId) => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL + "/cart/move-to-wishlist/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedProductId: ProductId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "MOVED_TO_WISHLIST") {
        setWhatToShow("one");
        setCartProducts(response.products);
        setCartArray(response.userCart);
        setSubtotal(response.subtotal);
        setSellerName(response.sellerName);
        setSellerDP(response.sellerDP);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const quantityChangeHandler = async (ProductId, Quantity) => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL +
          "/cart/update-product-quantity/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedProductId: ProductId,
            recievedQuanitity: Quantity,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "QUANTITY_UPDATED") {
        setWhatToShow("one");
        setCartProducts(response.products);
        setCartArray(response.userCart);
        setSubtotal(response.subtotal);
        setSellerName(response.sellerName);
        setSellerDP(response.sellerDP);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const extractedFirstName = userNameFromStore.split(" ")[0];

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  return (
    <div className="cartRoute">
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <SmallNavBar navBarText={extractedFirstName + "'s Cart"} />
      {whatToShow === "one" && (
        <div>
          {!(cartProducts.length <= 0) && (
            <div className="container-fluid">
              <div className="myCartControls">
                <div className="myCartControls_subtotal container-fluid">
                  <span className="fs-5 d-flex justify-content-start align-items-start">
                    <span>Subtotal</span>
                    <strong
                      style={{
                        marginLeft: "2%",
                        paddingRight: "1px",
                        marginTop: "3px",
                        fontSize: "13px",
                      }}
                    >
                      ₹
                    </strong>
                    <strong className="fs-4 top-0">{formatNumber(subtotal)}</strong>
                    <strong
                      style={{
                        fontSize: "13px",
                        marginTop: "3px",
                        paddingLeft: "1px",
                      }}
                    >
                      00
                    </strong>
                  </span>
                </div>
                <div className="container-fluid myCartControls_CartCheckoutButton_disclaimer_cont">
                  <div>
                    Delivery Charges Applicable according to the seller.
                  </div>
                </div>
                <div className="myCartControls_CartCheckoutButton">
                  <button
                    className="CartCheckoutButton"
                    onClick={checkoutHandler}
                  >
                    <span style={{ fontSize: "16px" }} className="fw-semibold">
                      PROCEED TO BUY
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="MyCartItemsContainer">
            <React.Fragment>
              {!(sellerName === "") && (
                <div
                  className="formGeneralSeller mb-2 fw-semibold"
                  style={{ marginLeft: "3%", marginRight: "3%" }}
                >
                  <div style={{ fontSize: "12px" }}>
                    {userNameFromStore}, you are shopping from
                  </div>
                  <div
                    style={{ color: "rgb(250,103,103)", marginTop: "1.5%" }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <img
                      src={
                        process.env.REACT_APP_SELLER_BACKEND_IMAGE_DP_LOCATION +
                        "/" +
                        sellerDP
                      }
                      alt=""
                      style={{
                        borderRadius: "50%",
                        height: "22px",
                        width: "22px",
                      }}
                    />
                    <span
                      style={{
                        paddingLeft: "2%",
                        fontSize: "17px",
                      }}
                    >
                      {sellerName}
                    </span>
                  </div>
                </div>
              )}
              {cartProducts.map((product) => {
                return (
                  <CartItem
                    key={product._id}
                    _id={product._id}
                    image={product.productImage}
                    productName={product.productName}
                    price={formatNumber(product.price)}
                    mrp={formatNumber(product.MRP)}
                    discount={product.discount}
                    sellerId={product.sellerId}
                    brandName={product.brandName}
                    quantity={
                      cartArray.find((o) => o._id === product._id).quantity
                    }
                    moveToWishlist={moveToWishlistHandler}
                    deleteFromCart={deleteFromCartHandler}
                    updateQuantity={quantityChangeHandler}
                  />
                );
              })}
              {cartProducts.length <= 0 && (
                <div className="container-fluid mt-3 mb-3">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "75vh" }}
                  >
                    <div
                      style={{
                        color: "#494949",
                        fontSize: "20px",
                      }}
                      className="fw-semibold d-flex justify-content-center align-items-center flex-column"
                    >
                      <img src={emptyIcon} alt="" style={{ height: "160px" }} />{" "}
                      <div>Your cart is empty.</div>
                      <div
                        className="fw-semibold"
                        style={{ fontSize: "15px", color: "rgb(250,103,103)" }}
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        Start Shopping.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          </div>
          <div className="p-3"></div>
        </div>
      )}
      {whatToShow === "unableToReachServer" && (
        <div>
          <CoudntFetch />
        </div>
      )}
    </div>
  );
};
export default React.memo(Cart);
