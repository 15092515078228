import "./IndividualProductPage.css";
import React from "react";
const ProductReview = (props) => {
  return (
    <div className="productReview">
      {/* 110 Max words */}
      <div className="d-flex justify-content-start">
        <div className="productReview_name">
          <b>{props.customerName}</b>
        </div>
      </div>
      <div className="productReview_review fs-6">{props.review}</div>
    </div>
  );
};
export default React.memo(ProductReview);
