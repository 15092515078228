import React from "react";

const LoadingAddressItem = () => {
  return (
    <div
      className="AddressForOrder_AddressItem"
      style={{ border: "2px solid rgb(224, 224, 224)" }}
    >
      <div
        className="rbBlock"
        style={{ margin: "3.5% 3% 3.5% 3%", paddingLeft:"18px"}}
      >
       
            <div className="loadingSearchResultProductText2"></div>
            <div className="loadingSearchResultProductText4 mt-1"></div>
            <div className="loadingSearchResultProductText3 mt-1"></div>
            <div className="loadingSearchResultProductText2 mt-1"></div>
            <div className="loadingSearchResultProductText5 mt-1"></div>
            <div className="loadingSearchResultProductText3 mt-1"></div>
      </div>
    </div>
  );
};
export default React.memo(LoadingAddressItem);
