import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CategoriesItem = (props) => {
  const navigate = useNavigate();
  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const goToProductPage = () => {
    if (userTokenFromStore) {
      navigate("/shop/" + props.sellerId + "/product/" + props._id);
    }
    if (!userTokenFromStore) {
      props.signInToggle();
    }
  };

  return (
    <div
      className="col-6"
      style={{ padding: "12px", border: "1px solid rgb(255, 212, 212)" }}
      onClick={goToProductPage}
    >
      <div className="d-flex justify-content-center">
        <img
          src={
            process.env.REACT_APP_USER_BACKEND_PRODUCT_IMAGE_LOCATION +
            "/" +
            props.image
          }
          alt=""
          style={{ height: "100px", width: "100px" }}
        />
      </div>
      <div>
        <span>{props.productName}</span>
      </div>
      <div>
        <span>
          <strong>₹{props.price} </strong>
        </span>
        ({props.discount}% Off)
      </div>
    </div>
  );
};
export default CategoriesItem;
