import "./SearchBox.css";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "../../icons/search.png";
import googleLense from "../../icons/camera.png";

import React from "react";
import { useState } from "react";

const SearchBox = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [enteredText, setEnteredText] = useState("");

  const searchProductsHandler = () => {
    if (!(enteredText.trim() === "")) {
      if (location.pathname.includes("search")) {
        var radio1 = document.getElementById("Relavance");
        radio1.checked = false;
        var radio2 = document.getElementById("Popularity");
        radio2.checked = false;
        var radio3 = document.getElementById("PriceLowToHigh");
        radio3.checked = false;
        var radio4 = document.getElementById("PriceHighToLow");
        radio4.checked = false;
      }

      navigate(
        "/search/" +
          enteredText +
          "?sort=relavance&reviews=0&discount=0&price=10000000"
      );
    }
  };
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const googleLenseUrl = iOS
    ? "https://apps.apple.com/app/id284815942?ct=oo-pmm-web-Lensmktg-Lens&mt=8&pt=9008"
    : "android-app://com.google.ar.lens/";
  return (
    <div className="searchBox shadow-sm d-flex bg-white justify-content-center align-items-center sticky-top">
      <div
        className="row"
        style={{ padding: "0px 7px 0px 8px", width: "100%" }}
      >
        <div
          className="col-11 d-flex align-items-center justify-content-start position-relative"
          style={{ padding: "0px" }}
        >
          <input
            type="text"
            name=""
            id="searchField"
            className="bg-white"
            style={{ width: "100%" }}
            value={enteredText}
            onChange={(event) => setEnteredText(event.target.value)}
          />
          <span
            onClick={searchProductsHandler}
            className="position-absolute end-0"
            style={{ margin: "0px 10px 1px 0px" }}
          >
            <img src={SearchIcon} alt="" className="searchBox_img" />
          </span>
        </div>
        <div className=" col-1 googleLenseContainer d-flex align-items-center justify-content-center">
          <img
            src={googleLense}
            alt=""
            style={{
              height: "20px",
              margin: "0px 0px 0px 8px",
              padding: "0px 0px 0px 0px",
            }}
            onClick={() => {
              const win = window.open(googleLenseUrl, "_blank");
              if (win != null) {
                win.focus();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(SearchBox);

// href="itms-apps://?action=discover&referrer=app-store&itscg=10000&itsct=app-appstore-nav-200917"
