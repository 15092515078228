import "./FilterDrawer.css";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";

const FilterDrawer = (props) => {
  let drawerClasses = "filterDrawer shadow";
  if (props.show === true) {
    drawerClasses = "filterDrawer shadow open";
  } else {
    drawerClasses = "filterDrawer shadow";
  }
  const [isPriceOpen, setIsPriceOpen] = useState(true);
  const [isReviewsOpen, setIsReviewsOpen] = useState(false);
  const [isDiscountsOpen, setIsDiscountsOpen] = useState(false);

  const priceOpenHandler = () => {
    setIsPriceOpen(true);
    setIsReviewsOpen(false);
    setIsDiscountsOpen(false);
  };
  const reviewsOpenHandler = () => {
    setIsPriceOpen(false);
    setIsReviewsOpen(true);
    setIsDiscountsOpen(false);
  };
  const deliveryOpenHandler = () => {
    setIsPriceOpen(false);
    setIsReviewsOpen(false);
    setIsDiscountsOpen(false);
  };
  const discountOpenHandler = () => {
    setIsPriceOpen(false);
    setIsReviewsOpen(false);
    setIsDiscountsOpen(true);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { search } = location;
  const { sort, reviews, discount, price } = queryString.parse(search);

  return (
    <nav className={drawerClasses}>
      <div className="container-fluid">
        <div className="d-flex justify-content-center filterDrawer_Heading">
          <strong>Filter</strong>
        </div>
        <div className="row mt-3 all_filter_container">
          <div className="filter_sidebar col-5">
            <div
              onClick={priceOpenHandler}
              className={`filter_sidebar_item ${
                isPriceOpen
                  ? "filter_sidebar_item_selected"
                  : "filter_sidebar_item_selected_reset"
              }`}
            >
              <strong className="fs-6">Price</strong>
            </div>
            <div
              onClick={reviewsOpenHandler}
              className={`filter_sidebar_item ${
                isReviewsOpen
                  ? "filter_sidebar_item_selected"
                  : "filter_sidebar_item_selected_reset"
              }`}
            >
              <strong className="fs-6">Reviews</strong>
            </div>
            <div
              onClick={discountOpenHandler}
              className={`filter_sidebar_item ${
                isDiscountsOpen
                  ? "filter_sidebar_item_selected"
                  : "filter_sidebar_item_selected_reset"
              }`}
            >
              <strong className="fs-6">Discounts</strong>
            </div>
            <div
              onClick={() => {
                props.filterToggle();
                navigate(
                  "/search/" +
                    params.keyWord +
                    "?sort=relavance&reviews=0&discount=0&price=10000000"
                );
              }}
              className={`filter_sidebar_item_clear`}
            >
              <strong className="fs-6">Clear</strong>
            </div>
          </div>
          <div className="col-6">
            <div className="filterDrawer_CategoryContainer mt-3 fs-6">
              {isPriceOpen && (
                <div className="fs-6">
                  <div
                    className="common_filterElement_Container"
                    onClick={() => {
                      navigate(
                        "/search/" +
                          params.keyWord +
                          "?sort=" +
                          sort +
                          "&reviews=" +
                          reviews +
                          "&discount=" +
                          discount +
                          "&price=500"
                      );
                      props.filterToggle();
                    }}
                  >
                    Under ₹500
                  </div>
                  <div>
                    <div
                      className="common_filterElement_Container"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=" +
                            reviews +
                            "&discount=" +
                            discount +
                            "&price=1000"
                        );
                        props.filterToggle();
                      }}
                    >
                      ₹500 - ₹1,000
                    </div>
                  </div>
                  <div>
                    <div
                      className="common_filterElement_Container"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=" +
                            reviews +
                            "&discount=" +
                            discount +
                            "&price=1500"
                        );
                        props.filterToggle();
                      }}
                    >
                      ₹1,000 - ₹1,500
                    </div>
                  </div>
                  <div>
                    <div
                      className="common_filterElement_Container"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=" +
                            reviews +
                            "&discount=" +
                            discount +
                            "&price=2000"
                        );
                        props.filterToggle();
                      }}
                    >
                      ₹1,500 - ₹2,000
                    </div>
                  </div>
                  <div>
                    <div
                      className="common_filterElement_Container"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=" +
                            reviews +
                            "&discount=" +
                            discount +
                            "&price=2500"
                        );
                        props.filterToggle();
                      }}
                    >
                      ₹2,000 - ₹2,500
                    </div>
                  </div>
                  <div>
                    <div
                      className="common_filterElement_Container"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=" +
                            reviews +
                            "&discount=" +
                            discount +
                            "&price=3000"
                        );
                        props.filterToggle();
                      }}
                    >
                      ₹2,500 - ₹3,000
                    </div>
                  </div>
                  <div>
                    <div
                      className="common_filterElement_Container"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=" +
                            reviews +
                            "&discount=" +
                            discount +
                            "&price=4000"
                        );
                        props.filterToggle();
                      }}
                    >
                      ₹3,000 - ₹4,000
                    </div>
                  </div>
                  <div>
                    <div
                      className="common_filterElement_Container"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=" +
                            reviews +
                            "&discount=" +
                            discount +
                            "&price=5000"
                        );
                        props.filterToggle();
                      }}
                    >
                      ₹4,000 - ₹5,000
                    </div>
                  </div>
                  <div>
                    <div
                      className="common_filterElement_Container"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=" +
                            reviews +
                            "&discount=" +
                            discount +
                            "&price=10000"
                        );
                        props.filterToggle();
                      }}
                    >
                      ₹5,000 - ₹10,000
                    </div>
                  </div>
                </div>
              )}
              {isReviewsOpen && (
                <div className="fs-6">
                  <div>
                    <div
                      className="reviewStarContainer_4"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=4" +
                            "&discount=" +
                            discount +
                            "&price=" +
                            price +
                            ""
                        );
                        props.filterToggle();
                      }}
                    >
                      <span className="fa fa-star checked"></span> {"  "}
                      <span className="fa fa-star checked"></span> {"  "}
                      <span className="fa fa-star checked"></span> {"  "}
                      <span className="fa fa-star checked"></span> {"  "}
                      <span> & up</span>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div
                      className="reviewStarContainer_3"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=3" +
                            "&discount=" +
                            discount +
                            "&price=" +
                            price +
                            ""
                        );
                        props.filterToggle();
                      }}
                    >
                      <span className="fa fa-star checked"></span> {"  "}
                      <span className="fa fa-star checked"></span> {"  "}
                      <span className="fa fa-star checked"></span> {"  "}
                      <span> & up</span>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div
                      className="reviewStarContainer_2"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=2" +
                            "&discount=" +
                            discount +
                            "&price=" +
                            price +
                            ""
                        );
                        props.filterToggle();
                      }}
                    >
                      <span className="fa fa-star checked"></span> {"  "}
                      <span className="fa fa-star checked"></span> {"  "}
                      <span> & up</span>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div
                      className="reviewStarContainer_1"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=1" +
                            "&discount=" +
                            discount +
                            "&price=" +
                            price +
                            ""
                        );
                        props.filterToggle();
                      }}
                    >
                      <span className="fa fa-star checked"></span> {"  "}
                      <span> & up</span>
                    </div>
                  </div>
                </div>
              )}
              {isDiscountsOpen && (
                <div className="fs-6 container-fluid">
                  <div>
                    <div
                      className="common_filterElement_Container"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=" +
                            reviews +
                            "&discount=5&price=" +
                            price +
                            ""
                        );
                        props.filterToggle();
                      }}
                    >
                      5% Off or more
                    </div>
                    <div
                      className="common_filterElement_Container"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=" +
                            reviews +
                            "&discount=10&price=" +
                            price +
                            ""
                        );
                        props.filterToggle();
                      }}
                    >
                      10% Off or more
                    </div>
                  </div>
                  <div>
                    <div
                      className="common_filterElement_Container"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=" +
                            reviews +
                            "&discount=20&price=" +
                            price +
                            ""
                        );
                        props.filterToggle();
                      }}
                    >
                      20% Off or more
                    </div>
                  </div>
                  <div>
                    <div
                      className="common_filterElement_Container"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=" +
                            reviews +
                            "&discount=30&price=" +
                            price +
                            ""
                        );
                        props.filterToggle();
                      }}
                    >
                      30% Off or more
                    </div>
                  </div>
                  <div>
                    <div
                      className="common_filterElement_Container"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=" +
                            reviews +
                            "&discount=40&price=" +
                            price +
                            ""
                        );
                        props.filterToggle();
                      }}
                    >
                      40% Off or more
                    </div>
                  </div>
                  <div>
                    <div
                      className="common_filterElement_Container"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=" +
                            reviews +
                            "&discount=50&price=" +
                            price +
                            ""
                        );
                        props.filterToggle();
                      }}
                    >
                      50% Off or more
                    </div>
                  </div>
                  <div>
                    <div
                      className="common_filterElement_Container"
                      onClick={() => {
                        navigate(
                          "/search/" +
                            params.keyWord +
                            "?sort=" +
                            sort +
                            "&reviews=" +
                            reviews +
                            "&discount=60&price=" +
                            price +
                            ""
                        );
                        props.filterToggle();
                      }}
                    >
                      60% Off or more
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default React.memo(FilterDrawer);
