import React, { useEffect } from "react";
import "./SideDrawer.css";
import greaterThanIcon from "../../icons/greater-than.png";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const SideDrawer = (props) => {
  let drawerClasses = "sideDrawer shadow";
  if (props.show) {
    drawerClasses = "sideDrawer shadow open";
  } else {
    drawerClasses = "sideDrawer shadow";
  }
  // const classesFun = (navData) => {
  //   return navData.isActive ? "sidebarLinkActive" : "sidebarLinkNotActive";
  // };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "USER_LOAD" });
  }, []);
  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const userDPFromStore = useSelector((state) => state.userData.userDP);
  const userNameFromStore = useSelector((state) => state.userData.userName);
  const userIdFromStore = useSelector((state) => state.userData.userId);

  const navigate = useNavigate();
  const userAccountNavigateHandler = () => {
    props.close();
    navigate("/user");
  };
  const signupNavigateHandler = () => {
    props.close();
    navigate("/signup");
  };
  const signinNavigateHandler = () => {
    props.close();
    navigate("/");
    props.signInOpen();
  };

  let nameToBeDisplayed = "";
  if (userNameFromStore) {
    const userFirstName = userNameFromStore.split(" ")[0];
    const userLastName = userNameFromStore.split(" ")[1];
    if ((userFirstName + " " + userLastName).length > 21) {
      nameToBeDisplayed = userFirstName + " " + userLastName[0];
    } else {
      nameToBeDisplayed = userFirstName + " " + userLastName;
    }
  } else {
    nameToBeDisplayed = " ";
  }

  return (
    <nav className={drawerClasses}>
      {userTokenFromStore && (
        <div className="headerSideDrawer d-flex justify-content-between">
          <span
            className="headerSideDrawer_username_color"
            onClick={userAccountNavigateHandler}
            style={{ marginTop: "3px", fontSize: "19px" }}
          >
            <strong>{nameToBeDisplayed}</strong>
          </span>
          <img
            src={
              process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
              userDPFromStore
            }
            alt="dp"
            onClick={userAccountNavigateHandler}
            className="accountHolderIconSideDrawer"
          />
        </div>
      )}
      {!userTokenFromStore && (
        <React.Fragment>
          <div className="headerSideDrawer d-flex justify-content-start">
            <span
              className="fs-6 headerSideDrawer_username_color"
              onClick={signinNavigateHandler}
            >
              <strong>Sign in</strong>
            </span>
          </div>
          <div className="headerSideDrawer_notlogged d-flex justify-content-start">
            <span
              className="fs-6 headerSideDrawer_username_color"
              onClick={signupNavigateHandler}
            >
              <strong>Sign up</strong>
            </span>
          </div>
        </React.Fragment>
      )}
      <div className="categoryContainerSideDrawer">
        <div className="categorySideDrawer">
          <span>
            <strong>Most used</strong>
          </span>
        </div>
        <div className="container-fluid">
          <div
            className="linkSideDrawer d-flex justify-content-between"
            onClick={() => {
              if (userTokenFromStore) {
                props.close();
                navigate("/user");
              }
              if (!userTokenFromStore) {
                props.close();
                navigate("/");
                props.signInOpen();
              }
            }}
          >
            <span className="">My Account</span>
            <img src={greaterThanIcon} alt="" style={{ height: "18px" }} />
          </div>
          <div
            className="linkSideDrawer d-flex justify-content-between"
            onClick={() => {
              if (userTokenFromStore) {
                props.close();
                navigate("/user/orderhistory");
              }
              if (!userTokenFromStore) {
                props.close();
                navigate("/");
                props.signInOpen();
              }
            }}
          >
            <span className="">Order History</span>
            <img src={greaterThanIcon} alt="" style={{ height: "18px" }} />
          </div>
          <div
            className="linkSideDrawer d-flex justify-content-between"
            onClick={() => {
              if (userTokenFromStore) {
                props.close();
                navigate("/user/cart");
              }
              if (!userTokenFromStore) {
                props.close();
                navigate("/");
                props.signInOpen();
              }
            }}
          >
            <span className="">Cart</span>
            <img src={greaterThanIcon} alt="" style={{ height: "18px" }} />
          </div>
          <div
            className="linkSideDrawer d-flex justify-content-between"
            onClick={() => {
              if (userTokenFromStore) {
                props.close();
                navigate("/user/wishlist");
              }
              if (!userTokenFromStore) {
                props.close();
                navigate("/");
                props.signInOpen();
              }
            }}
          >
            <span className="">Wish List</span>
            <img src={greaterThanIcon} alt="" style={{ height: "18px" }} />
          </div>
        </div>
      </div>
      <div className="categoryContainerSideDrawer">
        <div className="categorySideDrawer">
          <span>
            <strong>Top Categories For You</strong>
          </span>
        </div>
        <div className="container-fluid">
          <Link
            className="linkSideDrawer d-flex justify-content-between"
            to="/categories/Gadgets"
            onClick={props.close}
          >
            <span className="">Gadgets</span>
            <img src={greaterThanIcon} alt="" style={{ height: "18px" }} />
          </Link>
          <Link
            className="linkSideDrawer d-flex justify-content-between"
            to="/categories/Footwear"
            onClick={props.close}
          >
            <span className="">Footwear</span>
            <img src={greaterThanIcon} alt="" style={{ height: "18px" }} />
          </Link>
          <Link
            className="linkSideDrawer d-flex justify-content-between"
            to="/categories/MensFashion"
            onClick={props.close}
          >
            <span className="">Men's Fashion</span>
            <img src={greaterThanIcon} alt="" style={{ height: "18px" }} />
          </Link>
          <Link
            className="linkSideDrawer d-flex justify-content-between"
            to="/categories/WomensFashion"
            onClick={props.close}
          >
            <span className="">Women's Fashion</span>
            <img src={greaterThanIcon} alt="" style={{ height: "18px" }} />
          </Link>
          <Link
            className="linkSideDrawer d-flex justify-content-between"
            to="/categories/Kids"
            onClick={props.close}
          >
            <span className="">Kids</span>
            <img src={greaterThanIcon} alt="" style={{ height: "18px" }} />
          </Link>
          <Link
            className="linkSideDrawer d-flex justify-content-between"
            to="/categories/Groceries"
            onClick={props.close}
          >
            <span className="">Groceries</span>
            <img src={greaterThanIcon} alt="" style={{ height: "18px" }} />
          </Link>
          <Link
            className="linkSideDrawer d-flex justify-content-between"
            to="/categories/Appliances"
            onClick={props.close}
          >
            <span className="">Appliances</span>
            <img src={greaterThanIcon} alt="" style={{ height: "18px" }} />
          </Link>
          <Link
            className="linkSideDrawer d-flex justify-content-between"
            to="/categories/Gifting"
            onClick={props.close}
          >
            <span className="">Gifting</span>
            <img src={greaterThanIcon} alt="" style={{ height: "18px" }} />
          </Link>
        </div>
      </div>
      <div className="categoryContainerSideDrawer">
        <div className="categorySideDrawer">
          <span>
            <strong>More From Swoopkart</strong>
          </span>
        </div>
        <div className="container-fluid">
          <Link
            className="linkSideDrawer d-flex justify-content-between"
            onClick={() => {
              const win = window.open("https://sell.swoopkart.varunbukka.in/", "_blank");
              if (win != null) {
                win.focus();
              }
              props.close();
            }}
          >
            <span className="">Sell at Swoopkart</span>
            <img src={greaterThanIcon} alt="" style={{ height: "18px" }} />
          </Link>
          <Link
            className="linkSideDrawer d-flex justify-content-between"
            to="/forgotpassword"
            onClick={props.close}
          >
            <span className="">Forgot Password</span>
            <img src={greaterThanIcon} alt="" style={{ height: "18px" }} />
          </Link>
          <Link
            className="linkSideDrawer d-flex justify-content-between"
            to="/support"
            onClick={props.close}
          >
            <span className="">Support</span>
            <img src={greaterThanIcon} alt="" style={{ height: "18px" }} />
          </Link>
        </div>
      </div>
      <div style={{ height: "35px" }}></div>
    </nav>
  );
};
export default SideDrawer;
