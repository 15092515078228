import React from "react";

const LoadingSearchResultProduct = () => {
  return (
    <div className="searchResultProduct d-flex justify-content-start">
      <div className="container-fluid ">
        <div className="row">
          <div className="col-4">
            <div className="loadingSearchResultProductImage"></div>
          </div>
          <div className="col-8 productDetails d-flex flex-column justify-content-start align-items-start">
            <div className="loadingSearchResultProductText1"></div>
            <div className="loadingSearchResultProductText2 mt-1"></div>
            <div className="loadingSearchResultProductText3 mt-1"></div>
            <div className="loadingSearchResultProductText4 mt-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(LoadingSearchResultProduct);
