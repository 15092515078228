import "./SmallNavBar.css";
import backIcon from "../../icons/back.png";
import { useNavigate } from "react-router-dom";
import swoopkartIcon from "../../icons/icon-white.png";
import React from "react";
const SmallNavBar = (props) => {
  const navigate = useNavigate();
  const backHandler = () => {
    navigate(-1);
  };
  const rootHandler = () => {
    navigate("/");
  };
  return (
    <div className="d-flex justify-content-between smallNavBar_container sticky-top shadow-sm">
      <div className="d-flex justify-content-center align-items-center">
        <div style={{ marginTop: "-1.5px" }}>
          <img src={backIcon} alt="back" onClick={backHandler} />
        </div>
        <div className="smallNavBar_text">
          <strong>{props.navBarText}</strong>
        </div>
      </div>
      <div className="smallNavBar_logo">
        <img
          src={swoopkartIcon}
          alt=""
          style={{ height: "30px" }}
          onClick={rootHandler}
        />
      </div>
    </div>
  );
};

export default React.memo(SmallNavBar);
