import React from "react";

const CouponCodeItem = (props) => {
  return (
    <div
      className="AddressForOrder_AddressItem"
      style={{ padding: "3%", margin: "1% 3% 4% 3%" }}
      onClick={() => props.onClick(props._id)}
    >
      <div style={{ fontSize: "14px" }}>
        <strong>{props._id}</strong>
      </div>
      <div style={{ fontSize: "13px" }}>
        Flat {props.discountPercentage}% Discount
      </div>
    </div>
  );
};

export default React.memo(CouponCodeItem);
