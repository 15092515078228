import React from "react";
import { useState } from "react";
import deleteIcon from "../../icons/controls/delete.png";
import saveForLaterIcon from "../../icons/controls/saveForLaterOutlined.png";
import { useNavigate } from "react-router-dom";
import "./CartItem.css";
const CartItem = (props) => {
  const navigate = useNavigate();
  const openFullProductPageHandler = () => {
    navigate("/shop/" + props.sellerId + "/product/" + props._id);
  };

  const [selectedQuantity, setSelectedQuantity] = useState(props.quantity);

  return (
    <div className="cartItem">
      <div className=" container-fluid">
        <div className="cartResultProduct d-flex justify-content-start row">
          <div className="col-4 d-flex justify-content-center align-items-center">
            <img
              src={
                process.env.REACT_APP_USER_BACKEND_PRODUCT_IMAGE_LOCATION +
                "/" +
                props.image
              }
              alt="product"
              style={{ height: "95px", width: "95px" }}
              className="productImage"
              onClick={openFullProductPageHandler}
            />
          </div>
          <div
            className="col-8 productDetails"
            onClick={openFullProductPageHandler}
          >
            {/* 30 Words */}
            <span className="productTitle fs-6">
              <strong>{props.productName}</strong>
            </span>
            <span className="productPrice fw-bold fs-6">₹{props.price}</span>
            <div className="fs-6 searchResultProduct_discount">
              <strike>₹{props.mrp}</strike>
              <span>
                {"  "}({props.discount}% off)
              </span>
            </div>
            {/* 20 Words */}
            <span className="productBrand">{props.brandName}</span>
          </div>
          <div className="container-fluid">
            <div className="cartItemControls row d-flex justify-content-start ">
              <div className="d-flex justify-content-start flex-row align-items-center">
                <span
                  className="fw-semibold"
                  style={{ paddingRight: "5px", fontSize: "14px" }}
                >
                  Quantity :{" "}
                </span>
                <div
                  className="d-flex justify-content-start"
                  style={{ width: "65px" }}
                >
                  <select
                    className="form-select form-select-sm d-flex justify-content-start align-items-center fw-semibold"
                    aria-label="Choose Quantity"
                    style={{ height: "28px", fontSize: "13px" }}
                    value={selectedQuantity}
                    onChange={(event) => {
                      setSelectedQuantity(event.target.value);
                      props.updateQuantity(props._id, event.target.value);
                    }}
                  >
                    <option defaultValue value="1">
                      1
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
                <button
                  style={{ color: "rgb(250, 103, 103)" }}
                  className="cartItemControls_image wishlistItemControlButton"
                  onClick={() => {
                    props.deleteFromCart(props._id);
                  }}
                >
                  <img
                    src={deleteIcon}
                    alt="Delete"
                    style={{ height: "19px" }}
                  />
                </button>
                <button
                  style={{ color: "rgb(250, 103, 103)" }}
                  className="cartItemControls_image wishlistItemControlButton"
                  onClick={() => {
                    props.moveToWishlist(props._id);
                  }}
                >
                  <img
                    src={saveForLaterIcon}
                    alt="Save for Later"
                    style={{ height: "18px" }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(CartItem);
