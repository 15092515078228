import "./IndividualProductPage.css";
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import ProductRoot from "../root/ProductRoot";
import ProductReview from "./ProductReview";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import Footer from "../../UI/Footer/Footer";
import LogoNavBar from "../../UI/LogoNavBar";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import { useDispatch, useSelector } from "react-redux";
import wishlistIconFilled from "../../icons/controls/saveForLater.png";
import wishlistIconOutlined from "../../icons/controls/saveForLaterOutlined.png";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import greenTickIcon from "../../icons/greenTick.png";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";
import upArrow from "../../icons/up.png";
import downArrow from "../../icons/down.png";

const IndividualProductPage = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "USER_LOAD" });
  }, []);

  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const userDPFromStore = useSelector((state) => state.userData.userDP);
  const userNameFromStore = useSelector((state) => state.userData.userName);
  const userIdFromStore = useSelector((state) => state.userData.userId);

  const [starOneClass, setStarOneClass] = useState("fa-star-unchecked");
  const [starTwoClass, setStarTwoClass] = useState("fa-star-unchecked");
  const [starThreeClass, setStarThreeClass] = useState("fa-star-unchecked");
  const [starFourClass, setStarFourClass] = useState("fa-star-unchecked");
  const [starFiveClass, setStarFiveClass] = useState("fa-star-unchecked");
  const [isWriteReviewClicked, serIsWriteReviewClicked] = useState(false);

  const [thankyouForReviewing, setThankyouForReviewing] = useState(false);

  const starOneClickHandler = () => {
    if (!thankyouForReviewing) {
      sendRating(1);
      setThankyouForReviewing(true);
      setStarOneClass("checked");
      setStarTwoClass("fa-star-unchecked");
      setStarThreeClass("fa-star-unchecked");
      setStarFourClass("fa-star-unchecked");
      setStarFiveClass("fa-star-unchecked");
    }
  };
  const starTwoClickHandler = () => {
    if (!thankyouForReviewing) {
      sendRating(2);
      setThankyouForReviewing(true);
      setStarOneClass("checked");
      setStarTwoClass("checked");
      setStarThreeClass("fa-star-unchecked");
      setStarFourClass("fa-star-unchecked");
      setStarFiveClass("fa-star-unchecked");
    }
  };
  const starThreeClickHandler = () => {
    if (!thankyouForReviewing) {
      sendRating(3);
      setThankyouForReviewing(true);
      setStarOneClass("checked");
      setStarTwoClass("checked");
      setStarThreeClass("checked");
      setStarFourClass("fa-star-unchecked");
      setStarFiveClass("fa-star-unchecked");
    }
  };
  const starFourClickHandler = () => {
    if (!thankyouForReviewing) {
      sendRating(4);
      setThankyouForReviewing(true);
      setStarOneClass("checked");
      setStarTwoClass("checked");
      setStarThreeClass("checked");
      setStarFourClass("checked");
      setStarFiveClass("fa-star-unchecked");
    }
  };
  const starFiveClickHandler = () => {
    if (!thankyouForReviewing) {
      sendRating(5);
      setThankyouForReviewing(true);
      setStarOneClass("checked");
      setStarTwoClass("checked");
      setStarThreeClass("checked");
      setStarFourClass("checked");
      setStarFiveClass("checked");
    }
  };

  const openWriteReviewClickHandler = () => {
    serIsWriteReviewClicked(true);
  };
  const cancelWriteReviewClickHandler = () => {
    serIsWriteReviewClicked(false);
    setEnteredReview("");
    setReviewError("");
  };

  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [calculatedRating, setCalculatedRating] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForRating, setIsLoadingForRating] = useState(false);
  const [recomendedProducts, setRecomendedProducts] = useState([]);
  const [recievedDeliveryInfo, setRecievedDeliveryInfo] = useState({
    deliveryOptionName: "",
    onlyOrderedBefore: "",
    waitTime: "",
  });
  const [dataRecieved, setDataRecieved] = useState({
    productImage: "none.png",
    rating: { totalSum: 0, noOfPeople: 0 },
    _id: "",
    productName: "",
    brandName: "",
    MRP: 0,
    price: 0,
    discount: 0,
    category: "",
    description: "",
    stockAvailable: 0,
    views: 0,
    soldUnits: 0,
    archived: false,
    soldBy: "",
    sellerId: "",
  });
  const [reviewsRecieved, setReviewsRecieved] = useState([]);
  const [whatToShow, setWhatToShow] = useState("one");
  const [isProductWishlisted, setIsProductWishlisted] = useState(false);
  const [isProductInCart, setIsProductInCart] = useState(false);
  const [isDomesticSeller, setIsDomesticSeller] = useState(false);
  const [isSellingRegionsOpened, setIsSellingRegionsOpened] = useState(false);

  const sellingRegionsToggleClickHandler = () => {
    setIsSellingRegionsOpened((prev) => !prev);
  };

  const [sellerSellingRegions, setSellerSellingRegions] = useState([]);
  const [
    isSellerAvailableInUsersLocation,
    setIsSellerAvailableInUsersLocation,
  ] = useState(false);

  const fetchLoadHandler = async () => {
    try {
      setIsLoading(true);
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL + "/individual-product/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedSellerId: params.sellerId,
            recievedProductId: params.productId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "PRODUCT_FOUND") {
        setIsDomesticSeller(response.isDomesticSeller);
        setIsSellerAvailableInUsersLocation(
          response.isSellerAvailableInUsersLocation
        );
        setSellerSellingRegions(response.sellerSellingRegions);
        setDataRecieved(response.product);
        setReviewsRecieved(response.product.reviews);
        setRecomendedProducts(response.recomendedProducts);
        setRecievedDeliveryInfo(response.deliveryInfo);
        setWhatToShow("one");
        setIsProductInCart(response.inCartOrNot);
        document.title =
          response.product.productName +
          " from " +
          response.product.soldBy +
          " • Sell at Swoopkart";

        setThankyouForReviewing(false);
        setSelectedQuantity(1);
        setStarOneClass("fa-star-unchecked");
        setStarTwoClass("fa-star-unchecked");
        setStarThreeClass("fa-star-unchecked");
        setStarFourClass("fa-star-unchecked");
        setStarFiveClass("fa-star-unchecked");
        setIsProductWishlisted(response.wishlistedOrNot);
        cancelWriteReviewClickHandler();
        setCoudntAddToCart(false);
      }
      if (response.status === "PRODUCT_NOT_FOUND") {
        setWhatToShow("notfound");
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const [enteredReview, setEnteredReview] = useState("");
  const [reviewError, setReviewError] = useState("");

  // serIsWriteReviewClicked(false);

  const submitWriteReviewClickHandler = async () => {
    if (enteredReview.trim() !== "" && enteredReview.trim().length <= 120) {
      try {
        setIsLoading(true);
        const requestSent = await fetch(
          process.env.REACT_APP_USER_BACKEND_URL +
            "/individual-product/product-review/",
          {
            method: "POST",
            body: JSON.stringify({
              recievedSellerId: params.sellerId,
              recievedProductId: params.productId,
              recievedUsername: userNameFromStore,
              enteredReview: enteredReview,
            }),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + userTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setReviewError("There was a server error.");
        }
        if (response.status === "REVIEW_SUBMITED") {
          cancelWriteReviewClickHandler();
          fetchLoadHandler();
          setReviewError("");
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "USER_LOGGING_OUT" });
          navigate("/user-not-authenticated");
        }
        if (response.status === "LENGTHY_REVIEW") {
          cancelWriteReviewClickHandler();
          fetchLoadHandler();
          setReviewError(
            "Enter a valid review that is less than 120 characters."
          );
        }
        if (!response.status) {
          setReviewError("There was a server error.");
        }
        setIsLoading(false);
      } catch (error) {
        setReviewError("Unable to reach the server. Try again later.");
        setIsLoading(false);
      }
    } else {
      setReviewError("Enter a valid review that is less than 120 characters.");
    }
  };

  const sendRating = async (rating) => {
    try {
      setIsLoadingForRating(true);
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL +
          "/individual-product/product-rating/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedSellerId: params.sellerId,
            recievedProductId: params.productId,
            enteredRating: rating,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "RATING_SUBMITED") {
        setWhatToShow("one");
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      setIsLoadingForRating(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoadingForRating(false);
    }
  };

  const loaction = useLocation();
  useEffect(() => {
    fetchLoadHandler();
  }, [loaction]);

  useEffect(() => {
    setCalculatedRating(
      Math.round(dataRecieved.rating.totalSum / dataRecieved.rating.noOfPeople)
    );
  }, [dataRecieved]);

  const addToWishListHandler = async (toWishlistOrNot) => {
    try {
      setIsLoading(true);
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL +
          "/individual-product/wishlist-operations/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedSellerId: params.sellerId,
            recievedProductId: params.productId,
            toWishlistOrNot: toWishlistOrNot,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "WISHLISTED") {
        setWhatToShow("one");
        setIsProductWishlisted(response.wishlistedOrNot);
      }
      if (response.status === "REMOVED_FROM_WISHLIST") {
        setWhatToShow("one");
        setIsProductWishlisted(response.wishlistedOrNot);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const [coudntAddToCart, setCoudntAddToCart] = useState(false);

  const addToCartHandler = async () => {
    try {
      setIsLoading(true);
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL +
          "/individual-product/add-to-cart/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedSellerId: params.sellerId,
            recievedProductId: params.productId,
            recievedQuanitity: selectedQuantity,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "ADDED_TO_CART") {
        setWhatToShow("one");
        setIsProductInCart(response.inCartOrNot);
      }
      if (response.status === "COUDNT_ADD_TO_CART") {
        setWhatToShow("one");
        setIsProductInCart(response.inCartOrNot);
        setCoudntAddToCart(true);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const removeAllAndAddToCartHandler = async () => {
    try {
      setIsLoading(true);
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL +
          "/individual-product/remove-all-and-add-to-cart/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedSellerId: params.sellerId,
            recievedProductId: params.productId,
            recievedQuanitity: selectedQuantity,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "ADDED_TO_CART") {
        setWhatToShow("one");
        setIsProductInCart(response.inCartOrNot);
        setCoudntAddToCart(false);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      {whatToShow === "one" && (
        <React.Fragment>
          <SmallNavBar
            navBarText={`${
              dataRecieved.productName.length > 19
                ? dataRecieved.productName.slice(0, 19) + "..."
                : dataRecieved.productName
            }`}
          />
          <div className="ProductPage bg-white">
            <div className="container-fluid mb-4">
              <div className="ProductPage_Rating d-flex justify-content-end">
                <div className="productRating">
                  <span
                    className={`fa fa-star ${
                      calculatedRating >= 1 && "checked"
                    }`}
                  ></span>{" "}
                  <span
                    className={`fa fa-star ${
                      calculatedRating >= 2 && "checked"
                    }`}
                  ></span>{" "}
                  <span
                    className={`fa fa-star ${
                      calculatedRating >= 3 && "checked"
                    }`}
                  ></span>{" "}
                  <span
                    className={`fa fa-star ${
                      calculatedRating >= 4 && "checked"
                    }`}
                  ></span>{" "}
                  <span
                    className={`fa fa-star ${
                      calculatedRating >= 5 && "checked"
                    }`}
                  ></span>
                  {"  "}
                  <span className="noOfRatings">{`(${dataRecieved.rating.noOfPeople})`}</span>
                </div>
              </div>
            </div>
            <div className="ProductPage_ImageContainer d-flex justify-content-center">
              <img
                src={
                  process.env.REACT_APP_USER_BACKEND_PRODUCT_IMAGE_LOCATION +
                  "/" +
                  dataRecieved.productImage
                }
                alt="product"
              />
            </div>
            <div className="container-fluid mt-4">
              <div className="container-fluid">
                <div className="ProductPage_Pricing">
                  <div className="fs-1">
                    <span className="ProductPage_Pricing_Discount">
                      {" "}
                      {dataRecieved.discount}% off
                    </span>{" "}
                    <strong>₹{formatNumber(dataRecieved.price)}</strong>
                  </div>
                  <div className="ProductPage_Pricing_MRP fs-6">
                    M.R.P ₹{formatNumber(dataRecieved.MRP)}
                  </div>
                </div>
                <div className="ProductPage_TitleAndBrand fs-5">
                  <strong>{dataRecieved.productName}</strong>
                  <div className="fs-6">by {dataRecieved.brandName}</div>
                </div>
              </div>
            </div>
            <div className="ProductPage_Delivery mb-4">
              <div className="container-fluid">
                {dataRecieved.stockAvailable > 5 && (
                  <div
                    className="ProductPage_Controls_Stock fw-semibold"
                    style={{ fontSize: "16px" }}
                  >
                    In Stock
                  </div>
                )}
                {dataRecieved.stockAvailable < 5 && (
                  <div
                    className="ProductPage_Controls_Stock fw-semibold"
                    style={{ fontSize: "16px", color: "red" }}
                  >
                    Might Not Be In Stock
                  </div>
                )}
              </div>
              <div className="pb-2">
                <div className="container-fluid" style={{ fontSize: "18px" }}>
                  Sold by{" "}
                  <Link
                    className="ProductPage_VendorDeltils_Link"
                    to={`/shop/${dataRecieved.sellerId}`}
                  >
                    <b>{dataRecieved.soldBy}</b>
                  </Link>
                </div>
              </div>
              {(isSellerAvailableInUsersLocation || isDomesticSeller) && (
                <div>
                  <div className="container-fluid mb-4">
                    <div className="ProductPage_Delivery_Container">
                      <div
                        className="fw-semibold"
                        style={{
                          fontSize: "19px",
                          color: "rgb(250,103,103)",
                        }}
                      >
                        Delivered in
                      </div>
                      {recievedDeliveryInfo && (
                        <div>
                          <div>
                            <b style={{ fontSize: "18px" }}>
                              {recievedDeliveryInfo.waitTime} - (
                              {recievedDeliveryInfo.deliveryOptionName})
                            </b>
                          </div>
                          {recievedDeliveryInfo.onlyOrderedBefore !== "NA" && (
                            <div>
                              Only if ordered before{" "}
                              {recievedDeliveryInfo.onlyOrderedBefore}
                            </div>
                          )}
                          <div>
                            <i>
                              Note : Nominal delivery charges are applicable on
                              this order.
                            </i>
                          </div>
                        </div>
                      )}
                      {!recievedDeliveryInfo && (
                        <div
                          className="fw-semibold"
                          style={{ fontSize: "16px" }}
                        >
                          Sorry, Delivery for this product is temporarily
                          unavailable.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {!isSellerAvailableInUsersLocation && !isDomesticSeller && (
                <div>
                  <div
                    className="container-fluid"
                    style={{ marginBottom: "-5%" }}
                  >
                    <div className="ProductPage_Delivery_Container">
                      <div
                        className="fw-semibold"
                        style={{ fontSize: "19px", color: "rgb(250,103,103)" }}
                      >
                        Delivery Unavailable
                      </div>

                      <div style={{ fontSize: "16px" }} className="fw-semibol">
                        Sorry, Delivery for this product is <b>not available</b>{" "}
                        in your selected location. Check the selling regions
                        section to see all available locations.
                      </div>
                      <div
                        style={{ fontSize: "16px" }}
                        className="fw-bold"
                        onClick={() => {
                          props.openAddressSelector();
                          navigate("/");
                        }}
                      >
                        Change your location here.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {(isSellerAvailableInUsersLocation || isDomesticSeller) && (
              <div className="ProductPage_Controls pt-1">
                {userTokenFromStore && (
                  <div className="container-fluid">
                    <div
                      style={{ marginBottom: "-8%" }}
                      className="d-flex justify-content-start flex-row align-items-center"
                    >
                      <span
                        className="fw-semibold"
                        style={{ paddingRight: "5px", fontSize: "18px" }}
                      >
                        Quantity :{" "}
                      </span>
                      <div className="col-2 d-flex justify-content-start">
                        <select
                          className="form-select form-select-sm fw-semibold"
                          aria-label="Choose Quantity"
                          value={selectedQuantity}
                          onChange={(event) => {
                            setSelectedQuantity(event.target.value);
                          }}
                        >
                          <option defaultValue value="1">
                            1
                          </option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="nextButton col-9"
                        style={{ paddingRight: "7px", fontSize: "6px" }}
                      >
                        <button
                          className="nextButtonCustom"
                          disabled={!recievedDeliveryInfo || isProductInCart}
                          onClick={addToCartHandler}
                        >
                          {!isProductInCart && (
                            <span
                              style={{ fontSize: "16px" }}
                              className="fw-semibold"
                            >
                              ADD TO CART
                            </span>
                          )}
                          {isProductInCart && (
                            <span className="d-flex justify-content-center align-items-center">
                              <img
                                src={greenTickIcon}
                                alt=""
                                style={{
                                  height: "18px",
                                  paddingRight: "2.3%",
                                }}
                              />
                              <span
                                style={{ fontSize: "16px" }}
                                className="fw-semibold"
                              >
                                ADDED TO CART
                              </span>
                            </span>
                          )}
                        </button>
                      </div>
                      <div
                        className="nextButton col-3"
                        style={{ paddingLeft: "7px" }}
                      >
                        <button
                          style={{
                            backgroundColor: "white",
                            border: "1px solid rgb(250,103,103)",
                          }}
                          onClick={() => {
                            addToWishListHandler(!isProductWishlisted);
                          }}
                          disabled={!recievedDeliveryInfo}
                          className="nextButtonCustom d-flex justify-content-center align-items-center"
                        >
                          {isProductWishlisted && (
                            <img
                              src={wishlistIconFilled}
                              style={{ height: "29px" }}
                              alt=""
                            />
                          )}
                          {!isProductWishlisted && (
                            <img
                              src={wishlistIconOutlined}
                              style={{ height: "29px" }}
                              alt=""
                            />
                          )}
                        </button>
                      </div>
                    </div>
                    {coudntAddToCart && (
                      <div className="mt-3">
                        <div className="formErrorSeller">
                          <div>
                            Cannot add this product to cart. As you have
                            products from other store.
                          </div>
                          <div className="row d-flex justify-content-end mt-3">
                            <div
                              className="col-4"
                              style={{ paddingRight: "6px" }}
                            >
                              <button
                                style={{
                                  height: "33px",
                                  width: "100%",
                                  backgroundColor: "white",
                                  border: "1px solid rgb(250,103,103)",
                                  color: "rgb(250,103,103)",
                                  borderRadius: "5px",
                                  padding: "2.5%",
                                }}
                                onClick={() => setCoudntAddToCart(false)}
                              >
                                Okay
                              </button>
                            </div>
                            <div
                              className="col-8"
                              style={{ paddingLeft: "6px" }}
                            >
                              <button
                                style={{
                                  height: "33px",
                                  width: "100%",
                                  backgroundColor: "rgb(250,103,103)",
                                  border: "none",
                                  color: "white",
                                  borderRadius: "5px",
                                  padding: "2.5%",
                                }}
                                onClick={removeAllAndAddToCartHandler}
                              >
                                Remove and add this
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            <div className="ProductPage_Description mt-5">
              <div className="container-fluid">
                <div
                  className="fw-semibold mb-1"
                  style={{ fontSize: "19px", color: "rgb(250,103,103)" }}
                >
                  Description
                </div>
                <div className="fs-6">{dataRecieved.description}</div>
              </div>
            </div>
            <div className="container-fluid" style={{ marginTop: "8%" }}>
              <div
                className="fw-semibold mb-1  d-flex justify-content-start align-items-center"
                style={{ fontSize: "19px", color: "rgb(250,103,103)" }}
                onClick={sellingRegionsToggleClickHandler}
              >
                <span style={{ marginRight: "5%" }}>Selling Regions</span>
                {!isSellingRegionsOpened && (
                  <img src={downArrow} alt="" style={{ height: "16px" }} />
                )}
                {isSellingRegionsOpened && (
                  <img src={upArrow} alt="" style={{ height: "16px" }} />
                )}
              </div>
              {isSellingRegionsOpened && (
                <div
                  className="AddressForOrder_AddressItem"
                  style={{ marginTop: "10px" }}
                >
                  <div
                    className="rbBlock d-flex justify-content-start align-items-center"
                    style={{ padding: "3%", margin: "0" }}
                  >
                    <div style={{ fontSize: "15px" }}>
                      <div className="container-fluid">
                        {isDomesticSeller && (
                          <div
                            className=""
                            style={{ color: "rgb(250,103,103)" }}
                          >
                            <strong>
                              <strong style={{ marginRight: "7px" }}>•</strong>
                              Domestic
                            </strong>{" "}
                            -<span>{"  "}Whole India</span>
                          </div>
                        )}
                        {sellerSellingRegions.map((region) => {
                          return (
                            <div className="" key={region._id}>
                              <strong>
                                <strong style={{ marginRight: "7px" }}>
                                  •
                                </strong>
                                {region.pincode}
                              </strong>{" "}
                              -
                              <span>
                                {"  "}
                                {region.division}, {region.district}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="ProductPage_Reviews">
              <div className="container-fluid">
                <div
                  className="fw-semibold mb-1"
                  style={{ fontSize: "19px", color: "rgb(250,103,103)" }}
                >
                  Reviews
                </div>
              </div>
              <div className="reviewsContainer">
                <React.Fragment>
                  {reviewsRecieved.map((review) => {
                    return (
                      <ProductReview
                        key={review._id}
                        _id={review._id}
                        customerName={review.customerName}
                        review={review.review}
                      />
                    );
                  })}
                  {reviewsRecieved.length <= 0 && (
                    <div className="container-fluid mt-3 mb-3">
                      <div
                        className="formGeneralSeller p-5"
                        style={{ border: "none" }}
                      >
                        <span
                          style={{
                            color: "#494949",
                            fontSize: "16.5px",
                          }}
                        >
                          {" "}
                          Be the first person to review this product.
                        </span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              </div>
            </div>
            <div className="ProductPage_RelatedProducts">
              <div className="container-fluid">
                <div
                  className="fw-semibold mb-1"
                  style={{ fontSize: "19px", color: "rgb(250,103,103)" }}
                >
                  Today's Recomendations
                </div>
              </div>
              <div className="relatedProductsContainer">
                <React.Fragment>
                  {recomendedProducts.map((recomendations) => {
                    return (
                      <ProductRoot
                        onClick={fetchLoadHandler}
                        key={recomendations._id}
                        _id={recomendations._id}
                        image={recomendations.productImage}
                        productName={recomendations.productName}
                        price={formatNumber(recomendations.price)}
                        discount={recomendations.discount}
                        sellerId={recomendations.sellerId}
                        signInToggle={props.signInToggle}
                      />
                    );
                  })}
                  {recomendedProducts.length <= 0 && (
                    <div className="container-fluid mt-3 mb-3">
                      <div className="formGeneralSeller p-5">
                        <span
                          style={{
                            color: "#494949",
                            fontSize: "16.5px",
                          }}
                        >
                          {" "}
                          No best popular products found.
                        </span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              </div>
            </div>
            <div className="IpDropReviewsContainer">
              <div className="container-fluid">
                <div
                  className="fw-semibold mb-1 "
                  style={{ fontSize: "19px", color: "rgb(250,103,103)" }}
                >
                  Leave a review
                </div>
              </div>

              <div className="container-fluid container_leavearating_rating">
                <div className="d-flex justify-content-center flex-column leavearating_rating pt-3">
                  <div className="d-flex justify-content-center ">
                    <span
                      className={`fs-1 fa fa-star ${starOneClass}`}
                      onClick={starOneClickHandler}
                    ></span>
                    <span className="reviewStarSpacer"></span>
                    <span
                      className={`fs-1 fa fa-star ${starTwoClass}`}
                      onClick={starTwoClickHandler}
                    ></span>
                    <span className="reviewStarSpacer"></span>
                    <span
                      className={`fs-1 fa fa-star ${starThreeClass}`}
                      onClick={starThreeClickHandler}
                    ></span>
                    <span className="reviewStarSpacer"></span>
                    <span
                      className={`fs-1 fa fa-star ${starFourClass}`}
                      onClick={starFourClickHandler}
                    ></span>
                    <span className="reviewStarSpacer"></span>
                    <span
                      className={`fs-1 fa fa-star ${starFiveClass}`}
                      onClick={starFiveClickHandler}
                    ></span>
                  </div>
                  {isLoadingForRating && (
                    <div
                      className="mt-2 text-center fw-semibold"
                      style={{ fontSize: "14px" }}
                    >
                      <div>Posting your ratings...</div>
                    </div>
                  )}
                  {thankyouForReviewing && !isLoadingForRating && (
                    <div
                      className="mt-2 text-center fw-semibold"
                      style={{ fontSize: "14px" }}
                    >
                      <div>Thankyou for reviewing this product.</div>
                      <div>We appreciate it.</div>
                    </div>
                  )}
                  {!isWriteReviewClicked && (
                    <div className="container-fluid mt-3">
                      <div
                        className="d-flex justify-content-start addwrittenreviewtext fw-semibold"
                        onClick={openWriteReviewClickHandler}
                        style={{ fontSize: "15px", color: "rgb(250,103,103)" }}
                      >
                        Want to post a written review ?
                      </div>
                    </div>
                  )}
                  {isWriteReviewClicked && (
                    <div className="container-fluid">
                      {/* <div
                        className="mt-3"
                        style={{ borderBottom: "2px solid #ced4da" }}
                      ></div> */}
                      <div className="mt-3">
                        {/* <div
                          className="mb-2 text-center"
                          style={{ fontSize: "18px" }}
                        >
                          <strong>Write a review</strong>
                        </div> */}
                        {reviewError !== "" && (
                          <div className="formErrorSeller mt-3 mb-3">
                            {reviewError}
                          </div>
                        )}
                        <div className="form-floating floatingTextarea_writeareview_container">
                          <textarea
                            className="form-control"
                            placeholder="Leave a comment here"
                            id="floatingTextarea_writeareview"
                            rows="8"
                            style={{ height: "100%" }}
                            value={enteredReview}
                            onChange={(event) => {
                              setEnteredReview(event.target.value);
                            }}
                          ></textarea>
                          <label htmlFor="floatingTextarea_writeareview">
                            Write a review here.
                          </label>
                        </div>
                        <div className="mt-3 d-flex justify-content-center">
                          <button
                            className="seller_updateProduct_cancelbutton"
                            onClick={cancelWriteReviewClickHandler}
                          >
                            Cancel
                          </button>
                          <button
                            className="seller_addProduct_button"
                            onClick={submitWriteReviewClickHandler}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </React.Fragment>
      )}
      {whatToShow === "notfound" && (
        <React.Fragment>
          <LogoNavBar />
          <div className="container-fluid">
            <div className="d-flex justify-content-center">
              <h3>Sorry, this page isn't available.</h3>
            </div>
            <div className="container-fluid mt-3 text-center fs-6">
              The link you followed may be broken, or the page may have been
              removed.{" "}
              <span
                className="fw-semibold"
                style={{ color: "rgb(250,103,103)" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                Go back to Swoopkart.
              </span>
            </div>
          </div>
          <div className="fixed-bottom footerSignIn">
            <SmallFooter />
          </div>
        </React.Fragment>
      )}
      {whatToShow === "unableToReachServer" && (
        <div>
          <SmallNavBar navBarText="Product Page" />
          <CoudntFetch />
        </div>
      )}
    </React.Fragment>
  );
};
export default React.memo(IndividualProductPage);
