import React from "react";
import "./CoudntFetch.css";
const CoudntFetch = () => {
  return (
    <div className="alignmentCoudntFetch fw-semibold">
      <div>Couldn't reach server.</div>
    </div>
  );
};
export default CoudntFetch;
