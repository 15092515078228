import "./LoadingSpinner.css";
const LoadingSpinner = () => {
  return (
    <div
      className="d-flex justify-content-center flex-column align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="loader" style={{ marginLeft: "-7px" }}></div>
      <div
        style={{
          color: "rgb(49, 49, 49)",
          marginTop: "11px",
          fontSize: "19px",
          zIndex: "1000000",
        }}
      >
        Loading...
      </div>
    </div>
  );
};
export default LoadingSpinner;
