import React, { useState, useEffect } from "react";
import "./ForgotPassword.css";
import LogoNavBar from "../../UI/LogoNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import { useNavigate, useParams } from "react-router-dom";
const ResetUserPassword = (props) => {
  const navigate = useNavigate();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState("");
  const [enteredRepetePassword, setEnteredRepetePassword] = useState("");
  const [whatToShow, setWhatToShow] = useState("one");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [userFirstName, setUserFirstName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [enteredPasswordValid, setEnteredPasswordValid] = useState(true);
  const [enteredRepetePasswordValid, setEnteredRepetePasswordValid] =
    useState(true);
  const [isFormValid, setIsFormValid] = useState(true);
  const [doesBothPasswordsMatch, setDoesBothPasswordsMatch] = useState(true);

  const formValidationHandler = () => {
    const validation =
      enteredPasswordValid &&
      enteredRepetePasswordValid &&
      doesBothPasswordsMatch;
    setIsFormValid(validation);
  };

  const showPasswordHandleChange = () => {
    setIsPasswordShown((current) => !current);
  };
  const [cssChangeClass, setCSSChangeClass] = useState("formSuccessSeller");

  const params = useParams();

  const checkLinkValidity = async () => {
    const requestSent = await fetch(
      process.env.REACT_APP_USER_BACKEND_URL + "/forgot-password/access-link/",
      {
        method: "POST",
        body: JSON.stringify({
          uniqueURL: params.uniqueURL,
        }),
        headers: { "content-type": "application/json" },
      }
    );
    const response = await requestSent.json();
    if (response.status === "FAILED") {
      setError("There was a server error.");
      setWhatToShow("notfound");
    }
    if (!response.status) {
      setError("There was a server error.");
      setWhatToShow("notfound");
    }
    if (response.status === "LINK_FOUND") {
      setUserFirstName(response.userFirstName);
      setUserEmail(response.userEmail);
      setWhatToShow("one");
    }
    if (response.status === "LINK_NOT_FOUND") {
      setError("The shop you were looking for dosen't exist.");
      setWhatToShow("notfound");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    document.title = "Reset Password • Swoopkart";
    setTimeout(function () {
      setCSSChangeClass("formGeneralSeller");
    }, 3000);

    try {
      setIsLoading(true);
      checkLinkValidity();
    } catch (err) {
      setError("Unable to reach the server. Try again later.");
      setIsLoading(false);
    }
  }, []);

  const changePasswordHandler = async () => {
    if (enteredPassword === "") {
      setEnteredPasswordValid(false);
      return;
    }
    if (enteredPassword.length < 9) {
      setEnteredPasswordValid(false);
      return;
    }
    setEnteredPasswordValid(true);
    if (enteredRepetePassword === "") {
      setEnteredRepetePasswordValid(false);
      return;
    }
    if (enteredRepetePassword.length < 9) {
      setEnteredRepetePasswordValid(false);
      return;
    }
    setEnteredRepetePasswordValid(true);
    if (enteredRepetePassword !== enteredPassword) {
      setDoesBothPasswordsMatch(false);
      setError("Both passwords are not matching. Try again.");
      return;
    }
    setDoesBothPasswordsMatch(true);
    formValidationHandler();
    if (isFormValid) {
      setIsLoading(true);
      try {
        const requestSentSetNewPassword = await fetch(
          process.env.REACT_APP_USER_BACKEND_URL +
            "/forgot-password/set-new-password/",
          {
            method: "POST",
            body: JSON.stringify({
              uniqueURL: params.uniqueURL,
              enteredPassword: enteredPassword,
              enteredRepetePassword: enteredRepetePassword,
              userFirstNameRecieved: userFirstName,
              userEmailRecieved: userEmail,
            }),
            headers: { "content-type": "application/json" },
          }
        );
        const responseSetNewPassword = await requestSentSetNewPassword.json();
        if (responseSetNewPassword.status === "PASSWORD_ERROR") {
          setError(
            "Enter a valid password. It should be greater than 9 characters."
          );
        }
        if (responseSetNewPassword.status === "FAILED") {
          setError("There was a server error.");
        }
        if (!responseSetNewPassword.status) {
          setError("There was a server error.");
        }
        if (responseSetNewPassword.status === "PASSWORD_CHANGED_SUCESSFULLY") {
          setWhatToShow("two");
        }
        setIsLoading(false);
      } catch (err) {
        setError("Unable to reach the server. Try again later.");
        setIsLoading(false);
      }
    }
  };
  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <LogoNavBar />
      {whatToShow === "one" && (
        <div className="container-fluid">
          <div className="d-flex justify-content-center mb-2">
            <h3>New Password</h3>
          </div>
          <div className="container-fluid">
            <div className={`${cssChangeClass} mt-2 mb-1`}>
              <div>
                <strong>{`${userFirstName + ", "}`}</strong>Create a strong
                password. Also, your password must be greater than 9 characters.
              </div>
            </div>
          </div>

          {(!enteredPasswordValid ||
            !enteredRepetePasswordValid ||
            error !== "") && (
            <div className="container-fluid mt-3">
              <div className="formErrorSeller mb-3 text-start">
                {!enteredPasswordValid && (
                  <span>Enter a valid password greater than 9 characters.</span>
                )}
                {!enteredRepetePasswordValid && (
                  <span>
                    Re-Enter a valid password greater than 9 characters.
                  </span>
                )}
                {error !== "" && <span>{error}</span>}
              </div>
            </div>
          )}
          <div>
            <div className="container-fluid">
              <div className="form-floating mt-3">
                <input
                  type={isPasswordShown ? "text" : "password"}
                  className="form-control"
                  id="floatingPassword_fgp_user2"
                  placeholder="New Password"
                  value={enteredPassword}
                  onChange={(event) => setEnteredPassword(event.target.value)}
                />
                <label htmlFor="floatingPassword_fgp_user2">New Password</label>
              </div>
              <div className="form-floating mt-3">
                <input
                  type={isPasswordShown ? "text" : "password"}
                  className="form-control"
                  id="floatingPassword_fgp_user1"
                  placeholder="Repeat New Password"
                  value={enteredRepetePassword}
                  onChange={(event) =>
                    setEnteredRepetePassword(event.target.value)
                  }
                />
                <label htmlFor="floatingPassword_fgp_user1">
                  Repeat New Password
                </label>
              </div>
              <div className="showPasswordOrNotCheckBoxContainer">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={isPasswordShown}
                    onChange={showPasswordHandleChange}
                    id="flexCheckChecked_passshow"
                    defaultChecked={false}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked_passshow"
                  >
                    Show Password
                  </label>
                </div>
              </div>
              <div className="nextButton">
                <button
                  className="nextButtonCustom"
                  onClick={changePasswordHandler}
                >
                  Finish
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {whatToShow === "two" && (
        <div className="container-fluid">
          <div className="container-fluid">
            <div className="d-flex mb-2 justify-content-center">
              <h3>Password Changed</h3>
            </div>
            <div className="formSuccessSeller mt-2 mb-1">
              <div>
                <strong>{`${userFirstName + ", "}`}</strong>Your password has
                been changed sucessfully. Now you can Sign in to your swoopkart
                account with your new password and shop as usual.
              </div>
            </div>
            <div className="nextButton" style={{ marginTop: "30px" }}>
              <button
                className="nextButtonCustom"
                onClick={() => {
                  navigate("/");
                }}
              >
                Go to Sign In
              </button>
            </div>
          </div>
        </div>
      )}
      {whatToShow === "notfound" && (
        <div className="container-fluid">
          <div className="d-flex justify-content-center">
            <h3>Sorry, this page isn't available.</h3>
          </div>
          <div className="container-fluid mt-3 text-center fs-6">
            The link you followed may be broken, or the page may have been
            removed.{" "}
            <span
              style={{ color: "rgb(250,103,103)" }}
              onClick={() => {
                navigate("/");
              }}
            >
              <strong>Go back to Swoopkart.</strong>
            </span>
          </div>
        </div>
      )}
      <div className="fixed-bottom" style={{ zIndex: "1" }}>
        <SmallFooter />
      </div>
    </div>
  );
};
export default React.memo(ResetUserPassword);
