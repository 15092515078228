const LoadingCategoriesItem = (props) => {
  return (
    <div
      className="col-6"
      style={{ padding: "12px", border: "1px solid rgb(255, 212, 212)" }}
    >
      <div className="d-flex justify-content-center">
        <div
          style={{ height: "100px", width: "100px" }}
          className="loadingProductRootImage"
        />
      </div>
      <div className="rootProductPrice mt-1 col-10">
        <span className="loadingRootProductText"></span>
      </div>
      <div className="rootProductPrice mt-1 col-6">
        <span className="loadingRootProductText"></span>
      </div>
    </div>
  );
};
export default LoadingCategoriesItem;
