import "./Checkout.css";
import React, { useState, useEffect } from "react";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import AddressOption from "./AddressOption";
import SwoopkartLogo from "../../icons/dark.png";
import { useNavigate } from "react-router-dom";
import DeliveryOption from "./DeliveryOption";
import upArrow from "../../icons/up.png";
import downArrow from "../../icons/down.png";
import IndividualProductOrdered from "../individualorderpage/individualproductordered/IndividualProductOrdered";
import tickIcon from "../../icons/greenTick.png";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";
import CouponCodeItem from "./CouponCodeItem";

const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "USER_LOAD" });
  }, []);

  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const userDPFromStore = useSelector((state) => state.userData.userDP);
  const userNameFromStore = useSelector((state) => state.userData.userName);
  const userIdFromStore = useSelector((state) => state.userData.userId);

  const [showWhatInCheckout, setShowWhatInCheckout] = useState("address");
  const goToSelectPayment = () => {
    setShowWhatInCheckout("payment");
  };
  const goToDeliveryOptions = () => {
    setShowWhatInCheckout("deliveryoption");
  };

  const [showApplyCoupon, setShowApplyCoupon] = useState(false);
  const showApplyCouponToggleClickHandler = () => {
    setShowApplyCoupon((prev) => !prev);
  };

  const [isTandCAgreed, setIsTandCAgreed] = useState(false);
  const TandCChangeHandler = () => {
    setIsTandCAgreed((prevState) => !prevState);
  };
  const [isLoading, setIsLoading] = useState(false);

  const [sellerDP, setSellerDP] = useState("");
  const [sellerName, setSellerName] = useState("");
  const [userAddressess, setUserAddressess] = useState([]);
  const [sellerCoupons, setSellerCoupons] = useState([]);
  const [domesticDeliveryData, setDomesticDeliveryData] = useState({});
  const [sellerDomesticDelivery, setSellerDomesticDelivery] = useState(false);
  const [sellerDeliveryOptions, setSellerDeliveryOptions] = useState([]);
  const [error, setError] = useState("");

  // On Load

  const fetchOnLoadHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL + "/checkout/",
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setShowWhatInCheckout("unableToReachServer");
      }
      if (response.status === "CHECKOUT_DATA_LOADED") {
        setShowWhatInCheckout("address");
        setUserAddressess(response.userCheckoutAddressess);
        setSellerCoupons(response.sellerCoupons);
        setDomesticDeliveryData(response.domesticDeliveryData);
        setSellerDomesticDelivery(response.sellerDomesticDelivery);
        setSellerDeliveryOptions(response.sellerDeliveryOptions);
        setSellerName(response.sellerName);
        setSellerDP(response.sellerDP);
      }
      if (response.status === "CART_IS_EMPTY") {
        setShowWhatInCheckout("pageNotFound");
      }
      if (response.status === "NO_DELIVERY_OPTIONS") {
        setShowWhatInCheckout("error");
        setError(
          "We cannot take orders for this store as the seller has not registered any delivery mechanism."
        );
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setShowWhatInCheckout("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setShowWhatInCheckout("unableToReachServer");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOnLoadHandler();
  }, []);

  useEffect(() => {
    document.title = userNameFromStore + "'s Checkout • Swoopkart";
  }, []);

  // screen 1

  const [selectedAddress, setSelectedAddress] = useState(null);

  const onAddressOptionClickChange = (AddressId) => {
    setSelectedAddress(AddressId);
  };

  // screen 2

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const onPaymentMethodOptionClickChange = (PaymentMethod) => {
    setSelectedPaymentMethod(PaymentMethod);
  };

  const [enteredCouponCode, setEnteredCouponCode] = useState("");
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [couponError, setCouponError] = useState("");

  const validateCouponHandler = async () => {
    if (enteredCouponCode !== "") {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_USER_BACKEND_URL +
            "/checkout/check-coupon-validity/",
          {
            method: "POST",
            body: JSON.stringify({
              recievedCouponCode: enteredCouponCode,
            }),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + userTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setShowWhatInCheckout("unableToReachServer");
        }
        if (response.status === "COUPON_IS_VALID") {
          setShowWhatInCheckout("payment");
          setIsCouponValid(true);
          setCouponError("");
        }
        if (response.status === "COUPON_IS_INVALID") {
          setShowWhatInCheckout("payment");
          setIsCouponValid(false);
          setCouponError("Enter a valid Coupon.");
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "USER_LOGGING_OUT" });
          navigate("/user-not-authenticated");
        }
        if (!response.status) {
          setShowWhatInCheckout("unableToReachServer");
        }
        setIsLoading(false);
      } catch (error) {
        setShowWhatInCheckout("unableToReachServer");
        setIsLoading(false);
      }
    } else {
      setIsCouponValid(false);
      setCouponError("Enter a valid Coupon.");
    }
  };

  // screen 3

  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState(null);

  const onDeliveryOptionClickChange = (DeliveryOptionId) => {
    setSelectedDeliveryOption(DeliveryOptionId);
  };

  const [summaryDiscountData, setSummaryDiscountData] = useState({});
  const [summaryUserAddress, setSummaryUserAddress] = useState("");
  const [summarySellerAddress, setSummarySellerAddress] = useState("");
  const [summaryDeliveryOption, setSummaryDeliveryOption] = useState({});
  const [summaryUserCart, setSummaryUserCart] = useState([]);
  const [summaryCartProducts, setSummaryCartProducts] = useState([]);
  const [summaryPaymentMethod, setSummaryPaymentMethod] = useState("");
  const [summarySubtotal, setSummarySubtotal] = useState(0);
  const [summaryGrandTotal, setSummaryGrandTotal] = useState(0);
  const [summaryTodaysDate, setSummaryTodaysDate] = useState("");
  const [summarySellerPhoneNumber, setSummarySellerPhoneNumber] = useState("");
  const [summaryCalculatedDiscount, setSummaryCalculatedDiscount] = useState(0);
  const [placedOrderId, setPlacedOrderId] = useState("");

  const generateSummaryClickHandler = async () => {
    try {
      setIsLoading(true);
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL + "/checkout/generate-summary/",
        {
          method: "POST",
          body: JSON.stringify({
            selectedAddress: selectedAddress,
            selectedPaymentMethod: selectedPaymentMethod,
            isCouponValid: isCouponValid,
            enteredCouponCode: enteredCouponCode,
            selectedDeliveryOption: selectedDeliveryOption,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setShowWhatInCheckout("unableToReachServer");
      }
      if (response.status === "CHECKOUT_FETCHED") {
        setShowWhatInCheckout("order");
        setSummaryDiscountData(response.discountData);
        setSummaryUserAddress(response.userAddress);
        setSummarySellerAddress(response.sellerAddress);
        setSummaryDeliveryOption(response.deliveryOption);
        setSummaryUserCart(response.userCart);
        setSummaryCartProducts(response.cartProducts);
        setSummaryPaymentMethod(response.paymentMethod);
        setSummarySubtotal(response.subtotal);
        setSummaryGrandTotal(response.grandTotal);
        setSummaryTodaysDate(response.todaysDate);
        setSummarySellerPhoneNumber(response.sellerPhoneNumber);
        setSummaryCalculatedDiscount(response.calculatedDiscount);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setShowWhatInCheckout("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setShowWhatInCheckout("unableToReachServer");
      setIsLoading(false);
    }
  };

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  // screen 4

  const placeOrderClickHandler = async () => {
    if (isTandCAgreed) {
      try {
        setIsLoading(true);
        const requestSent = await fetch(
          process.env.REACT_APP_USER_BACKEND_URL + "/checkout/place-order/",
          {
            method: "POST",
            body: JSON.stringify({
              selectedAddress: selectedAddress,
              selectedPaymentMethod: selectedPaymentMethod,
              isCouponValid: isCouponValid,
              enteredCouponCode: enteredCouponCode,
              selectedDeliveryOption: selectedDeliveryOption,
            }),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + userTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setShowWhatInCheckout("unableToReachServer");
        }
        if (response.status === "ORDER_PLACED") {
          setShowWhatInCheckout("success");
          setPlacedOrderId(response.orderId);
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "USER_LOGGING_OUT" });
          navigate("/user-not-authenticated");
        }
        if (!response.status) {
          setShowWhatInCheckout("unableToReachServer");
        }
        setIsLoading(false);
      } catch (error) {
        setShowWhatInCheckout("unableToReachServer");
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <div
        className="mb-3 d-flex stcky-top shadow-sm justify-content-center align-items-center"
        style={{
          backgroundColor: "rgb(255, 231, 231)",
          zIndex: "10",
          paddingTop: "5.4%",
          paddingBottom: "3.4%",
        }}
      >
        <img
          src={SwoopkartLogo}
          alt=""
          style={{ height: "30px" }}
          onClick={() => navigate("/")}
        />
      </div>
      <div className="Checkout_container container-fluid">
        {showWhatInCheckout === "error" && (
          <div>
            <div className="container-fluid">
              <div className="d-flex mb-3 justify-content-center">
                <h3>Can't Checkout</h3>
              </div>
              <div className="formErrorSeller mt-2 mb-1">
                <div>
                  <strong>{`${userNameFromStore + ", "}`}</strong>
                  {error}
                </div>
              </div>
              <div
                className="nextButton justify-content-center d-flex"
                style={{ marginTop: "30px" }}
              >
                <button
                  className="nextButtonCustom"
                  onClick={() => {
                    navigate("/user/cart");
                  }}
                  style={{
                    marginLeft: "4px",
                    fontSize: "17px",
                  }}
                >
                  Back to Cart
                </button>
              </div>
            </div>
          </div>
        )}
        {showWhatInCheckout === "address" && (
          <div className="Checkout_select_address">
            <div className="Checkout_Headings">
              <strong style={{ color: "rgb(250,103,103)", fontSize: "19px" }}>
                Choose a delivery address
              </strong>
            </div>
            <div>
              {userAddressess.map((address) => {
                return (
                  <AddressOption
                    key={address._id}
                    _id={address._id}
                    fullName={address.fullName}
                    phoneNumber={address.phoneNumber}
                    flatHouseBuildingNo={address.flatHouseBuildingNo}
                    areaStreet={address.areaStreet}
                    landmark={address.landmark}
                    townCity={address.townCity}
                    pincode={address.pincode}
                    state={address.state}
                    onClick={onAddressOptionClickChange}
                  />
                );
              })}
              <div className="AddressForOrder_AddressItem">
                <div
                  className="rbBlock d-flex justify-content-start align-items-center"
                  style={{ padding: "3%", margin: "0" }}
                >
                  <div
                    className="ExistingAddress_FullName fs-6"
                    onClick={() => navigate("/user/addresses")}
                  >
                    <div className="container-fluid">
                      <strong>Add new address</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nextButton" style={{ marginTop: "8%" }}>
              <button
                className="nextButtonCustom"
                onClick={goToSelectPayment}
                disabled={!selectedAddress}
              >
                Continue
              </button>
            </div>
          </div>
        )}
        {showWhatInCheckout === "payment" && (
          <div className="checkout_SelectPaymentMethod_container">
            <div className="Checkout_Headings">
              <strong style={{ color: "rgb(250,103,103)", fontSize: "19px" }}>
                Choose a payment option
              </strong>
            </div>
            <div>
              <div className="AddressForOrder_AddressItem">
                <div className="rbBlock d-flex justify-content-start align-items-center">
                  <div className="payment_checkout_radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="PayWithCashOnDelivery"
                      onClick={() =>
                        onPaymentMethodOptionClickChange("Cash On Delivery")
                      }
                    />
                  </div>
                  <label
                    className="form-check-label address_checkout_radio_container"
                    htmlFor="PayWithCashOnDelivery"
                  >
                    <div className="ExistingAddress_FullName fs-6">
                      <strong>Cash On Delivery</strong>
                    </div>
                  </label>
                </div>
              </div>
              <div className="AddressForOrder_AddressItem">
                <div className="rbBlock d-flex justify-content-start align-items-center">
                  <div className="payment_checkout_radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="PayWithPayOnDelivery"
                      onClick={() =>
                        onPaymentMethodOptionClickChange("Pay On Delivery")
                      }
                    />
                  </div>
                  <label
                    className="form-check-label address_checkout_radio_container"
                    htmlFor="PayWithPayOnDelivery"
                  >
                    <div className="ExistingAddress_FullName fs-6">
                      <strong>Pay On Delivery</strong>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div className="AddressForOrder_AddressItem">
                <div
                  className="d-flex justify-content-start align-items-center"
                  style={{ padding: "3.5%" }}
                  onClick={showApplyCouponToggleClickHandler}
                >
                  {!showApplyCoupon && (
                    <img src={downArrow} alt="" style={{ height: "15px" }} />
                  )}
                  {showApplyCoupon && (
                    <img src={upArrow} alt="" style={{ height: "15px" }} />
                  )}
                  <div
                    className="ExistingAddress_FullName fs-6"
                    style={{ marginLeft: "1.9%" }}
                  >
                    <strong>Apply Coupon</strong>
                  </div>
                </div>
                {showApplyCoupon && (
                  <React.Fragment>
                    <div
                      className="container-fluid"
                      style={{
                        paddingBottom: `${isCouponValid ? "0.5%" : "2.4"}`,
                      }}
                    >
                      {!isCouponValid && (
                        <div style={{ padding: "3%" }}>
                          {couponError !== "" && (
                            <div
                              className="formErrorSeller mb-3"
                              style={{ padding: "2%" }}
                            >
                              {couponError}
                            </div>
                          )}
                          <div className="row">
                            <div
                              className="col-7"
                              style={{ paddingRight: "6px" }}
                            >
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Coupon Code"
                                value={enteredCouponCode}
                                onChange={(event) => {
                                  setEnteredCouponCode(
                                    event.target.value.toUpperCase()
                                  );
                                }}
                              />
                            </div>
                            <div
                              className="col-5"
                              style={{ paddingLeft: "6px" }}
                            >
                              <button
                                className="form-control"
                                style={{
                                  backgroundColor: "rgb(250,103,103)",
                                  color: "white",
                                  border: "none",
                                }}
                                onClick={validateCouponHandler}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {isCouponValid && (
                        <div className="row" style={{ padding: "3%" }}>
                          <div className="col-6 d-flex justify-content-start align-items-center">
                            <img
                              src={tickIcon}
                              alt=""
                              style={{ height: "17.5px" }}
                            />{" "}
                            <strong
                              style={{
                                color: "#571089",
                                fontSize: "17px",
                                paddingLeft: "5%",
                              }}
                            >
                              {enteredCouponCode}
                            </strong>
                          </div>
                          <div className="col-6 d-flex justify-content-end align-items-center">
                            <span
                              style={{
                                fontSize: "16px",
                                color: "rgb(250,103,103)",
                              }}
                              className="fw-semibold"
                              onClick={() => {
                                setEnteredCouponCode("");
                                setIsCouponValid(false);
                              }}
                            >
                              Remove
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    {!isCouponValid && (
                      <div className="container-fluid">
                        <div
                          className="container-fluid fw-semibold mt-3 mb-2"
                          style={{ color: "rgb(250,103,103)" }}
                        >
                          {sellerName}'s Coupons
                        </div>
                        {sellerCoupons.map((coupon) => {
                          return (
                            <CouponCodeItem
                              key={coupon._id}
                              _id={coupon._id}
                              discountPercentage={coupon.discountPercentage}
                              onClick={(CouponId) =>
                                setEnteredCouponCode(CouponId)
                              }
                            />
                          );
                        })}
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className="nextButton" style={{ marginTop: "8%" }}>
              <button
                className="nextButtonCustom"
                onClick={goToDeliveryOptions}
                disabled={!selectedPaymentMethod}
              >
                Continue
              </button>
            </div>
          </div>
        )}
        {showWhatInCheckout === "deliveryoption" && (
          <div>
            <div className="Checkout_Headings">
              <strong style={{ color: "rgb(250,103,103)", fontSize: "19px" }}>
                Choose a delivery option
              </strong>
            </div>
            <div>
              {sellerDomesticDelivery && (
                <DeliveryOption
                  key="domesticdelivery"
                  _id="domesticdelivery"
                  deliveryOptionName="Domestic Delivery"
                  deliveryCharges={domesticDeliveryData.price}
                  onlyOrderedBefore={false}
                  waitTime={`${domesticDeliveryData.waitTime} Days`}
                  deliveredBy={sellerName}
                  onClick={onDeliveryOptionClickChange}
                />
              )}
              {sellerDeliveryOptions.map((deliveryOption) => {
                return (
                  <DeliveryOption
                    key={deliveryOption._id}
                    _id={deliveryOption._id}
                    deliveryOptionName={deliveryOption.deliveryOptionName}
                    deliveryCharges={deliveryOption.deliveryCharges}
                    onlyOrderedBefore={deliveryOption.onlyOrderedBefore}
                    waitTime={deliveryOption.waitTime}
                    deliveredBy={sellerName}
                    onClick={onDeliveryOptionClickChange}
                  />
                );
              })}
            </div>
            <div className="nextButton" style={{ marginTop: "8%" }}>
              <button
                className="nextButtonCustom"
                onClick={generateSummaryClickHandler}
                disabled={!selectedDeliveryOption}
              >
                Continue
              </button>
            </div>
          </div>
        )}
        {showWhatInCheckout === "order" && (
          <div className="Checkout_orderNow">
            <React.Fragment>
              <div className="container-fluid mt-3">
                <div className="individualOrderPage_DetailsContainer_Divider"></div>
                <div className="individualOrderPage_DetailsContainer">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="individualOrderPage_DetailsContainer_Details col-6">
                        <div className="individualOrderPage_DetailsContainer_DetailsHeading">
                          Ordered On
                        </div>
                        <div className="individualOrderPage_DetailsContainer_DetailsText">
                          {summaryTodaysDate}
                        </div>
                      </div>
                      <div className="individualOrderPage_DetailsContainer_Details col-6">
                        <div className="individualOrderPage_DetailsContainer_DetailsHeading">
                          Estimated Delivery
                        </div>
                        <div className="individualOrderPage_DetailsContainer_DetailsText">
                          {summaryDeliveryOption.waitTime}
                        </div>
                      </div>
                      <div className="individualOrderPage_DetailsContainer_Details col-6">
                        <div className="individualOrderPage_DetailsContainer_DetailsHeading">
                          Order Value
                        </div>
                        <div className="individualOrderPage_DetailsContainer_DetailsText">
                          ₹{formatNumber(summaryGrandTotal)}
                        </div>
                      </div>
                      <div className="individualOrderPage_DetailsContainer_Details col-6">
                        <div className="individualOrderPage_DetailsContainer_DetailsHeading">
                          Payment Method
                        </div>
                        <div className="individualOrderPage_DetailsContainer_DetailsText">
                          {summaryPaymentMethod}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="orderedIndividualProductsContainer_new">
                <div className="container-fluid">
                  <div className="individualOrderPage_DetailsContainer_Divider mb-3"></div>
                </div>
                {summaryCartProducts.map((product) => {
                  return (
                    <IndividualProductOrdered
                      key={product._id}
                      _id={product._id}
                      image={product.productImage}
                      productName={product.productName}
                      price={formatNumber(product.price)}
                      mrp={formatNumber(product.MRP)}
                      discount={product.discount}
                      brandName={product.brandName}
                      quantity={
                        summaryUserCart.find((o) => o._id === product._id)
                          .quantity
                      }
                    />
                  );
                })}
                <div className="container-fluid">
                  <div className="individualOrderPage_DetailsContainer_Divider mt-3"></div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="container-fluid mb-3">
                  <div
                    className="row individualOrderPage_DetailsContainer_CandCContainer"
                    style={{ fontWeight: "500" }}
                  >
                    <div className="col-9 individualOrderPage_DetailsContainer_CandC fw-semibold">
                      Subtotal
                    </div>
                    <div className="col-3 individualOrderPage_DetailsContainer_CandC text-end">
                      ₹{formatNumber(summarySubtotal)}
                    </div>
                  </div>
                  {summaryDiscountData.discountPercentage !== 0 && (
                    <div
                      className="row individualOrderPage_DetailsContainer_CandCContainer"
                      style={{ fontWeight: "500" }}
                    >
                      <div className="col-9 individualOrderPage_DetailsContainer_CandC">
                        <div className="fw-semibold">Coupon Discount</div>
                        <div>{summaryDiscountData._id}</div>
                        <div>{summaryDiscountData.discountPercentage}% Off</div>
                      </div>
                      <div className="col-3 individualOrderPage_DetailsContainer_CandC text-end">
                        - ₹{formatNumber(summaryCalculatedDiscount)}
                      </div>
                    </div>
                  )}
                  <div
                    className="row individualOrderPage_DetailsContainer_CandCContainer"
                    style={{ fontWeight: "500" }}
                  >
                    <div className="col-6 individualOrderPage_DetailsContainer_CandC fw-semibold">
                      {summaryDeliveryOption.deliveryOptionName}
                    </div>
                    <div className="col-6 individualOrderPage_DetailsContainer_CandC text-end">
                      + ₹{formatNumber(summaryDeliveryOption.deliveryCharges)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="individualOrderPage_DetailsContainer_Divider"></div>
              </div>
              <div className="container-fluid">
                <div className="container-fluid">
                  <div className="row individualOrderPage_DetailsContainer_TotalContainer fw-semibold">
                    <div className="col-6 individualOrderPage_DetailsContainer_Total">
                      Total
                    </div>
                    <div className="col-6 individualOrderPage_DetailsContainer_Total text-end">
                      ₹{formatNumber(summaryGrandTotal)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="individualOrderPage_DetailsContainer_Divider"></div>
              </div>
              <div className="container-fluid mt-3 mb-3">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-3">
                      <span>Sold to</span>
                    </div>
                    <div className="col-9">
                      <div>
                        <strong>{summaryUserAddress.fullName}</strong>
                      </div>
                      <div>
                        <span className="fw-semibold">
                          +91 {summaryUserAddress.phoneNumber}
                        </span>
                      </div>
                      <span>
                        {summaryUserAddress.flatHouseBuildingNo},{" "}
                        {summaryUserAddress.areaStreet},{" "}
                        {summaryUserAddress.townCity} -{" "}
                        {summaryUserAddress.pincode}, {summaryUserAddress.state}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <span>Sold by</span>
                    </div>
                    <div className="col-9">
                      <div>
                        <strong>{sellerName}</strong>
                      </div>
                      <div>
                        <span className="fw-semibold">
                          +91 {summarySellerPhoneNumber}
                        </span>
                      </div>
                      <span>{summarySellerAddress}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="individualOrderPage_DetailsContainer_Divider"></div>
              </div>
              <div className="mt-3 mb-3">
                <div className="fs-6 mt-1 fw-semibold thankyou_forOrdering">
                  <span>Thankyou for shopping with us.</span>
                </div>
              </div>
              <div className="container-fluid">
                <div className="individualOrderPage_DetailsContainer_Divider"></div>
              </div>
            </React.Fragment>
            <div className="Checkout_orderNow_TnC mt-4">
              <div className="form-check flexCheckChecked_checkout_tnc_cont">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={TandCChangeHandler}
                  id="flexCheckChecked_checkout_tnc"
                  defaultChecked={false}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexCheckChecked_checkout_tnc"
                >
                  I agree to the no return and no refund policy. Click to know
                  more.
                </label>
              </div>
            </div>
            <div className="nextButton" style={{ marginTop: "7%" }}>
              <button
                className="nextButtonCustom shadow-sm"
                disabled={!isTandCAgreed}
                onClick={placeOrderClickHandler}
              >
                Place your order
              </button>
            </div>
          </div>
        )}
        {showWhatInCheckout === "success" && (
          <div>
            <div className="container-fluid">
              <div className="d-flex mb-2 justify-content-center">
                <h3>Order Placed</h3>
              </div>
              <div className="formSuccessSeller mt-2 mb-1">
                <div>
                  <strong>{`${userNameFromStore + ", "}`}</strong>Your was
                  placed successfully. Once the seller notices your order he
                  will take an action. You can view the status in your order
                  history.
                </div>
              </div>
              <div
                className="nextButton justify-content-center d-flex"
                style={{ marginTop: "30px" }}
              >
                <button
                  className="nextButtonCustom fw-semibold"
                  onClick={() => {
                    navigate("/user/orderhistory/" + placedOrderId);
                  }}
                  style={{
                    border: "1px solid rgb(250,103,103)",
                    color: "rgb(250,103,103)",
                    backgroundColor: "rgb(255,255,255)",
                    marginRight: "4px",
                    fontSize: "15px",
                  }}
                >
                  View Order
                </button>
                <button
                  className="nextButtonCustom fw-semibold"
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{
                    marginLeft: "4px",
                    fontSize: "15px",
                  }}
                >
                  Home
                </button>
              </div>
            </div>
          </div>
        )}
        {showWhatInCheckout === "unableToReachServer" && (
          <div>
            <CoudntFetch />
          </div>
        )}
        {showWhatInCheckout === "pageNotFound" && (
          <div>
            <div className="container-fluid">
              <div className="d-flex justify-content-center">
                <h3>Sorry, this page isn't available.</h3>
              </div>
              <div className="container-fluid mt-3 text-center fs-6">
                The link you followed may be broken, or the page may have been
                removed.{" "}
                <span
                  className="fw-semibold"
                  style={{ color: "rgb(250,103,103)" }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Go back to Swoopkart.
                </span>
              </div>
            </div>
            <div className="fixed-bottom footerSignIn">
              <SmallFooter />
            </div>
          </div>
        )}
      </div>
      {!(
        showWhatInCheckout === "unableToReachServer" ||
        showWhatInCheckout === "pageNotFound"
      ) && <SmallFooter />}
    </div>
  );
};
export default React.memo(Checkout);
