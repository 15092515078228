import React from "react";
const SmallFooter = () => {
  return (
    <div
      className=" d-flex justify-content-center pb-4 pt-5"
      style={{
        bottom: "0",
        right: "0",
        left: "0",
      }}
    >
      <span
        className="sidedrawer_footer_text"
        style={{ color: "rgb(250,103,103)", fontSize: "15.5px" }}
      >
        © All rights reserved by <strong>swoopkart</strong>
      </span>
    </div>
  );
};
export default React.memo(SmallFooter);
