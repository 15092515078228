import React from "react";

const DeliveryOption = (props) => {
  return (
    <div className="AddressForOrder_AddressItem">
      <div className="rbBlock d-flex justify-content-start align-items-center">
        <div className="payment_checkout_radio">
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id={"radio" + props._id}
            onClick={() => props.onClick(props._id)}
          />
        </div>
        <label
          className="form-check-label address_checkout_radio_container"
          htmlFor={"radio" + props._id}
        >
          <div className="ExistingAddress_FullName fs-6">
            <strong>
              ₹ {props.deliveryCharges} - {props.deliveryOptionName}
            </strong>
          </div>
          <div style={{ fontSize: "15px" }}>
            Delivered Within {props.waitTime}
          </div>
          {props.onlyOrderedBefore && (
            <div style={{ fontSize: "13px" }}>
              Only if ordered before {props.onlyOrderedBefore}
            </div>
          )}
          <div
            style={{
              fontSize: "13px",
              fontWeight: "600",
              color: "rgb(250,103,103)",
            }}
          >
            delivered by {props.deliveredBy}
          </div>
        </label>
      </div>
    </div>
  );
};
export default React.memo(DeliveryOption);
