import "./Categories.css";
import React, { useEffect, useState } from "react";
import gadgetCategory from "../../icons/root/smartphone.png";
import menCategory from "../../icons/root/men-fashion.png";
import womenCategory from "../../icons/root/women-fashion.png";
import kidCategory from "../../icons/root/children.png";
import footwearCategory from "../../icons/root/footwear.png";
import homeCategory from "../../icons/root/home.png";
import perfumeCategory from "../../icons/root/perfume.png";
import makeupCategory from "../../icons/root/makeup.png";
import fitnessCategory from "../../icons/root/fitness.png";
import stationeryCategory from "../../icons/root/stationery.png";
import groceriesCategory from "../../icons/root/groceries.png";
import foodCategory from "../../icons/root/food.png";
import toysCategory from "../../icons/root/toys.png";
import petsCategory from "../../icons/root/pets.png";
import appliancesCategory from "../../icons/root/appliance.png";
import giftingCategory from "../../icons/root/gifting.png";
import officeCategory from "../../icons/root/office.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CategoriesItem from "./CategoriesItem";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import emptyIcon from "../../icons/empty.png";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";
import LoadingCategoriesItem from "../../UI/LoadingProduct/LoadingCategoriesItem";

const Categories = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { onLoad } = props;

  useEffect(() => {
    onLoad();
    dispatch({ type: "USER_LOAD" });
    document.title = params.categoryName + " In Categories • Swoopkart";
  }, []);

  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const userDPFromStore = useSelector((state) => state.userData.userDP);
  const userNameFromStore = useSelector((state) => state.userData.userName);
  const userIdFromStore = useSelector((state) => state.userData.userId);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [recievedProducts, setRecievedProducts] = useState([]);

  const fetchCategoryProducts = async (CategoryName) => {
    setIsLoading(true);
    try {
      const formattedCategoryName =
        CategoryName.charAt(0).toUpperCase() + CategoryName.slice(1);
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL +
          "/categories/" +
          formattedCategoryName,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setError("There was a server error.");
      }
      if (response.status === "CATEGORY_PRODUCTS_LOADED") {
        setError("");
        setRecievedProducts(response.products);
      }
      if (!response.status) {
        setError("There was a server error.");
      }
      setIsLoading(false);
    } catch (error) {
      setError("There was a server error.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategoryProducts(params.categoryName);
  }, [location]);

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  return (
    <div>
      <div
        className="categoriesRoot_container pb-4 pt-4"
        style={{ backgroundColor: "#f0fff1" }}
      >
        <div
          className="categoriesRoot_category d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/Gadgets");
          }}
        >
          <img src={gadgetCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>Gadgets</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category  d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/Appliances");
          }}
        >
          <img src={appliancesCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>Appliances</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category  d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/Groceries");
          }}
        >
          <img src={groceriesCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>Groceries</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category  d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/Food");
          }}
        >
          <img src={foodCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>Food</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category  d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/Footwear");
          }}
        >
          <img src={footwearCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>Footwear</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/MensFashion");
          }}
        >
          <img src={menCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>M's Fashion</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category  d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/WomensFashion");
          }}
        >
          <img src={womenCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>W's Fashion</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category  d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/Kids");
          }}
        >
          <img src={kidCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>Kids</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category  d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/Stationery");
          }}
        >
          <img src={stationeryCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>Stationery</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category  d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/Home");
          }}
        >
          <img src={homeCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>Home</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category  d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/Perfumes");
          }}
        >
          <img src={perfumeCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>Perfumes</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category  d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/Beauty");
          }}
        >
          <img src={makeupCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>Beauty</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category  d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/Wellness");
          }}
        >
          <img src={fitnessCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>Wellness</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category  d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/Pets");
          }}
        >
          <img src={petsCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>Pets</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category  d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/Toys");
          }}
        >
          <img src={toysCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>Toys</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category  d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/Gifting");
          }}
        >
          <img src={giftingCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>Gifting</strong>
          </div>
        </div>
        <div
          className="categoriesRoot_category  d-flex align-items-center flex-column"
          style={{ backgroundColor: "#f0fff1" }}
          onClick={() => {
            navigate("/categories/Office");
          }}
        >
          <img src={officeCategory} alt="" />
          <div className="fs-6 text-center ___text">
            <strong>Office</strong>
          </div>
        </div>
      </div>
      <div>
        <div className="mt-4 mb-3 d-flex container-fluid justify-content-start">
          {(params.categoryName === "WomensFashion" ||
            params.categoryName === "MensFashion") && (
            <strong style={{ fontSize: "19px", color: "rgb(250,103,103)" }}>
              {params.categoryName === "WomensFashion" && (
                <span>Women's Fashion In Categories</span>
              )}
              {params.categoryName === "MensFashion" && (
                <span>Men's Fashion In Categories</span>
              )}
            </strong>
          )}
          {!(
            params.categoryName === "WomensFashion" ||
            params.categoryName === "MensFashion"
          ) && (
            <strong style={{ fontSize: "19px", color: "rgb(250,103,103)" }}>
              {params.categoryName} In Categories
            </strong>
          )}
        </div>
        <div
          style={{
            borderBottom: `${
              error === "" && !isLoading && recievedProducts.length <= 0
                ? "2px solid rgb(255, 212, 212)"
                : ""
            }`,
            marginTop: "-8px",
          }}
        ></div>
        {(error !== "" || isLoading) && (
          <div
            className="row m-0"
            style={{
              borderTop: "1px solid rgb(255, 212, 212)",
              borderBottom: "1px solid  rgb(255, 212, 212)",
            }}
          >
            <LoadingCategoriesItem />
            <LoadingCategoriesItem />
            <LoadingCategoriesItem />
            <LoadingCategoriesItem />
            <LoadingCategoriesItem />
            <LoadingCategoriesItem />
            <LoadingCategoriesItem />
            <LoadingCategoriesItem />
            <LoadingCategoriesItem />
            <LoadingCategoriesItem />
            <LoadingCategoriesItem />
            <LoadingCategoriesItem />
          </div>
        )}
        {error === "" && !isLoading && recievedProducts.length > 0 && (
          <div
            className="row m-0"
            style={{
              borderTop: "1px solid rgb(255, 212, 212)",
              borderBottom: "1px solid  rgb(255, 212, 212)",
            }}
          >
            {recievedProducts.map((product) => {
              return (
                <CategoriesItem
                  key={product._id}
                  _id={product._id}
                  image={product.productImage}
                  productName={product.productName}
                  price={formatNumber(product.price)}
                  discount={product.discount}
                  sellerId={product.sellerId}
                  signInToggle={props.signInToggle}
                />
              );
            })}
          </div>
        )}
        {error === "" && !isLoading && recievedProducts.length <= 0 && (
          <div className="container-fluid mt-3 mb-3">
            <div className="d-flex justify-content-center align-items-center mt-5">
              <div
                style={{
                  color: "#494949",
                  fontSize: "18px",
                }}
                className="fw-semibold container-fluid"
              >
                <div>No Products found in this category.</div>
                <div style={{ fontSize: "15.5px", fontWeight: "300" }}>
                  There might be no products listed. Try searching in other
                  categories.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default React.memo(Categories);
