import "./NavBar.css";
import menuIcon from "../../icons/menu.png";
import cartIcon from "../../icons/cart.png";
import swoopkartLogo from "../../icons/dark.png";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const NavBar = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "USER_LOAD" });
  }, []);

  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const userDPFromStore = useSelector((state) => state.userData.userDP);
  const userNameFromStore = useSelector((state) => state.userData.userName);
  const userIdFromStore = useSelector((state) => state.userData.userId);

  return (
    <nav
      className={`d-flex justify-content-center align-items-center container-fluid bg-white navBar ${
        props.shadowForNav && "shadow-sm sticky-top"
      }`}
    >
      <div className="container-fluid row">
        <div className="col-3 class-0padding align-items-center">
          <button className="drawerToggleButton" onClick={props.drawerToggle}>
            <img src={menuIcon} alt="Menu" className="bars" />
          </button>
        </div>

        <div className="navBarLogo d-flex align-items-center justify-content-center col-6 class-0padding">
          <Link to="/">
            <img src={swoopkartLogo} alt="logo" height="30px" />
          </Link>
        </div>

        <div className="col-3 d-flex justify-content-end class-0padding align-items-center">
          {!userTokenFromStore && (
            <Link className="navSignIn" onClick={props.signInToggle}>
              <b>Sign in</b>
            </Link>
          )}
          {userTokenFromStore && (
            <div>
              <Link className="navAccount" onClick={props.myAccountToggle}>
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    userDPFromStore
                  }
                  alt=""
                />
              </Link>
              <Link
                to="/user/cart"
                className="navCart position-relative"
                onClick={props.cartToggle}
              >
                <img src={cartIcon} alt="cart" className="cart" />
                <span
                  className="position-absolute top-0 start-100 translate-middle badge rounded-pill cart-pill-bg"
                  style={{ color: "white" }}
                >
                  {props.cartItemsCount}
                  <span className="visually-hidden">unread messages</span>
                </span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default React.memo(NavBar);
