import "./Root.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
const ProductRoot = (props) => {
  const navigate = useNavigate();

  const userTokenFromStore = useSelector((state) => state.userData.userToken);

  const goToProductPage = () => {
    if (userTokenFromStore) {
      navigate("/shop/" + props.sellerId + "/product/" + props._id);
    }
    if (!userTokenFromStore) {
      props.signInToggle();
    }
  };

  return (
    <div className="productRoot">
      <div className="productRootInner" onClick={goToProductPage}>
        <div className="rootProductImage_cont d-flex justify-content-center">
          <img
            src={
              process.env.REACT_APP_USER_BACKEND_PRODUCT_IMAGE_LOCATION +
              "/" +
              props.image
            }
            alt="product"
            className="rootProductImage"
          />
        </div>
        <div style={{ paddingLeft: "3.5%" }}>
          <div className="rootProductTitle d-flex justify-content-start">
            <span>{props.productName}</span>
          </div>
          <div className="rootProductPrice fs-6 justify-content-start">
            <b>₹{props.price}</b> ({props.discount}% Off)
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(ProductRoot);
