import React, { useState, useEffect } from "react";
import "./Avatar.css";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";

const Avatar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "USER_LOAD" });
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const userTokenFromStore = useSelector((state) => state.userData.userToken);
  const userDPFromStore = useSelector(
    (state) => state.userData.userDP,
    shallowEqual
  );
  const userNameFromStore = useSelector((state) => state.userData.userName);
  const userIdFromStore = useSelector((state) => state.userData.userId);

  const changeAvatarHandler = async (avatarName) => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_USER_BACKEND_URL + "/edit-profile/avatar",
        {
          method: "POST",
          body: JSON.stringify({
            recievedNewAvatar: avatarName,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + userTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setError("There was a server error.");
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setError("There was a server error.");
      }
      if (response.status === "AVATAR_CHANGED_SUCESSFULLY") {
        localStorage.setItem("userDP", response.newAvatar);
        dispatch({ type: "USER_LOAD" });
        setError("");
      }
      setIsLoading(false);
    } catch (err) {
      setError("Unable to reach the server. Try again later.");
      setIsLoading(false);
    }
  };

  return (
    <div className="ChangeAvatar_container">
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <SmallNavBar navBarText="Change Avatar" />
      <div className="container-fluid mt-4 user_dp_and_name">
        <div className="d-flex justify-content-center">
          <img
            src={
              process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
              userDPFromStore
            }
            alt="dp"
            className="user_dp"
          />
        </div>
        <div className="d-flex justify-content-center mt-1">
          <span className="fs-2">{userNameFromStore}</span>
        </div>
        <div className="text-center container-fluid mt-2">
          <span
            className="fw-semibold"
            style={{ color: "gray", fontSize: "14.5px" }}
          >
            Choose an avatar from our collection that suits your personality.
          </span>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container-fluid">
          {error !== "" && (
            <div className="mb-3 mt-3 formErrorSeller">
              {error !== "" && <span>{error}</span>}
            </div>
          )}
        </div>
      </div>

      <div className="ChangeAvatar_avatars">
        <div className="container-fluid">
          <div className="ChangeAvatar_avatars_male_female ">
            <div className="container-fluid mb-1">
              <div className="container-fluid">
                <strong className="fs-5">Male</strong>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "men.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("men.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "men-one.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("men-one.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "men-two.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("men-two.png");
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "men-three.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("men-three.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "men-four.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("men-four.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "men-five.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("men-five.png");
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "men-six.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("men-six.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "men-seven.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("men-seven.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "men-eight.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("men-eight.png");
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "men-nine.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("men-nine.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "men-ten.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("men-ten.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "men-eleven.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("men-eleven.png");
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "men-twelve.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("men-twelve.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "men-thirteen.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("men-thirteen.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "men-fourteen.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("men-fourteen.png");
                  }}
                />
              </div>
            </div>
          </div>

          <div className="ChangeAvatar_avatars_male_female">
            <div className="container-fluid">
              <div className="container-fluid mb-1">
                <strong className="fs-5">Female</strong>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "women.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("women.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "women-one.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("women-one.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "women-two.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("women-two.png");
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "women-three.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("women-three.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "women-four.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("women-four.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "women-five.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("women-five.png");
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "women-six.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("women-six.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "women-seven.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("women-seven.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "women-eight.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("women-eight.png");
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "women-nine.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("women-nine.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "women-ten.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("women-ten.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "women-eleven.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("women-eleven.png");
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "women-twelve.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("women-twelve.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "women-thirteen.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("women-thirteen.png");
                  }}
                />
              </div>
              <div className="change_avatar_item">
                <img
                  src={
                    process.env.REACT_APP_USER_BACKEND_AVATAR_LOCATION +
                    "women-fourteen.png"
                  }
                  alt=""
                  onClick={() => {
                    changeAvatarHandler("women-fourteen.png");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3"></div>
    </div>
  );
};
export default React.memo(Avatar);
